import React from 'react';
import { Language } from 'shared/Language';
const OrderInfo = (props) => {
    const { table ,adult, child, salesman, note } = props;

    return (
        <div className="info-container">
            <div className="text-order">
                {Language.payment.orderInfo}
            </div>
            <div className="row-detail">
                <div className="detail-part">
                    <div className="part-text">{Language.orderPaid.table}:</div>
                    <div className="part-result">{table}</div>
                </div>
                <div className="detail-part">
                    <img src="assets/icon/Personal/index.svg" alt="icon" />
                    <div className="part-text">{Language.orderPaid.adult}:</div>
                    <div className="part-result">{adult}</div>
                </div>
                <div className="detail-part">
                    <img src="assets/icon/Child/index.svg" alt="icon" />
                    <div className="part-text">{Language.orderPaid.child}:</div>
                    <div className="part-result">{child}</div>
                </div>
                <div className="detail-part">
                    <div className="part-text">Salesman:</div>
                    <div className="part-result-name">{''}</div>
                </div>
            </div>
            {/* <div className="row-note">
                <div className="text-note">{Language.orderPaid.note}:</div>
                <div className="detail-note">{note}</div>
            </div> */}
        </div>
    );
};
export default OrderInfo;
