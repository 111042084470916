/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormControl from '@mui/material/FormControl';
import { SVGSplitAmount } from 'assets/svg';
import {TextField, FormLabel } from '@mui/material';
import './style.scss';
import OrderAction from '../../../redux/actions/order/orderAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { splitOrder } from 'service/orderService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NumericFormat } from 'react-number-format';
import GlobalAction from '../../../redux/actions/global/globalAction';
import LoadingTable from 'components/LoadingTable';

const index = (props) => {
    const {showModal,setShow, setShowSplit,showSplit} = props;
    const [amount, setAmount] = React.useState(0);
    const [totalBookedPrice,setTotalBookedPrice] = useState<number>();
    const userName = localStorage.getItem('Apec_Account');
    // ===========================================================================
    // Selectors
    // ===========================================================================
    const {currentOrderId} =  useSelector((state: AppState) => state.currentOrderId);
    const {bookedList} = useSelector((state: AppState) => state.bookedList);
    const {loading} = useSelector((state:AppState) => state.global);
    //============================================================================
    // Dispatch
    // ===========================================================================
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const dispatch = useDispatch();
    const _getBookedList = () => dispatch<any>(OrderAction.getBookedList(currentOrderId));
    const _setLoading = (loading: boolean) => dispatch<any>(GlobalAction.setLoading(loading));
    const _updateHeaderOrder = ()  => dispatch<any>(OrderAction.updateHeaderOrder());

    // =====================
    //  HOOKS
    // =====================
    useEffect(() => {
        setTotalBookedPrice(0);
        bookedList.map((item: any) => {
            setTotalBookedPrice(pre => pre + item?.price * item?.qty);
        });
    }, [bookedList]);

    // ===========================================================================
    // Events Handlers
    // ===========================================================================
    const handleBack = () =>{ 
        setShow(!showModal); 
        setShowSplit(!showSplit);
    };
    const handleChange = (value) => {
        const regex = /^[0-9\b]+$/;
        if (value === '' || regex.test(value)) {
            setAmount(Number(value));
        }
    };
    const handleConfirm = async () => {
        _setLoading(true);
        const res = await splitOrder({
            username : JSON.parse(userName)?.username,
            isManual : false,
            isPart : false,
            valueSplit :amount ,
            orderId : parseInt(currentOrderId),
            itemIds: formatItemIds()
        });
        if (res?.statusCode === 200) {
            toast.success('Split order successfully', {
                position: 'top-right',
                theme: 'colored'
            });
            _updateHeaderOrder();
            _setLoading(false);
            _getBookedList();
        } else {
            toast.error('Split order failed', {
                position: 'top-right',
                theme: 'colored'
            });
            _setLoading(false);
        }
        setShow(!showModal);
    };

    // ===========================================================================
    // Ultility
    // ===========================================================================
    const formatItemIds = () =>{
        let arr = [];
        bookedList.map((item) => {
            arr.push(item.detailId);
        });
        return arr.join(',');
    };
    const formatMoney= (number) =>{
        if (typeof number === 'number'){
            const optionsVND = { style: 'currency', currency: 'VND', currencyDisplay: 'code' };
            return number.toLocaleString('vi-VN', optionsVND).split('V')[0];
        }
        return number;
    };

    return (
        <React.Fragment>
            {loading && <LoadingTable/>}
            <Modal show={showModal} onHide={handleBack} centered={true}>
               
                <Modal.Body>
                    <FormControl style={{width: '100%'}}>
                        <SVGSplitAmount/>
                        <FormLabel id="split-bill-amount-label" style={{color:'#202223', fontWeight: 600, fontSize:20}}>Split bill with amount</FormLabel>
                        <FormLabel id="split-bill-amount-desc" style={{color:'#7A7A7B',margin: '20px 0', fontWeight: 400, fontSize:14}}>Vui lòng nhập số tiền bạn muốn chia. Hệ thống sẽ tự động tính số tiền còn lại của hóa đơn.</FormLabel>
                        <NumericFormat 
                            type="text" 
                            value={amount} 
                            thousandsGroupStyle="thousand" 
                            thousandSeparator=","
                            onValueChange={(e) => handleChange(e.value)} 
                            style={{background:'#FDFDFD',borderRadius:8, padding:10, border:'1px solid #DFE0E2'}}
                            suffix={' đ'} />        
                        <FormLabel id="split-bill-amount-label" style={{color:'#202223', margin: '10px 0', fontWeight: 600, fontSize:20}}>$ Total: <span style={{color:'#B51929'}}>{formatMoney(totalBookedPrice)}đ</span></FormLabel>
                    </FormControl>
                    <div style={{marginTop:12, display:'flex', justifyContent:'space-between', gap:16}}>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                handleBack();
                            }}
                            style={{ width: 130, background: 'none', color: 'black' }}
                        >
                            <ArrowBackIcon fontSize="small" /> Quay lại 
                        </Button>
                        <Button
                            onClick={() => handleConfirm()}
                            variant="primary"
                            disabled ={amount ? false: true}
                            style={{ width: 130, background: '#A6815B' }}
                        >
                        Xác nhận
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default index;
