import './styles.scss';
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { fetchLogin } from 'service/orderService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from 'layout/auth-provider';
import LoadingTable from 'components/LoadingTable';
const Login = (props) => {
    const { setUser, setShow } = props;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    let auth = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const onFetchLogin = ({ username, password }) => {
        setLoading(true);
        auth.signin(username, password);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    return (
        <>
            <div className="page-login">
                <img src="assets/login.png" alt="logo" />

                <div className="title">
                    <div className="main-title">LogIn into your account</div>
                    <div className="support-title">
                        {' '}
            Welcome back! Please enter your details.
                    </div>
                </div>
                <form className="login-form">
                    <div className="username">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            placeholder="username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="password">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            value={password}
                            placeholder="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <input
                            type="button"
                            className="sign-in"
                            value="Sign in"
                            onClick={() => onFetchLogin({ username, password })}
              
                        />
                    </div>
                </form>
            </div>
            {loading && <LoadingTable/>}
        </>
    );
};
export default Login;
