/* eslint-disable no-case-declarations */
/* eslint-disable filenames/match-regex */

import {CLEAR_SPLITED_ITEM_VALUE, CURRENT_NUM_CHILDREN, CURRENT_NUM_ELDER, CURRENT_TABLE_ID, CURRENT_TABLE_NAME, REMOVE_SPLITED_ITEM_VALUE, SET_ITEM_MENU, SET_LOADING, SET_SPLITED_ITEM_VALUE } from 'redux/constants/globalConstants';

const intialState ={
    currentTableId: '',
    currentTableName: '',
    currentnumElder: 0,
    currentnumChildren: 0,
    splitedItem:[],
    loading: false,
    itemMenu: null
};
export const globalReducer = (
    state = intialState,
    action
) =>{
    switch(action.type){
        case CURRENT_TABLE_ID:
            return {
                ...state,
                currentTableId: action.payload
            };
        case CURRENT_TABLE_NAME:
            return {
                ...state,
                currentTableName: action.payload
            };
        case CURRENT_NUM_ELDER:
            return {
                ...state,
                currentnumElder: action.payload
            };
        case CURRENT_NUM_CHILDREN:
            return {
                ...state,
                currentnumChildren: action.payload
            };
        case SET_SPLITED_ITEM_VALUE:
            const existedItem = state.splitedItem.find((x) => x.detailId === action.payload);
            if(!existedItem){
                return {
                    ...state,
                    splitedItem: [...state.splitedItem, action.payload]
                };
            }
            else{

                return {
                    ...state,
                    splitedItem: state.splitedItem
                };
            }
        case REMOVE_SPLITED_ITEM_VALUE:
            return {
                ...state,
                splitedItem: action.payload
            };
        case CLEAR_SPLITED_ITEM_VALUE:
            return {
                ...state,
                splitedItem: []
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case SET_ITEM_MENU:
            return {
                ...state,
                itemMenu: action.payload
            };
        default:
            return state;
    }
};
