/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { connect } from 'react-redux'
import './styles.scss';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Header from 'layout/header';
import { colorSet } from 'shared/styles/ColorSet';
import OderChild from 'components/OderChild';
import { IsVoid, OrderType, TAB_HOME } from 'constants/AppEnums';
import { SVGChild, SVGIcPP2, SVGOrder, SVGVoidOder } from 'assets/svg';
import { fetchOrderDetail } from 'service/orderService';
import { useLocation } from 'react-router-dom';
import { ItemOderDetailModel, OrderDetailModel, OrderModel } from 'models/order';
import { Language } from 'shared/Language';
import Format from 'utils/Format';
import { StatusType } from 'constants/Defines';
import LoadingTable from 'components/LoadingTable';
import PopupPrint from 'components/popup-print';
import { PopupPrintfAction } from 'components/popup-print/types';
import PopupPrintOrder from 'components/PopupPrintfOder';
import { toast } from 'react-toastify';

function OrderDetailComponent() {
    const [value, setValue] = React.useState<string>(OrderType.ORDER_LIST);
    const [locationList, setLocationList] = useState([]);
    const [currentLocation, setCurrentLocation] = useState<any>();
    const location = useLocation();
    const [orderList, setOrderList] = useState<ItemOderDetailModel[]>();
    const [voidItems, setVoidItems] = useState<ItemOderDetailModel[]>();
    const [data, setData] = useState<OrderDetailModel>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [tab, setTab] = useState<number>(TAB_HOME.ORDER_PAID);
    const refPopup = useRef<PopupPrintfAction>(null);
    const refPopupOrder = useRef<PopupPrintfAction>(null);
    const [order, setOrder] = useState<OrderModel>();


    useEffect(() => {
        getOderDetail(location?.state?.order_id);
    }, [location.state]);

    const getOderDetail = useCallback(async (id?: string) => {
        setLoading(true);
        const res = await fetchOrderDetail(id);
        setLoading(false);

        if (res.message === 'Success') {
            const data = res.metadata[0] as OrderDetailModel;
            setOrderList(data.items.filter((item: ItemOderDetailModel) => item.isVoid === IsVoid.NO));
            setVoidItems(data.items.filter((item: ItemOderDetailModel) => item.isVoid === IsVoid.YES));
            setOrder(res.metadata[0]);
            setData(data);
        } else {
            toast.error(res?.message || Language.err.notFound, {
                theme: 'colored'
            });
        }
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const onPressHeader = () => [
        refPopup.current.showModal()
    ];

    const onPrintOrder = (type: number) => {
        refPopup.current.hideModal();

        refPopupOrder.current.showModal(type, order);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getStatus = () => {
        switch(data.order_status) {
            case StatusType.Active:
                return Language.orderPaid.active;
            default: 
                return Language.orderPaid.close;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const renderTextView = (label: string, value: string | number, icon?: any, style?: any) => {
        return(
            <div className='flex-row mgr-24 jus-center mgt-16'>
                {icon &&  <>{icon}<div className='icon-text'></div></>
                }
                <span className='text-normal regular color-gray-1 mgr-5'>{label}</span>
                <span className={style ? `${style} text-normal bold ` : 'text-normal bold color-black'}>{value}</span>
            </div>
        );
    };

    const renderTextBetWeen = (label: string, value: string | number, style?: any, styleLeft?: any) => {
        return(
            <div className='flex-row mgr-24 between mgt-16'>
                <span className={styleLeft ? `${styleLeft} bold mgr-5` : 'text-normal regular color-gray-1 mgr-5'}>{label}</span>
                <span className={style ? `${style} text-normal bold ` : 'text-normal bold  color-black'}>{`${value}`}</span>
            </div>
        );
    };

    const renderViewOrder = useCallback(() => {
        return(
            <div className='flex-col pd-16 radius bg-white'>
                <span className='text-big-x regular color-black'>{Language.orderPaid.order} {data?.orderNo}</span>
                <div className='flex-row'>
                    {renderTextView(Language.orderPaid.table, location.state?.table_code)}
                    {renderTextView(Language.orderPaid.adult, data?.noOfGuest, <SVGIcPP2/>)}
                    {renderTextView(Language.orderPaid.child, data?.noOfChild, <SVGChild/>)}
                </div>
                <div className='flex-row'>
                    {renderTextView(Language.orderPaid.status, getStatus(), undefined, data.order_status === StatusType.Active ? 'text-green': 'text-red')}
                    {renderTextView(Language.orderPaid.salesman, '', undefined, 'text-pri')}
                </div>
                {/* <div className='flex-row mgt-16'>
                    <span className='text-normal regular color-gray-1 mgr-5'>{Language.orderPaid.note}</span>
                    <span className='text-normal regular color-black italic'>{data?.note || ''}</span>
                </div> */}
            </div>
        );
    }, [data, getStatus, location.state?.table_code]);

    const renderViewPayment = useCallback(() => {
        return(
            <div className='flex-col pd-16 mgt-24 radius bg-white'>
                <span className='text-big-x regular color-black'>{Language.orderPaid.paymentInfo}</span>
                <div className='flex-col'>
                    {renderTextBetWeen(`${Language.orderPaid.amount}( ${data.items?.length} items )`, Format.formatMoney(data?.amountNet))}
                    {renderTextBetWeen(Language.orderPaid.billDiscount, Format.formatMoney(data?.totalDiscount), 'txt-yellow')}
                    {renderTextBetWeen(Language.orderPaid.svc, Format.formatMoney(data?.svc))}
                    {renderTextBetWeen(Language.orderPaid.vat, Format.formatMoney(data?.vat))}
                    <div className='line mgt-16'></div>
                    {renderTextBetWeen(Language.orderPaid.total, Format.formatMoney(data?.total), 'txt-red', 'txt-bold')}
                </div>
            </div>
        );
    }, [data]);

    const renderViewVoucher = useCallback(() => {
        return(
            <div className='flex-col pd-16 mgt-24 radius bg-white'>
                <span className='text-big-x regular color-black'>{Language.orderPaid.voucherInfo}</span>
                <div className='flex-col'>
                    {renderTextBetWeen(Language.orderPaid.voucher, data?.itemVoucher?.[0]?.voucherCode || '' )}
                    {renderTextBetWeen(Language.orderPaid.guestName,  data?.itemVoucher?.[0]?.voucherCustomer || '' )}
                    {renderTextBetWeen(Language.orderPaid.topped, Format.formatMoney(data?.itemVoucher?.[0]?.toppedUp))}
                    <div className='line mgt-16'></div>
                    {renderTextBetWeen(`${Language.orderPaid.balance}:`, Format.formatMoney(data?.itemVoucher?.[0]?.balance) , 'txt-yellow', 'txt-bold')}
                </div>
            </div>
        );
    }, [data?.itemVoucher]);

    return (
        <div className='page-view'>
            <Header 
                currentLocation={currentLocation} 
                locationList={locationList} 
                setCurrentLocation={setCurrentLocation} 
                setTab={setTab} 
                tab={tab} 
                tabDetail={{nameTab: Language.header.orderDetail, onPress:onPressHeader, id: location?.state?.order_no}}/>
            {data && <div className='page-order-detail'>
                <Box sx={{ width: '60%', height: '100%', backgroundColor: colorSet.default.white, borderRadius: 4}}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="Tabs" className='tab-list' > 
                                <Tab label={Language.orderPaid.listOrder} value={OrderType.ORDER_LIST} icon={<SVGOrder/>} className={'flex-row mgr-5'} />
                                <Tab label={Language.orderPaid.voidOder} value={OrderType.VOID_ITEMS}  icon={<SVGVoidOder/> } className={'flex-row mgr-5'}/>
                            </TabList>
                        </Box>
                        <TabPanel value={OrderType.ORDER_LIST}>
                            <OderChild data={orderList} styles={'order-child'} isSVC isVAT hideNode/>
                        </TabPanel>
                        <TabPanel value={OrderType.VOID_ITEMS}>
                            <OderChild data={voidItems} styles={'order-child'} isSVC isVAT hideNode/>
                        </TabPanel>
                    </TabContext>
                </Box>
                <div className='view-right'>
                    {renderViewOrder()}
                    {renderViewPayment()}
                    {data?.itemVoucher?.[0]?.voucherCode && renderViewVoucher()}
                </div>
            </div>}
            <PopupPrint ref={refPopup} title={Language.payment.print} onConfirm={onPrintOrder}/>
            <PopupPrintOrder ref={refPopupOrder}/>
            {isLoading && <LoadingTable/>}
        </div>
    );
}

export default OrderDetailComponent;
