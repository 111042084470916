/* eslint-disable filenames/match-regex */
/* eslint-disable filenames/match-regex */
import { CURRENT_NUM_CHILDREN, CURRENT_NUM_ELDER } from 'redux/constants/globalConstants';
import {
    BOOKED_LIST_FAIL,
    BOOKED_LIST_REQUEST,
    BOOKED_LIST_SUCCESS,
    BOOKED_LIST_UPDATE,
    GET_CURRENT_ORDERNO,
    GET_CURRENT_ORDER_ID,
    SET_IS_LOCAL,
    ORDER_ITEM_REMOVE,
    ORDER_LIST_ADD,
    ORDER_LIST_UPDATE,
    SET_CURRENT_ORDERNO,
    SET_CURRENT_ORDER_ID,
    BOOKED_REMOVE_ITEM,
    ORDER_LIST_CLEAR,
    BOOKED_LIST_SPLIT_ADD,
    BOOKED_SPLIT_REMOVE_ITEM,
    BOOKED_SPLIT_CLEAR_ITEM,
    UPDATE_HEADER_ORDER,
    FLOW_LIST,
    UPDATE_MAX_X,
    UPDATE_MAX_Y,
    UPDATE_LOCATION,
    UPDATE_MIN_X,
    UPDATE_MIN_Y,
    UPDATE_EDIT
} from 'redux/constants/orderConstants';
import { fetchOrderInfo } from 'service/orderService';

const setCurrentOrderNo = (payload) => ({
    type: SET_CURRENT_ORDERNO,
    payload
});
const getCurrentOrderNo = (payload) => ({
    type: GET_CURRENT_ORDERNO,
    payload
});

const setIsLocalValue = (payload) => ({
    type: SET_IS_LOCAL,
    payload
});
const setCurrentOrderId = (payload) => ({
    type: SET_CURRENT_ORDER_ID,
    payload
});
const getCurrentOrderId = (payload) => ({
    type: GET_CURRENT_ORDER_ID,
    payload
});

const setFlow = (payload) => ({
    type: FLOW_LIST,
    payload
});

const setMaxY = (payload) => ({
    type: UPDATE_MAX_Y,
    payload
});

const setLocation = (payload) => ({
    type: UPDATE_LOCATION,
    payload
});


const setEdit = (payload) => ({
    type: UPDATE_EDIT, payload
});
const setMaxX = (payload) => ({
    type: UPDATE_MAX_X,
    payload
});

const setMinX = (payload) => ({
    type: UPDATE_MIN_X,
    payload
});
const setMinY = (payload) => ({
    type: UPDATE_MIN_Y,
    payload
});

const updateHeaderOrder = () => (dispatch:any) => {
    dispatch({
        type: UPDATE_HEADER_ORDER});  
};

const addBookedSplitList =(payload) =>(dispatch:any) => {
    dispatch({
        type: BOOKED_LIST_SPLIT_ADD,payload});  
};
const removeBookedSplitList =(payload) =>(dispatch:any) => {
    dispatch({
        type: BOOKED_SPLIT_REMOVE_ITEM,payload});
};
const clearBookedSplitList = () => (dispatch) =>{
    dispatch({type: BOOKED_SPLIT_CLEAR_ITEM});
};
const getBookedList = (orderId: string) =>(dispatch:any) => {
    try {
        dispatch({ type: BOOKED_LIST_REQUEST });
        if(orderId){
            fetchOrderInfo(orderId).then((res) =>{
                if(res?.statusCode === 200){
                    dispatch({type: BOOKED_LIST_SUCCESS,payload:res?.metadata[0]});
                    localStorage.setItem('bookedList',JSON.stringify(res?.metadata[0].items));
                    dispatch({type: CURRENT_NUM_ELDER, payload: res?.metadata[0].noOfGuest});
                    dispatch({type: CURRENT_NUM_CHILDREN, payload: res?.metadata[0].noOfChild});
                    dispatch({type: SET_IS_LOCAL,payload: res?.metadata[0].isInhouse});
                }   
            });
        }
        
    } catch (error) {
        dispatch({
            type: BOOKED_LIST_FAIL,
            payload:
        error.response  && error.response.data.message
            ?error.response.data.message
            :error.message
        });
    }
};

const updateBookedList =(payload) =>(dispatch:any,getState) => {
    const {
        bookedList: { bookedList }
    } = getState();
    const carts = bookedList;
    const existingCartIndex = bookedList.findIndex(p => p.detailId === payload.detailId && p.parent_id === payload.parent_id);
    if (existingCartIndex >= 0 && payload.qty >=0) {
        let cart = carts[existingCartIndex];
        cart.qty = payload.qty;
        cart.flag = payload.flag;
        cart.note = payload.note;
        cart.parent_id = payload.parent_id;
        cart.root_id = payload.root_id;
    }
    dispatch({type: BOOKED_LIST_UPDATE,payload:[...carts]});
    // localStorage.setItem('bookedList',JSON.stringify(carts));
};

const addToCartOrder =(payload) =>(dispatch:any) => {
    dispatch({
        type: ORDER_LIST_ADD,
        payload:{
            menu_id: payload.menu_id,
            label: payload.label,
            price: payload.price,
            image: payload.image,
            qty: 1,
            totalPrice: payload.price,
            item_id: payload.item_id,
            parent_id:  payload.parent_id,
            note: payload.note,
            root_menu: payload.root_menu
        }});
    // localStorage.setItem('orderItems', JSON.stringify(getState().orderList?.orderList));
};

const updateOrderList =(payload) =>(dispatch:any,getState) => {
    const {
        orderList: { orderList }
    } = getState();
    const carts = orderList;

    const existingCartIndex = orderList.findIndex(p => p.item_id === payload.item_id && p.parent_id === payload.parent_id);

    if (existingCartIndex >= 0 && payload.qty >=0) {
        let cart = carts[existingCartIndex];
        cart.qty = payload.qty;
        // cart.flag = payload.flag;
        cart.note = payload.note;
    }
    dispatch({type: ORDER_LIST_UPDATE, payload:[...carts]});
    // localStorage.setItem('orderItems', JSON.stringify(getState().orderList?.orderList));
};
const removeItemBookedList =(payload) =>(dispatch:any,getState) => {
    const {
        bookedList: { bookedList }
    } = getState();
    const carts = bookedList;
    const existingCartIndex = bookedList.findIndex(p => p.detailId === payload.detailId && p.parent_id === payload.parent_id);
    
    if (existingCartIndex >= 0) {
        let cart = carts[existingCartIndex];
        cart.flag = 'DELETE';
        cart[existingCartIndex] = cart;
    }
    dispatch({type: BOOKED_REMOVE_ITEM,payload:[...carts]});
    localStorage.setItem('orderItems', JSON.stringify(getState().orderList?.bookedList));
};
export const removeItemsFromCart = (payload) => async (dispatch) => {
    dispatch({
        type: ORDER_ITEM_REMOVE,
        payload: {item_id: payload.item_id, parent_id: payload.parent_id, menu_id: payload.menu_id}
    });
    // localStorage.setItem('orderItems', JSON.stringify(getState().orderList?.orderList));
};
const clearItemsOrderList = () => (dispatch) =>{
    dispatch({type: ORDER_LIST_CLEAR});
};

const exportDefault = {
    setCurrentOrderNo,
    getCurrentOrderNo,
    getBookedList,
    setCurrentOrderId,
    getCurrentOrderId,
    updateBookedList,
    addToCartOrder,
    updateOrderList,
    removeItemsFromCart,
    setIsLocalValue,
    removeItemBookedList,
    clearItemsOrderList,
    addBookedSplitList,
    removeBookedSplitList,
    clearBookedSplitList,
    updateHeaderOrder,
    setFlow,
    setMaxY,
    setMaxX,
    setLocation,
    setMinX,
    setMinY,
    setEdit
};
export default exportDefault;
