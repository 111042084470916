/* eslint-disable react/react-in-jsx-scope */

import { ItemOderDetailModel } from 'models/order';
import { useCallback } from 'react';
import { Language } from 'shared/Language';
import Format from 'utils/Format';
import './styles.scss';
import { OrderChildProps } from './types';

export default function OderChild({data, styles, isSVC, isVAT, isEdit, onEdit, hideNode}: OrderChildProps) {

    const renderItem = useCallback((item: ItemOderDetailModel) => {
        const onClickEdit = () => {
            onEdit?.(item);
        };

        const getPercent = () => {
            if(item.price === 0) return '';
            if(item?.isDiscountPer === 'Y') {
                return Number(item.discount);
            } else {
                return Number(item?.discountAmount) / item?.price > 0 ? Math.round(( Number(item?.discountAmount) * 100 / item.qty / item?.price) * 100) /100 : 0;
            }
        };
        return(
            <div className={`${styles} item-order-chill line`}>
                <div className='flex-row flex-2'>
                    <img src={'https://i.imgur.com/RTwSzTR.jpeg'} className='img'/>
                    <div className='flex-column mgl-10'>
                        <span className='text-normal bold color-black'>{item?.itemName}</span>
                        <div className='flex-row'>
                            <span className='text-normal regular color-gray mgr-5'>{Language.orderPaid.unit}:</span>
                            <span className='text-normal regular color-black'>{item?.unitName}</span>
                        </div>
                        {!hideNode && item?.note && item?.note.length > 0 && <div className='flex-row'>
                            <span className='text-normal regular color-gray mgr-5'>{Language.orderPaid.note}: 
                                <span className='text-small regular color-black'> {item?.note}</span>
                            </span>
                        </div>}
                    </div>
                </div>
                <div className='flex-1 flex-column al-end'>
                    <span className='text-normal bold color-black'>{Format.formatMoney(item?.price - item.discountAmount/item.qty)}</span>
                    {item.discountAmount > 0 && 
                        <span className='text-normal regular color-gray-1 txt-del'>{Format.formatMoney(item?.price)}</span>
                    }
                    {(isEdit || item.discountAmount) &&  <div className='flex-row'>
                        {isEdit && <img src='assets/icon/Pen/index.svg' width={14} onClick={onClickEdit}/>}
                        <span className='text-small regular color-black'>- {getPercent() || 0}%</span>
                    </div>}
                </div>
                <div className='flex-1 flex-column al-center '>
                    <span className='text-normal regular color-black'>{item?.qty || '0'}</span>
                </div>
                <div className='flex-1 flex-column al-end'>
                    <span className='text-normal bold color-red'>{Format.formatMoney(item?.total) || '0'}</span>
                    {isSVC &&  <div className='flex-row'>
                        <span className='text-small regular color-gray mgr-5'>{Language.orderPaid.svc}:</span>
                        <span className='text-small regular color-black-1'>{Format.formatMoney(item?.svc)}</span>
                    </div>}
                    {isVAT && <div className='flex-row'>
                        <span className='text-small regular color-gray mgr-5'>{Language.orderPaid.vat}:</span>
                        <span className='text-small regular color-black-1'>{Format.formatMoney(item?.vat)}</span>
                    </div>
                    } 
                </div>
            </div>
        );
    }, [hideNode, isEdit, isSVC, isVAT, onEdit, styles]);

    return(
        <div className={`${styles} oder-container`}>
            {data?.map((item, index) => {
                return(<div key={index}>{renderItem(item)}</div>);
            })}
        </div>
    );
}
