import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../styles.scss';
import OrderInfo from '../order-info';
import DiscountInfo from '../discount-info';
import PopupDiscount from '../popup-discount';
import PopupEditDiscount from '../popup-edit-discount';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchOrderDetail, fetchPayment, fetchUpdateDiscount, fetchUpdateDiscountOrder, fetchUpdateUpdateVATAndSVC, fetchVoucher } from 'service/orderService';
import { OrderByTable, OrderDetailModel, OrderModel, VoucherDetailModel } from 'models/order';
import OderChild from 'components/OderChild';
import { PopupDiscountActions } from '../popup-discount/types';
import { Language } from 'shared/Language';
import LoadingTable from 'components/LoadingTable';
import HeaderOrder from 'layout/header/header-order';
import { toast } from 'react-toastify';
import { ParamsOrder, ParamsOrderCheckBox } from '../types';
import { PopupPrintfAction } from 'components/popup-print/types';
import PopupPrint from 'components/popup-print';
import { TypePrint } from 'constants/AppEnums';
import PopupErr from 'components/PopupErr';
import { SVGPopupErr } from 'assets/svg';
import { TableDropDown } from 'components/DropDown/types';
import QrScanCode from 'components/QrScan';
import { ParamsPayment, PopupPaymentActions } from '../popup-payment/types';
import PopupPayment from '../popup-payment';
import PopupSuccess, { ModalAction } from '../popup-success';
import PopupPrintOrder from 'components/PopupPrintfOder';
import { VoucherType } from 'constants/Defines';

export const PaymentDetail = (props) => {
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<OrderModel>();
    const refModalDiscount = useRef<PopupDiscountActions>(null);
    const refModalEditDiscount = useRef<PopupDiscountActions>(null);
    const [voucherInfo, setVoucherInfo] = useState<VoucherDetailModel | null>();
    const [errVoucher, setErrVoucher] = useState<string>('');
    const [defaultDropDown, setDefaultDropDown] = useState<string>('');
    const [defaultCustomer, setDefaultCustomer] = useState<string>('');
    
    const refPopupPrintf = useRef<PopupPrintfAction>(null);
    const refPopupPayment = useRef<PopupPaymentActions>(null);
    const refPopupSuccess = useRef<ModalAction>(null);

    const refQrScan = useRef<PopupPrintfAction>(null);
    const refPopupOrder = useRef<PopupPrintfAction>(null);
    const refErr = useRef<PopupPrintfAction>(null);
    const refDiscount = useRef<any>(null);
    const location = useLocation();
    const [dropsDown, setDropDown] = useState<TableDropDown[]>([]);
    const _user = localStorage.getItem('Apec_Account');
    const user = _user && JSON.parse(_user);
    useEffect(() => {
        getOderDetail(location?.state?.order_id);
    }, [location.state]);

    const getOderDetail = useCallback(async (id?: string) => {
        if(id) {
            setLoading(true);
            const res = await fetchOrderDetail(id);
            setLoading(false);
            if (res.message === 'Success') {
                const _data = res.metadata[0] as OrderModel;
                setData(_data);
            } else {
                toast.error(Language.err.notFound, {
                    position: 'top-right',
                    theme: 'colored'
                });
            }
        }
    }, []);

    const openEdit = (item) => {
        refModalEditDiscount.current?.showModal(item);
    };

    const onEditDiscountItem = useCallback (async(params) => {
        setLoading(true);
        const _params = {...params, orderId: data.orderId};
        const res = await fetchUpdateDiscount(_params);
        setLoading(false);

        if (res.message === 'Success') {
            getOderDetail(location?.state?.order_id);
            toast.success(Language.toast.payment.successUpdate, {
                position: 'top-right',
                theme: 'colored'
            });
            getOderDetail(location?.state?.order_id);
        } else {
            toast.error(Language.toast.payment.errUpdate, {
                position: 'top-right',
                theme: 'colored'
            });
        }
    }, [data?.orderId]);

    const onEditDiscountOrder = useCallback (async(paramsOrder: ParamsOrder) => {
        setLoading(true);
        const res = await fetchUpdateDiscountOrder(paramsOrder);
        setLoading(false);
        if (res.message === 'Success') {
            getOderDetail(location?.state?.order_id);
        } else {
            toast.error(Language.toast.payment.errUpdate, {
                position: 'top-right',
                theme: 'colored'
            });
        }
        
    }, [location?.state?.order_id]);

    const onExtemp = useCallback(async(paramsExtemp: ParamsOrderCheckBox) => {
        setLoading(true);
        const resExtemp = await fetchUpdateUpdateVATAndSVC(paramsExtemp);
        setLoading(false);
        if (resExtemp.message === 'Success') {
            refDiscount.current?.onChangeCheckBox();
            getOderDetail(location?.state?.order_id);
        } else {
            toast.error(Language.toast.payment.errUpdate, {
                position: 'top-right',
                theme: 'colored'
            });
        }
    }, [getOderDetail, location?.state?.order_id]);

    const onPayment = useCallback(async() => {
        // if(voucherInfo && onCheckValueVoucher() ) {
        //     const _params = {
        //         orderId:  data?.orderId,
        //         voucherNo: voucherInfo?.voucherCode,
        //         username: user?.username,
        //         customer: defaultCustomer
        //     };
        //     setLoading(true);
        //     const res = await fetchPayment(_params);
        //     setLoading(false);
        //     if (res.message === 'Success') {
        //         refPopupSuccess.current.showModal();
        //     } else {
        //         toast.error(res.message || Language.err.paymentErr, {
        //             position: 'top-right',
        //             theme: 'colored'
        //         });
        //     }
        // }  else {
        //     refPopupPayment.current.showModal();
        // }

        refPopupPayment.current.showModal(onCheckValueVoucher(), refDiscount.current?.getValueVoucher());
    }, [data?.orderId, defaultCustomer, user?.username, voucherInfo]);

    const openModal = (id: string) => {
        refModalDiscount.current.showModal(id, defaultDropDown);
    };

    const setOpenModalPrint = () => {
        refPopupPrintf.current.showModal();
    };

    const onCamera = () => {
        refQrScan.current.showModal();
    };


    const onCheckValueVoucher = () => {
        if(voucherInfo) {
            if(voucherInfo.valueType === VoucherType.PERCENT) {
                if(voucherInfo.numberRemain > 0) {
                    return Number(voucherInfo.valuept) === 100 && Number(voucherInfo.voucherValueRemain) >= data.total;
                }
                return false;
            } else {
                return Number(voucherInfo.voucherValueRemain) >= data.total;
            }
        }
        return false;
    };

    const onPaymentConfirm = useCallback(async(params: ParamsPayment) => {
        const _params = params;
        _params.orderId = data?.orderId;
        _params.voucherNo = voucherInfo?.voucherCode || '';
        _params.username =  user?.username;
        _params.customer = defaultCustomer;
        setLoading(true);
        const res = await fetchPayment(_params);
        setLoading(false);
        if (res.message === 'Success') {
            refPopupSuccess.current.showModal();
        } else {
            toast.error(res.message || Language.err.paymentErr, {
                position: 'top-right',
                theme: 'colored'
            });
        }
    }, [data?.orderId, defaultCustomer, user?.username, voucherInfo?.voucherCode]);

    const getVoucher = useCallback(async(value: string) => {
        if(value && value.length > 0) {
            setLoading(true);
            const res = await fetchVoucher(value, location?.state?.order_id);
            if(res?.message === 'Success') {
                const _data = res.metadata[0] as VoucherDetailModel;
                _data.voucherCode = value;
                const sales = [] as TableDropDown[];
                res.metadata[0]?.listCustomer?.map(item => sales.push({id: item?.value, value: item?.text.split(' - ')[0]}));
                setDropDown(sales);
                setVoucherInfo(_data    );
                if(res.metadata?.length === 0) {
                    setErrVoucher(Language.payment.errVoucher);
                }
            } else {
                setVoucherInfo(null);
                toast.error(`${res?.message}`|| Language.err.notFound, {
                    position: 'top-right',
                    theme: 'colored'
                });
            }
            setLoading(false);
        } else {
            setVoucherInfo(null);
        }
    }, [location?.state?.order_id]);

    const onChangeText = () => {
        setErrVoucher('');
    };

    const onPrint = (type) => {
        if(type === TypePrint.BILL_MN || type === TypePrint.INVOICE) {
            refErr.current.showModal();
        } else {
            refPopupOrder.current.showModal(TypePrint.BILL, data);
        }
    };

    const onDropsDown = (item: TableDropDown, label: string) => {
        if(label === '1') {
            setDefaultDropDown(item.value);
        } else {
            setDefaultCustomer(item.value);
        }
    };

    const onQrCode = (value: any) => {
     
        if(value?.text && value?.text.length > 0) {
            refDiscount.current?.setValue(value?.text);
            getVoucher(value?.text);
        }
    };

    const onNavigateOrderPaid = () => {
        navigate('/order-paid');
    };

    return (
        <>
            {/* <Header></Header> */}
            <HeaderOrder
                label={`${Language.payment.payment}:`}
                styleLabel={'text-big-x bold color-black mgr-5'}
                id={location?.state?.order_no}
                noDrops
            />
            <div id='page-payment'>
                <div className="payment-left">
                    <OrderInfo 
                        table={location?.state?.table_code}
                        adult={data?.noOfGuest || 0} 
                        child={data?.noOfChild || 0} 
                        salesman="ThoNX" 
                        note={location?.state?.note || ''}
                    />
                    <div className="order-list-container">
                        <span className='text-big-x color-black regular'>{`${Language.payment.orderList} (${data?.items.length || 0} items)`}</span>
                        <OderChild data={data?.items.filter((item => item.isVoid === 'N'))} isSVC isVAT isEdit onEdit={openEdit} styles={'order-list-child'}/>
                    </div>
                </div>
                <div className="payment-right">
                    <DiscountInfo 
                        ref={refDiscount}
                        setOpenModal={openModal} 
                        setOpenModalPrint={setOpenModalPrint} 
                        data={data}
                        dataOder={location?.state}
                        onUpdateDiscount={onEditDiscountOrder}
                        onGetVoucher={getVoucher}
                        voucher={voucherInfo}
                        err={errVoucher}
                        onChange={onChangeText}
                        dataDropDown={dropsDown}
                        onCamera={onCamera}
                        defaultCustomer={defaultCustomer}
                        onDropsDown={onDropsDown}
                        onPress={onPayment}
                        onExtemp={onExtemp}
                    />
                </div>
                {voucherInfo && 
                <PopupDiscount 
                    ref={refModalDiscount} 
                    title={Language.payment.voucherModal.title} 
                    data={voucherInfo} 
                    des={Language.payment.voucherModal.content}
                    dataDrop={dropsDown}
                    defaultDrop={defaultCustomer}
                    onPressItem={onDropsDown}
                />}
                <PopupEditDiscount  
                    ref={refModalEditDiscount} 
                    title={Language.payment.moneyPayment} 
                    data={voucherInfo} 
                    des={location?.state?.total}
                    onPressConfirm={onEditDiscountItem}
                />
                <PopupPrint ref={refPopupPrintf} title={Language.payment.print} onConfirm={onPrint}/>
                <PopupErr 
                    ref={refErr}
                    title={Language.err.errPopupPrint} 
                    content={Language.err.contentErrPopupPrint} 
                    icon={<SVGPopupErr/>}
                />

                <PopupPayment ref={refPopupPayment} title={Language.payment.orderPayment} data={data} onPressConfirm={onPaymentConfirm} voucherRemain={refDiscount.current?.getValueVoucher()}/>
                <QrScanCode ref={refQrScan} onConfirm={onQrCode}/>
                <PopupSuccess ref={refPopupSuccess} onConfirm={onNavigateOrderPaid} />
                <PopupPrintOrder ref={refPopupOrder}/>
                {isLoading && <LoadingTable />}
            </div>
        </>
   
    );
};

export default PaymentDetail;
