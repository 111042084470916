/* eslint-disable filenames/match-regex */
import { AuthProvider } from 'layout/auth-provider';
import RequireAuth from 'layout/require-auth';
import Login from 'pages/authentication/login';
import Home from 'pages/home';
import { PaymentDetail } from 'pages/payment/payment-detail';
import React, { useEffect,useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route } from 'react-router-dom';
import PaymentComponent from 'pages/payment';
import OrderDetailComponent from 'pages/oder-paid/order-detail';
import OrderPaidComponent from 'pages/oder-paid';
import MergBill from 'pages/merge';
// import MergeOrderComponent from 'pages/merge';
import Order from 'pages/order/index';
import Flow from 'pages/flow';
import { ToastContainer } from 'react-toastify';
import MergeOrderComponent from 'pages/merge';
import { useServiceWorker } from 'useServiceWorker';
import ModalCommon from 'components/Modal/ModalCommon/ModalCommon';
function AppV2() {
    const { waitingWorker, showReload, reloadPage } = useServiceWorker();
    const [isShowModalUpdate, setIsShowModalUpdate] = useState(false);

    useEffect(() => {
        if (showReload && waitingWorker) {
            setIsShowModalUpdate(true);
        } else {
            setIsShowModalUpdate(false);
        }
    }, [waitingWorker, showReload, reloadPage]);

    const onFullScreen = () => {
        let elem = document.documentElement;
        const deviceInfo = (window.navigator.userAgent);
        if(!deviceInfo.includes('Mac OS') && !deviceInfo.includes('IOS')  && !deviceInfo.includes('OS')) {
            if (!document.fullscreenElement) {
                elem.requestFullscreen({ navigationUI: 'show' }).then(() => {
                    screen.orientation.lock('landscape').catch((err) => {
                    });
                }).catch(err => {
                    // alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
                });
            } 
        }
    };
    return (
        <div id="page-main" onClick={onFullScreen}>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/payment"
                        element={
                            <RequireAuth>
                                <PaymentComponent />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/payment-detail"
                        element={
                            <RequireAuth>
                                <PaymentDetail />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/"
                        element={
                            <RequireAuth>
                                <Home />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/order/:id"
                        element={
                            <RequireAuth>
                                <Order />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/order-paid"
                        element={
                            <RequireAuth>
                                <OrderPaidComponent />
                                {/* <MergeOrderComponent/> */}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/order-detail"
                        element={
                            <RequireAuth>
                                <OrderDetailComponent />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/order-merge"
                        element={
                            <RequireAuth>
                                <MergeOrderComponent />
                            </RequireAuth>
                        }
                    />
                    <Route path="/flow" element={<Flow></Flow>} />
                    <Route path="/merge" element={<MergBill/>} />
                </Routes>
            </AuthProvider>
            <ToastContainer />
            {isShowModalUpdate && (
                <ModalCommon
                    onHandleClose={() => {
                        setIsShowModalUpdate(false);
                    }}
                    onConfirm={() => {
                        setIsShowModalUpdate(false);
                        reloadPage();
                    }}
                    show={isShowModalUpdate}
                    title="Cập nhật phiên bản mới"
                    content="Đã có phiên bản mới bạn vui lòng thực hiện cập nhật để có thể sử dụng tính năng mới nhất!!!"
                ></ModalCommon>
            )}
        </div>
    );
}

export default AppV2;
