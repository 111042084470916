import React, { useEffect, useState, useRef } from 'react';
import ReactFlow, {  useUpdateNodeInternals, NodeResizer } from 'reactflow';
import { drag } from 'd3-drag';
import { select } from 'd3-selection';

import 'reactflow/dist/style.css';
import './styles.scss';
import useComponentVisible from 'hook/IsOutSizeHook';

export default function FrameTable(
    props
) {
    const { refFocus, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);

    const onClickTable = () => {
        setIsComponentVisible(true);
        props?.data?.onFocus(props);
    };

    return (
        <div
            onClick={onClickTable}
            ref={refFocus}
            style={{display: 'flex', width: '100%', height: '100%' }}
        >
         
            <NodeResizer isVisible={true} minWidth={180} minHeight={180}/>
        </div>
    );
}
