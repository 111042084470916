/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { FormLabel } from '@mui/material';
import { SVGSplitInto } from 'assets/svg';
import { splitOrder } from 'service/orderService';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderAction from '../../../redux/actions/order/orderAction';
import GlobalAction from '../../../redux/actions/global/globalAction';
import LoadingTable from 'components/LoadingTable';
const index = (props) => {
    const {showModal,setShow, setShowSplit,showSplit} = props;
    const [part, setPart] = React.useState(2);

    const userName = localStorage.getItem('Apec_Account');
    // ===========================================================================
    // Selectors
    // ===========================================================================
    const {currentOrderId} =  useSelector((state: AppState) => state.currentOrderId);
    const {bookedList} = useSelector((state: AppState) => state.bookedList);
    const {loading} = useSelector((state:AppState) => state.global);
    //============================================================================
    // Dispatch
    // ===========================================================================
    const dispatch = useDispatch();
    const _getBookedList = () => dispatch<any>(OrderAction.getBookedList(currentOrderId));
    const _setLoading = (loading: boolean) => dispatch<any>(GlobalAction.setLoading(loading));
    const _updateHeaderOrder = ()  => dispatch<any>(OrderAction.updateHeaderOrder());

    // ===========================================================================
    // Events Handlers
    // ===========================================================================
    const handleBack = () =>{
        setShow(!showModal); 
        setShowSplit(!showSplit);
    };
    const handleChange = (event) => {
        setPart(event.target.value);
    };
    const handleConfirm = async () =>{
        _setLoading(true);
        const res = await splitOrder({
            username : JSON.parse(userName)?.username,
            isManual : false,
            isPart : true,
            valueSplit :part ,
            orderId : parseInt(currentOrderId),
            itemIds: formatItemIds()
        });
        if (res?.statusCode === 200) {
            toast.success('Split order successfully', {
                position: 'top-right',
                theme: 'colored'
            });
            _updateHeaderOrder();
            _setLoading(false);
            _getBookedList();
        } else {
            toast.error('Split order failed', {
                position: 'top-right',
                theme: 'colored'
            });
            _setLoading(false);
        }
        setShow(!showModal); 
    };

    // ===========================================================================
    // Ultility
    // ===========================================================================
    const formatItemIds = () =>{
        let arr = [];
        bookedList.map((item) => {
            arr.push(item.detailId);
        });
        return arr.join(',');
    };

    return (
        <React.Fragment>
            <Modal show={showModal} onHide={handleBack} 
                centered={true} >
                {loading && <LoadingTable/>}
                <Modal.Body>
                    <FormControl style={{width: '100%'}}>
                        <SVGSplitInto/>
                        <FormLabel id="split-bill-into-label" style={{color:'#202223', fontWeight: 600, fontSize:20}}>Split Bill into</FormLabel>
                        <FormLabel id="split-bill-into-desc" style={{color:'#7A7A7B',margin: '20px 0', fontWeight: 400, fontSize:14}}>Please enter the part to split!</FormLabel>
                        <Select
                            labelId="split-bill-into-select-label"
                            id="demo-simple-select"
                            value={part ||2}
                            displayEmpty
                            style={{padding:'12px 16px', height:44, borderRadius:8}}
                            onChange={handleChange}                          
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {
                                Array.from(Array(9).keys()).map((item) =>{
                                    return(
                                        <MenuItem key={item} value={item+2}>{item+2}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    <div style={{marginTop:12, display:'flex', justifyContent:'space-between', gap:16}}>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                handleBack();
                            }}
                            style={{ width: 130, background: 'none', color: 'black' }}
                        >
                            <ArrowBackIcon fontSize="small" /> Quay lại 
                        </Button>
                        <Button
                            onClick={() => handleConfirm()}
                            variant="primary"
                            style={{ width: 130, background: '#A6815B' }}
                        >
                        Xác nhận
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default index;
