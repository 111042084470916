/* eslint-disable no-case-declarations */
/* eslint-disable filenames/match-regex */
import { LIMIT } from 'constants/Constants';
import {
    BOOKED_LIST_FAIL,
    BOOKED_LIST_REQUEST,
    BOOKED_LIST_SUCCESS,
    BOOKED_LIST_UPDATE,
    GET_CURRENT_ORDERNO,
    GET_CURRENT_ORDER_ID,
    SET_IS_LOCAL,
    ORDER_ITEM_REMOVE,
    ORDER_LIST_ADD,
    ORDER_LIST_CLEAR,
    ORDER_LIST_UPDATE,
    SET_CURRENT_ORDERNO,
    SET_CURRENT_ORDER_ID,
    BOOKED_REMOVE_ITEM,
    BOOKED_LIST_SPLIT_ADD,
    BOOKED_SPLIT_REMOVE_ITEM,
    BOOKED_SPLIT_CLEAR_ITEM,
    UPDATE_HEADER_ORDER,
    FLOW_LIST,
    UPDATE_MAX_X,
    UPDATE_MAX_Y,
    UPDATE_LOCATION,
    UPDATE_MIN_X,
    UPDATE_MIN_Y,
    UPDATE_EDIT
} from 'redux/constants/orderConstants';

export const CurrentOrderNoReducer = (
    state = { currentOrderNo: '' },
    action
) => {
    switch (action.type) {
        case GET_CURRENT_ORDERNO:
            return {
                ...state,
                currentOrderNo: action.payload
            };
        case SET_CURRENT_ORDERNO:
            return {
                ...state,
                currentOrderNo: action.payload
            };
        default:
            return state;
    }
};

export const Common = (
    state = { isUpdateHeader: false, flowList: [], maxX: 0, maxY: 0, location: '', minX: null, minY: null, isEdit: false },
    action
) => {
    switch (action.type) {
        case UPDATE_HEADER_ORDER:
            return {
                ...state,
                isUpdateHeader: !state.isUpdateHeader
            };
        case FLOW_LIST:
            return {
                ...state,
                flowList: action.payload
            };
        case UPDATE_MAX_X:
            return {
                ...state,
                maxX: action.payload > LIMIT.MAX_X ? LIMIT.MAX_X : action.payload
            };
        case UPDATE_MAX_Y:
            return {
                ...state,
                maxY: action.payload > LIMIT.MAX_Y ? LIMIT.MAX_Y : action.payload
            };
        case UPDATE_MIN_X:
            return {
                ...state,
                minX: action.payload < LIMIT.MIN_X ? LIMIT.MIN_X : action.payload
            };
        case UPDATE_MIN_Y:
            return {
                ...state,
                minY:  action.payload < LIMIT.MIN_Y ? LIMIT.MIN_Y : action.payload
            };
        case UPDATE_LOCATION:
            return {
                ...state,
                location: action.payload
            };
        case UPDATE_EDIT:
            return {
                ...state,
                isEdit: action.payload
            };
        default:
            return state;
    }
};

export const LocalValueReducer = (
    state = { loc: 'N'},
    action
) =>{
    switch(action.type){
        case SET_IS_LOCAL:
            return {
                ...state,
                loc: action.payload
            };
        default:
            return state;
    }
};
export const CurrentOrderIdReducer = (
    state = { currentOrderId: '' },
    action
) => {
    switch (action.type) {
        case SET_CURRENT_ORDER_ID:
            return {
                ...state,
                currentOrderId: action.payload
            };
        case GET_CURRENT_ORDER_ID:
            return {
                ...state,
                currentOrderId: action.payload
            };

        default:
            return state;
    }
};
interface IBookedCardState{
    detailId: number;
    itemId: number;
    itemName: string;
    qty: number;
    price: number;
    note: number;
    createdDate?: string;
    flag: string;
}
interface IBookedState{
    bookedList: IBookedCardState[],
    isInhouse:string,
    orderInfo: any
}
const intialBookedState:IBookedState ={
    bookedList: [],
    isInhouse:'Y',
    orderInfo: null
};
// BOOKED LIST
export const BookedListReducer = (state = intialBookedState, action) => {
    switch (action.type) {
        case BOOKED_LIST_REQUEST:
            return { loading: true, bookedList: [],isInhouse:'N',orderInfo: null };
        case BOOKED_LIST_SUCCESS:
            return { loading: false, bookedList: action.payload.items,isInhouse:action.payload.isInhouse,orderInfo:action.payload };
        case BOOKED_LIST_FAIL:
            return { loading: false, error: action.payload };
        case BOOKED_LIST_UPDATE:
            return {
                ...state,
                bookedList: action.payload
            }; 
        case BOOKED_REMOVE_ITEM:
            return {
                ...state,
                bookedList: action.payload
            }; 
        default:
            return state;
    }
};
const intialBookedSplitState ={
    bookedSplitList: []
};
export const BookedListSplitReducer = (state = intialBookedSplitState, action) => {
    switch (action.type) {
        case BOOKED_LIST_SPLIT_ADD:
            const item = action.payload;
            const existItem = state.bookedSplitList.find((x) => x.detailId === item.detailId);
            if (existItem) {
                return {
                    ...state,
                    bookedSplitList: state.bookedSplitList
                };
            } else {
                return {
                    ...state,
                    bookedSplitList: [...state.bookedSplitList, item]
                };
            }
        case BOOKED_SPLIT_REMOVE_ITEM:
            return {
                ...state,
                bookedSplitList: state.bookedSplitList.filter((x) => x.detailId !== action.payload)
            };
        case BOOKED_SPLIT_CLEAR_ITEM:
            return {
                ...state,
                bookedSplitList: []
            };
        default:
            return state;
    }
};

export const OrderListReducer = (state = {orderList:[]},action) =>{
    switch (action.type){
        case ORDER_LIST_ADD:
            const item = action.payload;
            // const existItem = state.orderList.find((x) => x.menu_id === item.menu_id);
            // if (existItem) {
            //     return {
            //         ...state,
            //         orderList: state.orderList.map((x) => (x.menu_id === existItem.menu_id ? item : x))
            //     };
            // } else {
            return {
                ...state,
                orderList: [...state.orderList, item]
            };
            // }
        case ORDER_LIST_UPDATE:
            return {
                ...state,
                orderList: action.payload
            };
        case ORDER_LIST_CLEAR:
            return{
                ...state,
                orderList: []
            };
        case ORDER_ITEM_REMOVE:
            const index = state.orderList.findIndex((x) => x.item_id === action.payload.item_id && x.parent_id === action.payload.parent_id );
            return {
                ...state,
                orderList: state.orderList.filter((x, i) => i !== index)
            };
        default: 
            return state;
    }
};
