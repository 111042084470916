/* eslint-disable filenames/match-regex */
export const SET_CURRENT_ORDERNO = 'SET_CURRENT_ORDERNO';
export const GET_CURRENT_ORDERNO = 'GET_CURRENT_ORDERNO';

export const SET_IS_LOCAL ='SET_IS_LOCAL';

export const SET_CURRENT_ORDER_ID = 'SET_CURRENT_ORDER_ID';
export const GET_CURRENT_ORDER_ID = 'GET_CURRENT_ORDER_ID';

export const BOOKED_LIST_REQUEST = 'BOOKED_LIST_REQUEST';
export const BOOKED_LIST_RESET = 'BOOKED_LIST_RESET';
export const BOOKED_LIST_SUCCESS = 'BOOKED_LIST_SUCCESS';
export const BOOKED_LIST_FAIL = 'BOOKED_LIST_FAIL';

export const BOOKED_REMOVE_ITEM = 'BOOKED_REMOVE_ITEM';
export const BOOKED_LIST_UPDATE = 'BOOKED_LIST_UPDATE';

export const BOOKED_LIST_SPLIT_ADD = 'BOOKED_LIST_SPLIT_ADD';
export const BOOKED_SPLIT_REMOVE_ITEM = 'BOOKED_SPLIT_REMOVE_ITEM';
export const BOOKED_SPLIT_CLEAR_ITEM = 'BOOKED_SPLIT_CLEAR_ITEM';

export const ORDER_LIST_ADD = 'ORDER_LIST_ADD';
export const ORDER_LIST_UPDATE = 'ORDER_LIST_UPDATE';
export const ORDER_ITEM_REMOVE = 'ORDER_ITEM_REMOVE';
export const ORDER_LIST_CLEAR = 'ORDER_LIST_CLEAR';
export const UPDATE_HEADER_ORDER = 'UPDATE_HEADER_ORDER';

export const FLOW_LIST = 'FLOW_LIST';
export const UPDATE_MAX_X = 'UPDATE_MAX_X';
export const UPDATE_MAX_Y = 'UPDATE_MAX_Y';
export const UPDATE_MIN_Y = 'UPDATE_MIN_Y';
export const UPDATE_MIN_X = 'UPDATE_MIN_X';

export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_EDIT = 'UPDATE_EDIT';




