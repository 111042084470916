/* eslint-disable react/react-in-jsx-scope */
import Modal from '@mui/material/Modal';
import { PopupPrintfAction, PopupPrintfProps } from 'components/popup-print/types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Language } from 'shared/Language';
import './styles.scss';

const PopupErr = forwardRef<PopupPrintfAction, PopupPrintfProps>(({title, content, icon}: PopupPrintfProps, ref) => {

    const [visible, setVisible] = useState<boolean>(false);

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const onConfirm = () => {
    };

    useImperativeHandle(ref, () => ({
        showModal,
        hideModal
    }));

    return (
        <Modal open={visible}   
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className='popup-err'>
                
                <div className="popup-print-container">
                    {icon}
                    <span className='text-big-x color-black bold mgt-16 mgb-10'>{title}</span>
                    <span className='text-normal color-black regular'>{content}</span>
                    <div className="button-contain">
                        <div onClick={hideModal} className="button-right">{Language.common.close}</div>
                    </div>
                </div>
            </div>
        </Modal>
    );
});
export default PopupErr;
