import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import  './styles.scss';
import { TextFieldActions, TextFieldProps } from './types';
import { TYPE_CAPITALIZE, TYPE_INPUT } from 'constants/AppEnums';
import Format from 'utils/Format';
import Validate from 'utils/Validate';


export const MyTextInput = forwardRef<TextFieldActions, TextFieldProps>(
    (
        {
            label,
            value,
            placeHolder,
            type = 'text',
            maxLength,
            disabled,
            containerInput,
            inputStyle,
            containerStyle,
            onKeyPress,
            rightIcon,
            onChangeText,
            important,
            onClickRightIcon,
            max,
            min,
            labelRight,
            capitalize,
            spellCheck,
            pattern,
            labelStyles,
            leftIcon,
            isPercent,
            onBlurText,
            icErr
        }: TextFieldProps,
        ref: any
    ) => {
        useImperativeHandle(ref, () => ({
            setValue,
            fillValue,
            getValue,
            focus,
            blur,
            setErrorMsg
        }));
        const [textfieldVal, setTextfieldVal] = useState<any>(`${value}`);
        const [errMsg, setErrMsg] = useState<string>('');
        const [isFocus, setIsFocus] = useState<boolean>(false);
        const orgTextInput = useRef<HTMLInputElement>(null);
        const isNumberInput = ['phone', 'number', 'tel'].includes(type);

        const _onChangeText = useCallback((e?: any) => {
            setErrMsg('');
            if(isPercent) {
                if(Number(e.target?.value) >= 100) {
                    setTextfieldVal?.('100');
                } else {
                    setTextfieldVal?.(e.target?.value);
                }
            } else {
                if (capitalize === TYPE_CAPITALIZE.SENTENCES) {
                    setTextfieldVal?.(Format.capitalizeFirstLetter(e.target?.value));
                } else { setTextfieldVal?.(e.target?.value); }
            }
        }, [capitalize, isPercent]);

        const getValue = useCallback(() => {
            switch (capitalize) {
                case TYPE_CAPITALIZE.WORDS:
                    return Format.formatForEachWordCase(textfieldVal?.trim()) || '';
                case TYPE_CAPITALIZE.CHARACTERS:
                    return Format.formatUpperCaseCharacter(textfieldVal?.trim()) || '';
                case TYPE_CAPITALIZE.SENTENCES:
                    return Format.capitalizeFirstLetter(textfieldVal?.trim()) || '';
                default:
                    return textfieldVal?.trim() || '';
            }
        }, [capitalize, textfieldVal]);

        const setValue = useCallback((text: any) => {
            if (text) {
                if (capitalize === TYPE_CAPITALIZE.SENTENCES) {
                    setTextfieldVal?.(Format.capitalizeFirstLetter(text));
                } else { setTextfieldVal?.(text); }
                setTextfieldVal?.(text);
            } else {
                setTextfieldVal?.('');
            }
        }, [capitalize]);

        const fillValue = useCallback(
            (text: any) => {
                setValue(text);
            },
            [setValue]
        );

        useEffect(() => {
            if (onChangeText && isFocus || isPercent) {
                onChangeText?.(textfieldVal, placeHolder);
            }
        }, [isFocus, onChangeText, placeHolder, textfieldVal, value, isPercent]);

        useEffect(() => {
            if (!Validate.isEmpty(value)) {
                if(isPercent && Number(value) > 100) {
                    setTextfieldVal('100');
                } else {
                    setValue(value);
                }
                onChangeText?.(textfieldVal, placeHolder);
            }
        }, [isPercent, setValue, value]);

        const focus = useCallback(() => {
            if (orgTextInput.current) {
                orgTextInput.current?.focus();
            }
            setIsFocus(true);
        }, []);

        const blur = useCallback(() => {
            if (orgTextInput.current) {
                orgTextInput.current?.blur();
                onBlurText?.(textfieldVal);
            }
            setIsFocus(false);
        }, [onBlurText, textfieldVal]);

        const setErrorMsg = useCallback((msg: string) => {
            if (Validate.isStringEmpty(msg)) {
                return setErrMsg('');
            }
            setIsFocus(false);
            setErrMsg(msg);
        }, []);

        const errorMessage = useMemo(() => {
            if (!Validate.isStringEmpty(errMsg)) {
                return (
                    <div className='messageError'>
                        {icErr && <img src={icErr} className={'mgr-5'}/>}
                        <span className='text-err'>{errMsg}</span>
                    </div>
                );
            }
            return null;
        }, [errMsg, icErr]);

        const handleClick = useCallback(() => {
            onClickRightIcon?.('');
        }, [onClickRightIcon]);

        const handleOnInput = useCallback((event: any) => {
            let maxNum = 0;
            if (event.target.value.length > maxNum && type === TYPE_INPUT.NUMBER) {
                event.target.value = event.target.value.slice(0, maxLength);
            }
        }, [maxLength, type]);

        const filterNumber = (event: any) => {
            let charCode = event.which ? event.which : event.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                event.preventDefault();
                return false;
            }

            return true;
        };

        const notInputKeyboard = (event: any) => {
            event.preventDefault();
        };

        const renderRightIcon = useMemo(() => {
            return rightIcon && <img
                src={rightIcon}
                className='icon-right-input'
                onClick={handleClick}
            />;
        }, [handleClick, rightIcon]);

        return (
            <div className={`${containerStyle}`}>
                <div className='label-container'>
                    {label ? (
                        <label className={`${labelStyles} text-gray`}>
                            {label}
                            {important && <span> *</span>}
                        </label>
                    ) : ('')}
                    {labelRight && (
                        <span className='label-right-text'>{labelRight}</span>
                    )}
                </div>

                <div className={`${containerInput} ${isFocus ? 'focus-input-container' :  errMsg ? 'error-input-container' : 'select-container'}`}>
                    
                    {leftIcon && 
                        <img
                            src={leftIcon}
                            className='icon-left-input'
                        />
                    }
                    <input
                        ref={orgTextInput}
                        type={type}
                        onChange={_onChangeText}
                        placeholder={placeHolder}
                        value={textfieldVal}
                        maxLength={maxLength}
                        disabled={disabled}
                        readOnly={disabled}
                        autoCapitalize={capitalize || 'none'}
                        onFocus={focus}
                        spellCheck={spellCheck || false}
                        onBlur={blur}
                        onKeyUp={onKeyPress}
                        max={max}
                        min={min}
                        onInput={handleOnInput}
                        pattern={pattern || isNumberInput ? '[0-9]*' : '*'}
                        onKeyDown={isNumberInput ? filterNumber : (type === TYPE_INPUT.DATE ? notInputKeyboard : undefined)}
                        className={`${inputStyle ? inputStyle : 'input-style'} ${!disabled ? '' : 'disable-input-container'}`}
                        required={!textfieldVal && type === 'date'}
                        defaultValue={value}
                    />
                    {/* {
                        isIcon ? isFocus && <div className={styles.ic_error}><img src={IcSuccess} alt="ic_success" /></div> : ''
                    } */}
                    <div className='icon-right-view'>
                        {renderRightIcon}
                    </div>

                </div>
                {errorMessage}
            </div>
        );
    }
);
