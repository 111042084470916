/* eslint-disable react/react-in-jsx-scope */
import { DropDownProps } from './types';
import './styles.scss';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
// eslint-disable-next-line import/named
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SVGIconClose } from 'assets/svg';

function DropDownComponent({
    label,
    iconLeft,
    iconRight,
    styleIconRight,
    styleIconLeft,
    styleLabel,
    stylesContainer,
    onPress, 
    widthMenu, 
    data,
    stylesDrops,
    defaultValue,
    isClose
}: DropDownProps, ref) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [value, setValue] = useState<string>(defaultValue);
    const [err, setErrDropDown] = useState<boolean>(false);
    
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const setErr = (isErr: boolean) => {
        setErrDropDown(isErr);
    };

    const setValueDrop = (_value) => {
        setValue(_value);
    };

    const getValue = () => {
        return value;
    };

    useImperativeHandle(ref, () => ({
        setErr,
        setValueDrop,
        getValue
    }));

    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 150,
            width: widthMenu,
            maxHeight: '60vh',
            color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0'
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5)
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    )
                }
            }
        }
    }));

    const onClear = () => {
        setValue('');
        onPress?.({id: '', value: ''});
        setAnchorEl(null);
    };

    return(
        <div className={`${stylesContainer} drop-down`}>
            <div className= {`${stylesDrops} drops-main`} >
                <div onClick={handleClick} style={{width: 'calc(100% - 35px)',  flexDirection: 'row', display: 'flex', minWidth: 40}}>
                    {iconLeft &&  <div className={`${styleIconLeft}`}  >{iconLeft}</div>}
                    <div className={`${styleLabel} center`} style={{color: err ? 'red' : ''}}>{value || defaultValue || label}</div>
                </div>
                {iconRight && <>
                    {((value || defaultValue) && isClose) ? <div className={`${styleIconRight}`} style={{width: 20, height: 20, justifyContent: 'center', alignItems: 'center', display: 'flex'}} onClick={onClear}>
                        <SVGIconClose/>
                    </div>  : <div className={`${styleIconRight}`} onClick={handleClick}>{iconRight}</div>}
                </>}
            </div>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {data?.map((item, index) => {
                    const onChoose = () => {
                        onPress?.(item);
                        setValue(item?.value);
                        setAnchorEl(null);
                        setErr(false);
                    };
                    return(
                        <MenuItem onClick={onChoose} disableRipple key={index}>
                            {item.value}
                        </MenuItem>
                    );
                })}
            </StyledMenu>
        </div>
    );
}

export default forwardRef(DropDownComponent);
