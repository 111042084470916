/* eslint-disable filenames/match-regex */
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const CURRENT_LOCATION = 'CURRENT_LOCATION';
export const GET_TABLES = 'GET_TABLES';

export const getLocations = (payload) => ({
    type: GET_LOCATIONS,
    payload
});

export const getTables = (payload) => ({
    type: GET_TABLES,
    payload
});

export const changeCurrentLocations = (payload) => ({
    type: CURRENT_LOCATION,
    payload
});
