/* eslint-disable filenames/match-regex */
function isEmpty(...data: any[]) {
    for (let i = 0; i < data.length; i++) {
        if (data[i]) return false;
    }
    return true;
}

function isStringEmpty(string: string) {
    return !string || string === 'null' || String.prototype.trim.call(string) === '';
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    isEmpty,
    isStringEmpty
};
