import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchLogin } from 'service/orderService';
import OrderAction from '../../redux/actions/order/orderAction';
interface AuthContextType {
    user: any;
    signin: (username: string, password: string) => void;
    signOut: () => void;
  }
  
let AuthContext = React.createContext<AuthContextType>(null!);
  
export  function AuthProvider({ children }: { children: React.ReactNode }) {
    let [user, setUser] = React.useState<any>(null);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let signin =  async (username, password ) => {
        await fetchLogin({ username, password }).then((res) => {
            if (res.statusCode === 200 && res.metadata && res.metadata.length > 0) {
                // toast.success('Đăng nhập thành công', {
                //     position: 'top-right',
                //     theme: 'colored'
                // });
                setUser(res.metadata[0]);
                navigate('/', { replace: true });
                localStorage.setItem('Apec_Account', JSON.stringify(res.metadata[0]));
                localStorage.setItem('PW', password);
                localStorage.setItem('Location', JSON.stringify(res.metadata[0]?.location.split(',')[0]));
                dispatch(OrderAction.setLocation(res.metadata[0]?.location.split(',')[0]));
            }
            else {
                toast.error('Đăng nhập không thành công', {
                    position: 'top-right',
                    theme: 'colored'
                });
            }
        });
    };
    let signOut =  async () => {
        navigate('/login', { replace: true });
        localStorage.removeItem('Apec_Account');
    };
    let value = { user, signin, signOut };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export function useAuth() {
    return React.useContext(AuthContext);
}
