/* eslint-disable filenames/match-regex */
import { CURRENT_LOCATION, GET_LOCATIONS, GET_TABLES } from 'redux/actions/home/homeAction';


const initialState: {
  locations: [];
  currentLocation: {},
  tables: []
} = {
    locations: [],
    currentLocation: {},
    tables: []
};

const HomeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOCATIONS:
            return {
                ...state,
                locations: action.payload
            };
        case GET_TABLES:
            return {
                ...state,
                tables: action.payload
            };
        case CURRENT_LOCATION:
            return {
                ...state,
                currentLocation: action.payload
            };
        default:
            return state;
    }
};
export default HomeReducer;
