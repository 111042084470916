import React, {useState, useEffect, useCallback} from 'react';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { SVGRing } from 'assets/svg';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import globalAction from '../../../../redux/actions/global/globalAction';

function FooterCart({
    createOrder,
    bookedList,
    orderList
}) {
    const [cost, setCost] = useState<number>(0);
    const [totalListPrice,setTotalListPrice] = useState<number>(0);
    // ====================
    // SELECTORS
    // ====================
    const {currentnumElder,currentnumChildren} = useSelector((state: AppState) => state.global);
    const {currentOrderId} = useSelector((state: AppState) => state.currentOrderId);

    
    // ====================
    // DISPATCH
    // ====================
    const dispatch = useDispatch();
    const _updateNumberElder = useCallback((numElder: number) => dispatch(globalAction.updateNumberElder(numElder)),[]);
    const _updateNumberChildren =  useCallback((numChildren: number) => dispatch(globalAction.updateNumberChildren(numChildren)),[]);
    // =====================
    //  HOOKS
    // =====================
    useEffect(() => {
        setCost(0);
        if(bookedList.length < 1) {
            _updateNumberElder(0);
            _updateNumberChildren(0);
        }
        bookedList.filter(item => item.flag !== 'DELETE' ).map((item: any) => {
            setCost(pre => pre + item?.price * item?.qty);
        });
    }, [_updateNumberChildren, _updateNumberElder, bookedList]);
    
    useEffect(() => {
        const total = orderList.reduce((a, i) => a + i.qty * i.price, 0);
        setTotalListPrice(total);
    }, [orderList]);

    const formatMoney= (number) =>{
        if (typeof number === 'number'){
            const optionsVND = { style: 'currency', currency: 'VND', currencyDisplay: 'code' };
            return number.toLocaleString('vi-VN', optionsVND).split('V')[0];
        }
        return number;
    };
    return (
        <div className="footer">
            <div className="total-value">
                <div className="total-people">
                    <PeopleOutlineIcon fontSize='small'/> Adult:
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{marginRight: 5}} onClick={() => {
                            if(currentnumElder > 0){
                                _updateNumberElder(currentnumElder-1);
                            }
                        }}>
                            <RemoveIcon style={{cursor:'pointer'}} fontSize='small'/>
                        </div> 
                        {currentnumElder}
                    </div>             
                    <div onClick={() => {{
                        _updateNumberElder(currentnumElder+1);
                    }}}>
                        <AddIcon style={{cursor:'pointer'}} fontSize='small'/>
                    </div> 
                    <span style={{width: 30}}/>
                    <ChildCareIcon fontSize='small'/> Child:
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{marginRight: 5}} onClick={() => {
                            if(currentnumChildren > 0){
                                _updateNumberChildren(currentnumChildren-1);
                            }
                        }}>
                            <RemoveIcon style={{cursor:'pointer'}} fontSize='small'/>
                        </div> 
                        {currentnumChildren}
                    </div>             
                    <div onClick={() => 
                    {
                        _updateNumberChildren(currentnumChildren+1);
                    }
                    }>
                        <AddIcon style={{cursor:'pointer'}} fontSize='small'/>
                    </div> 
                </div>
                <hr></hr>
                <div className="total-price">
                    <span>Total: </span>
                    <span style={{color: '#DC3545'}}>{formatMoney(cost + totalListPrice)}đ</span>

                </div>
            </div>
            <Button variant="contained" 
                onClick={() => createOrder(bookedList)} 
                disabled={orderList.length === 0 && bookedList.length === 0}
                style={{
                    textTransform:'none',
                    width:'calc(100%)',
                    color:'#fff',
                    background:'#B51929',
                    opacity: orderList.length === 0 && bookedList.length === 0 ? 0.4 : 1,
                    padding:10,
                    borderRadius:4
                }}> 
                <SVGRing/>
                <span className='color-white text-normal regular mgl-5'>Calling</span>
            </Button>
        </div>
        
    );
}
export default FooterCart;
