import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { MenuItem } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

import { useAuth } from 'layout/auth-provider';
// eslint-disable-next-line import/named
import { useNavigate } from 'react-router-dom';
import { AppState } from 'redux/store';
import { HeaderOrderProps } from './types';
import DropDownComponent from 'components/DropDown';
import { Language } from 'shared/Language';
import { SVGDown, SVGTable } from 'assets/svg';
import { colorSet } from 'shared/styles/ColorSet';
import PopupChooseTable from 'pages/home/popup-choose-table/PopupChooseTable';
import { fetchArea, fetchOrder, fetchTableMenu } from 'service/orderService';
import { changeCurrentLocations, getLocations, getTables } from 'redux/actions/home/homeAction';
import GlobalAction from '../../../redux//actions/global/globalAction';
import Format from 'utils/Format';
import { TableDropDown } from 'components/DropDown/types';
import OrderAction from '../../../redux/actions/order/orderAction';
import globalAction from '../../../redux//actions/global/globalAction';

export const HeaderOrder = ({
    tables, 
    label, 
    labelButtonRight,
    id,
    styleId,
    styleLabel,
    stylesButtonRight,
    iconButton,
    currentLocation,
    backUrl,
    defaultTable,
    onChangeId,
    noDrops
} : HeaderOrderProps) => {
    let navigate = useNavigate();
    // =========SELECTOR=========
    // ==========================
    const [isModalChooseTable, setIsModalChooseTable] = useState(false);
    const [ordersByTable, setOrdersByTable] = useState([]);
    const [orderDrops, setOrderDrops] = useState<TableDropDown[]>([]);
    const [tableChoose, setTableChoose] = useState<TableDropDown>();
    const [isUpdate, setIsUpdate] = useState<boolean>(true);
    const [defaultDropDown, setDefaultDropDown] = useState(defaultTable);
    const refDrop = useRef(null);
    const dispatch = useDispatch();
    const {currentTableId, currentTableName} =useSelector(
        (state: AppState) => state.global);
    const { currentOrderNo } = useSelector(
        (state: AppState) => state.currentOrderNo);
    const {isUpdateHeader} =useSelector(
        (state: AppState) => state.common);
    const _clearItemsOrderList = () => dispatch<any>(OrderAction.clearItemsOrderList());
    const _updateNumberElder = useCallback((numElder: number) => dispatch(globalAction.updateNumberElder(numElder)),[]);
    const _updateNumberChildren =  useCallback((numChildren: number) => dispatch(globalAction.updateNumberChildren(numChildren)),[]);

    useEffect(() => {
        onFetchOrder(currentTableId);
    }, [currentTableId, isUpdateHeader]);

    const handleClick = (value) => {
        setTableChoose(value);
        onFetchOrder(value?.id);
        setIsModalChooseTable(true);
    };
    const onFetchOrder = (tableId) => {
        if(tableId.length > 0) {
            fetchOrder(tableId).then((res) => {
                const _data = res.metadata;
                setOrdersByTable(_data);
                if(isUpdate) {
                    const listOrderId = [] as TableDropDown[];
                    _data?.map((item) => listOrderId.push({id: item?.orderId, value: item?.orderNo})); 
                    if(listOrderId.find(item => item.value === `${currentOrderNo}.01`)) {
                        dispatch(OrderAction.setCurrentOrderNo(`${currentOrderNo}.01`));
                    }
                    setOrderDrops(listOrderId);
                    setIsUpdate(false);
                }
            });
        }
    };

    const onChooseTable = () => {
        dispatch(GlobalAction.changeCurrentTables(tableChoose?.id));
        dispatch(GlobalAction.changeCurrentTablesName(tableChoose?.value));
        const listOrderId = [] as TableDropDown[];
        ordersByTable?.map((item) => listOrderId.push({id: item?.orderId, value: item?.orderNo})); 
        if(listOrderId.find(item => item.value === `${currentOrderNo}.01`)) {
            dispatch(OrderAction.setCurrentOrderNo(`${currentOrderNo}.01`));
        }
        setOrderDrops(listOrderId);

    };

    const onMove = (table) => {
        setDefaultDropDown(table.value);
        fetchOrder(table?.id).then((res) => {
            const _data = res.metadata;
            setOrdersByTable(_data);
            const listOrderId = [] as TableDropDown[];
            _data?.map((item) => listOrderId.push({id: item?.orderId, value: item?.orderNo})); 
            setOrderDrops(listOrderId);
            setIsUpdate(false);
        });
    };

    const onCancel = () => {
        refDrop.current?.setValueDrop(currentTableName);
    };

    const onFetchArea = useCallback(
        async (value) => {
            fetchArea(value).then((res) => {
                if (res.metadata && res.metadata.length > 0) {
                    onFetchTableMenu(res.metadata[0].value);
                }
            });
        }
        ,[]);
    const onFetchTableMenu = async (value) => {
        fetchTableMenu(value).then((res) => {
            getTables(res.metadata);
        }).catch((err) =>
            console.log(err)
        );
    };

    const onChangeOrderId = (item) => {
        dispatch(OrderAction.setCurrentOrderNo(item.value));
        dispatch(OrderAction.setCurrentOrderId(item.id));
        onChangeId?.(item.id);
        _clearItemsOrderList();
        _updateNumberElder(0);
        _updateNumberChildren(0);
    };

    return (
        <React.Fragment>
            <div className='layout-header-order'>
                <div className='d-flex content-left'>
                    <div onClick={() => { navigate(-1); }}><SVGArrowLeft /></div>
                    <div className={`${styleLabel} label`}>{label} </div>
                    {id && <div className='d-flex select-order align-items-center'>
                        {noDrops ? <div className={`${styleId} name-order text-big color-yellow-5`}>{id}</div> : <DropDownComponent
                            label={Language.orderPaid.table} 
                            data={orderDrops}
                            stylesContainer={'btn-order-header'}
                            onPress={onChangeOrderId}
                            // iconLeft={<SVGTable/>} 
                            iconRight={ <SVGArrowBottom color={colorSet.default.yellow2}/>}
                            styleIconRight={'icon-right-drops'}
                            stylesDrops={'drops'}
                            styleLabel={`${styleId} name-order text-big color-yellow-5`}
                            widthMenu={'fix-content'}
                            defaultValue={id}
                        />}
                    </div> }
                </div>
                <div className='d-flex content-right'>
                    <div className='dropdown-table'>
                        {tables && 
                            <DropDownComponent
                                label={Language.orderPaid.table} 
                                data={Format.sortUpTable(tables)}
                                stylesContainer={'btn-table-header'}
                                onPress={handleClick}
                                iconLeft={<SVGTable/>} 
                                iconRight={<SVGDown/>}
                                styleIconRight={'icon-right-drops'}
                                stylesDrops={'drops'}
                                styleLabel={'text-normal regular color-gray-1 mgl-5 mgt-3'}
                                widthMenu={200}
                                defaultValue={defaultDropDown}
                                ref={refDrop}
                            />
                        }
                    </div>
                    {labelButtonRight &&  <div className={`${stylesButtonRight} type-meal`}>
                        {iconButton}
                        <div className='label'>{labelButtonRight}</div>
                    </div>}
                </div>
            </div>
            {
                isModalChooseTable
                && <PopupChooseTable
                    tables={tables}
                    isModalChooseTable={isModalChooseTable}
                    setIsModalChooseTable ={setIsModalChooseTable}
                    ordersByTable={ordersByTable}
                    onFetchArea={onFetchArea}
                    currentLocation={currentLocation}
                    onCancel={onCancel}
                    // currentTable={currentTable}
                    onConfirm={onChooseTable}
                    onMove={onMove}
                    valueTable = {refDrop.current?.getValue()}
                    onHidden={() => {
                        setIsModalChooseTable(false);
                    }}
                />
            }
        </React.Fragment>
    );
};
const mapStateToProps = (state, ownProps) => {
    return {
        locations: state.home.locations,
        // tables: state.home.tables,
        currentLocation: state.home.currentLocation,
        currentOrderNo: state.home.currentOrderNo
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLocations: (payload) => {
            dispatch(getLocations(payload));
        },
        getTables: (payload) => {
            dispatch(getTables(payload));
        },
        changeCurrentLocations: (payload) => {
            dispatch(changeCurrentLocations(payload));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null)(HeaderOrder);

const SVGArrowLeft = () => {
    return (
        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.27735 13.9415C9.55419 14.237 9.70528 14.6286 9.69868 15.0334C9.69207 15.4383 9.5283 15.8247 9.24197 16.1111C8.95564 16.3974 8.5692 16.5612 8.16432 16.5678C7.75944 16.5744 7.36787 16.4233 7.07235 16.1465L0.757352 9.82996C0.465154 9.53745 0.301025 9.14091 0.301025 8.72746C0.301025 8.31401 0.465154 7.91747 0.757352 7.62496L7.07235 1.30996C7.36787 1.03312 7.75944 0.882033 8.16432 0.888635C8.5692 0.895238 8.95564 1.05901 9.24197 1.34534C9.5283 1.63167 9.69207 2.01811 9.69868 2.42299C9.70528 2.82787 9.55419 3.21945 9.27735 3.51496L4.06485 8.72746L9.27735 13.9415Z" fill="#333333" />
        </svg>

    );
};
const SVGArrowBottom = ({color}) => {
    return (
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.70459 0.675909C9.91677 0.470981 10.2009 0.357587 10.4959 0.36015C10.7909 0.362713 11.0731 0.481028 11.2816 0.689613C11.4902 0.898197 11.6085 1.18036 11.6111 1.47533C11.6137 1.7703 11.5003 2.05448 11.2953 2.26666L6.79534 6.76666C6.58438 6.97756 6.29828 7.09604 5.99997 7.09604C5.70166 7.09604 5.41556 6.97756 5.20459 6.76666L0.704595 2.26666C0.499667 2.05448 0.386273 1.7703 0.388836 1.47533C0.3914 1.18036 0.509715 0.898197 0.718299 0.689613C0.926883 0.481028 1.20905 0.362713 1.50402 0.36015C1.79899 0.357587 2.08317 0.470981 2.29534 0.675909L5.99997 4.38053L9.70459 0.675909Z" fill={`${color}` || '#595959'}/>
        </svg>
    
    );
};


