import CheckBox from 'components/CheckBox';
import DropDownComponent from 'components/DropDown';
import { TableDropDown } from 'components/DropDown/types';
import { MyTextInput } from 'components/Input';
import { TextFieldActions } from 'components/Input/types';
import { TYPE_EXTEMP, TYPE_INPUT } from 'constants/AppEnums';
import { OrderDetailModel, OrderModel, OrderPaidModel } from 'models/order';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import { Language } from 'shared/Language';
import Format from 'utils/Format';
import IconBarcode from 'assets/svg/ic_barcode.svg';
import IconNote from 'assets/svg/ic_note.svg';
import { SVGDown, SVGQrCode, SVGUpDown } from 'assets/svg';
import IcErr from '../../../assets/svg/ic_err.svg';
import { ParamsOrder, ParamsOrderCheckBox } from '../types';
import { VoucherType } from 'constants/Defines';
const dropsDown = [{id: '1', value: 'VND'}, {id: '2', value: 'Percent'}];

const DiscountInfo = ({
    setOpenModal, 
    setOpenModalPrint,
    data,
    dataOder,
    onPress,
    onGetVoucher,
    voucher,
    err,
    onChange,
    dataDropDown,
    onCamera,
    onDropsDown,
    defaultCustomer,
    onUpdateDiscount,
    onExtemp
} : {
    setOpenModal: any,
    setOpenModalPrint: any, 
    data: OrderModel,
    dataOder: OrderPaidModel,
    onGetVoucher?: (text: string) => any,
    onDropsDown?: (item: TableDropDown, label: string) => any,
    defaultCustomer?: string;
    onPress: any;
    voucher?: any;
    err: string;
    onExtemp?: any;
    dataDropDown?: TableDropDown[];
    onChange?: () => any;
    onCamera?: () => any;
    onUpdateDiscount?:any;
    }, ref) => {

    const [discount, setDiscount] = useState<number | string>('');
    const refInputDrops = useRef<TextFieldActions>(null);
    const refInput = useRef<TextFieldActions>(null);

    const [defaultDropDown, setDefaultDropDown] = useState<string>('VND');
    const [customer, setDefaultCustomer] = useState<string>(defaultCustomer || '');
    const _user = localStorage.getItem('Apec_Account');
    const user = _user && JSON.parse(_user);
    const [valueVoucher, setValueVoucher] = useState<string>('');

    const [toggle, setToggle] = useState<boolean>(false);
  
    const [paramsOrder, setParamsOrder] = useState<ParamsOrder>({
        isPercent: false,
        valueDiscount: 0,
        orderId: Number(dataOder.order_id),
        username: user.username
    });

    const [paramsOrderCheckBox, setParamsOrderCheckBox] = useState<ParamsOrderCheckBox>({
        isSVC: false,
        isVAT: false,
        orderId: Number(dataOder.order_id),
        username: user.username
    });
    const [checkBox, setCheckBox] = useState({
        isSVC: data?.extempSVC === TYPE_EXTEMP.YES,
        isVAT: data?.extempVAT === TYPE_EXTEMP.YES
    });

    useEffect(() => {
        setToggle(last => !last);
        setParamsOrder(last => {
            last.isPercent = data?.isDiscountPer === 'Y';
            return last;
        });
        setDefaultDropDown(data?.isDiscountPer === 'Y' ? 'Percent' : 'VND');
        setCheckBox(last => {
            last.isSVC = data?.extempSVC === TYPE_EXTEMP.YES;
            last.isVAT = data?.extempVAT === TYPE_EXTEMP.YES;
            return last;
        });
        setParamsOrderCheckBox(last => {
            last.isSVC = data?.extempSVC === TYPE_EXTEMP.YES;
            last.isVAT = data?.extempVAT === TYPE_EXTEMP.YES;
            return last;
        });
    }, [data?.extempSVC, data?.extempVAT, data?.isDiscountPer]);

    useEffect(() => {
        setDefaultCustomer(defaultCustomer);
        if(err.length > 0) {
            refInput.current.setErrorMsg(err);
        }
    }, [defaultCustomer, err, onGetVoucher]);

    const onChooseDropDown = (item: TableDropDown) => {
        onDropsDown?.(item, '1');
        setDefaultDropDown(item?.value);
        setParamsOrder(last => {
            last.isPercent = item?.value === 'Percent';
            onUpdateDiscount?.(last);
            return last;
        });
    };

    const onChooseCustomer = (item: TableDropDown) => {
        onDropsDown?.(item, '2');
        setDefaultCustomer(item?.value);
    };

    const onPayment = () => {
        setParamsOrder(last => {
            last.valueDiscount = Number(discount);
            onPress?.(last);
            return last;
        }
        );
    };

    const setOption = (value: boolean, label: string) => {
        if(label === Language.orderPaid.svc) {
            setParamsOrderCheckBox(last => {
                last.isSVC = value;
                onExtemp(last);
                return last;
            });
        } else {
            setParamsOrderCheckBox(last => {
                last.isVAT = value;
                onExtemp(last);
                return last;
            });
        }
    };

    const onValidateDiscount = () => {
        const errDiscount = refInputDrops.current?.getValue();
        if(Number(errDiscount) > data.subAmount) {
            refInputDrops.current?.setErrorMsg('Số tiền Discount phải nhỏ hơn Amount');
            return false;
        }
        return true;
    };

    const onUpdate= () => {
        if(onValidateDiscount()) {
            setParamsOrder(last => {
                last.valueDiscount = Number(discount);
                onUpdateDiscount?.(last);
                return last;
            }
            );
        }
       
    };

    const onChangeCheckBox = () => {
        setCheckBox(last => {
            last.isSVC = paramsOrderCheckBox.isSVC;
            last.isVAT = paramsOrderCheckBox.isVAT;
            return last;
        });
    };

    const setValue = (value: string) => {
        setValueVoucher(value);
    };

    const getValueVoucher = () => {
        if(voucher && voucher === null) return 0;
        
        if(voucher?.valueType === VoucherType.PERCENT) {
            if(voucher.numberRemain) {
                return Number(voucher?.valuept * data?.total / 100) > Number(voucher?.voucherValueRemain) ? voucher?.voucherValueRemain : Number(voucher?.valuept * data?.total / 100); 
            }
            return 0;
        } else return voucher?.voucherValueRemain > data?.total ? data?.total : voucher?.voucherValueRemain;
    };

    useImperativeHandle(ref, () => ({
        onChangeCheckBox,
        setValue,
        getValueVoucher
    }));

    return (
        <div className="discount-container" >
            <div className="discount-title">{Language.payment.discountInfo}</div>
            <div className='text-normal regular color-gray mgt-16 mgb-10'>{Language.payment.discount}</div>
            <div className="flex-row jus-end width-100">
                <MyTextInput 
                    type={TYPE_INPUT.NUMBER} 
                    ref={refInputDrops} 
                    containerInput={'input-discount-payment border'}
                    inputStyle={'input-style text-normal'}
                    placeHolder={Language.payment.enterValue}
                    isPercent={paramsOrder.isPercent}
                    value={Number(data?.discount) > 0 ? data?.discount : discount.toString()}
                    containerStyle={'container-my-input'}
                    onChangeText={(e) => setDiscount(e)}
                    onBlurText={onUpdate}
                />
                <DropDownComponent 
                    data={dropsDown}
                    label={Language.payment.choose}
                    styleLabel={'text-normal color-brown regular'}
                    stylesContainer={'drops-down-money'}
                    defaultValue={defaultDropDown}
                    onPress={onChooseDropDown}
                    iconRight={<SVGDown/>}
                    styleIconRight={'discount-icon-right'}
                    stylesDrops={'width-100'}
                /> 
            </div>
            <div className="checkbox-contain">
                <div className="label-checkbox">
                    {Language.payment.exempt}:
                </div>
                <div className="row-checkbox">
                    <CheckBox listOption={checkBox?.isSVC || false} setListOption={setOption} item={{id: 1, name: Language.orderPaid.svc}} />
                    <CheckBox listOption={checkBox?.isVAT || false} setListOption={setOption} item={{id: 2, name: Language.orderPaid.vat}} />
                </div>
            </div>  
            <span className="discount-title">{Language.payment.voucherInfo}</span>
            <div className='text-normal regular color-gray-1 mgt-16 mgb-5'>{`${Language.payment.voucherCode}:`}</div>
            <div className="flex-row jus-start mgb-16">
                <MyTextInput 
                    type={TYPE_INPUT.TEXT} 
                    ref={refInput} 
                    containerInput={'input-discount-payment width-30 border'}   
                    inputStyle={'input-voucher text-normal medium'}
                    placeHolder={Language.payment.enterValue}
                    leftIcon={IconBarcode}
                    rightIcon={err.length === 0 && voucher ? IconNote : ''}
                    onClickRightIcon={ () => setOpenModal(refInput.current.getValue())}
                    containerStyle={'container-my-input'}
                    onBlurText={onGetVoucher}
                    onChangeText={onChange}
                    value={valueVoucher || ''}
                    icErr={IcErr}
                />
                <div className="camera-box" onClick={onCamera}>
                    <SVGQrCode/>
                </div>
            </div>
            {voucher && <div className='jus-between'>
                <span className='text-normal color-gray-1 regular'>{Language.payment.peoplePayment}</span>
                <DropDownComponent 
                    data={dataDropDown}
                    label={Language.payment.choose}
                    styleLabel={'text-normal color-green regular space-nowrap mgr-24'}
                    stylesContainer={'drops-down-customer'}
                    defaultValue={`${customer}` || `${dataDropDown[0]?.value}`}
                    onPress={onChooseCustomer}
                    iconRight={<SVGUpDown/>}
                    styleIconRight={'discount-icon-right'}
                    stylesDrops={'width-100'}
                /> 
            </div>}
            <div className="discount-title">{Language.orderPaid.paymentInfo}</div>
            <div className="row-data-detail">
                <div className="row-label">{Language.orderPaid.amount}{` ( ${data?.items?.length || 0} items ) `} </div>
                <div className="row-value">{Format.formatMoney(data?.subAmount)}</div>
            </div>
            <div className="row-data-detail">
                <div className="row-label">{Language.orderPaid.billDiscount}</div>
                <div className="row-value color-yellow">- {Format.formatMoney(data?.totalDiscount)}</div>
            </div>
            <div className="row-data-detail">
                <div className="row-label">{Language.orderPaid.svc}</div>
                <div className="row-value">{Format.formatMoney(data?.svc)}</div>
            </div>
            <div className="row-data-detail">
                <div className="row-label">{Language.orderPaid.vat}</div>
                <div className="row-value">{Format.formatMoney(data?.vat)}</div>
            </div>
            {/* {voucher &&    <div className="row-data-detail">
                <div className="row-label">{Language.orderPaid.voucherDiscount}</div>
                <div className="row-value color-yellow">- {Format.formatMoney(getValueVoucher())}</div>
            </div>} */}
            <div className='line'/>

            <div className="discount-footer">
                <div className="total">
                    <div className="total-text">
                        {Language.orderPaid.total}
                    </div>
                    <div className="total-value">
                        {Format.formatMoney(data?.total)}
                    </div>
                </div>
                {voucher && <div className="row-data-detail">
                    <div className="row-label">{Language.orderPaid.voucherDiscount}</div>
                    <div className="row-value color-yellow">- {Format.formatMoney(getValueVoucher())}</div>
                </div>}
                <div className="buttons">
                    <div className="button-print" onClick={() => setOpenModalPrint(true)}>
                        <img src="assets/icon/Print/index.svg" alt="print" 
                            style={{marginRight: 5}}
                        />{Language.payment.print}
                    </div>
                    <div className="button-payment" onClick={onPayment}>
                        {Language.payment.payment} {Format.formatMoney(data?.total - Number(getValueVoucher() || 0))}
                        <img src="assets/icon/ArowRight/index.svg" alt="print" style={{marginLeft: 5}}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default forwardRef(DiscountInfo);
