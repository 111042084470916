/* eslint-disable filenames/match-regex */
import {sendOrderSuccess} from './orderService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { baseURL } from 'api/apiService';

const printService = (metaData) => {
    // let ePos = new window.epson.ePOSDevice();
    const _user = localStorage.getItem('Apec_Account');
    const user = _user && JSON.parse(_user);
    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
        
    };
    const createRequestData = (items) => {
        var builder = new window.epson.ePOSBuilder();
        // Create a print document
        builder.addTextLang('en');
        builder.addTextSmooth(true);
        builder.addTextFont(builder.FONT_C);
        builder.addTextSize(2, 2);
        builder.addText(metaData.hotelName + '\n\n');
        builder.addTextFont(builder.FONT_A);
        builder.addTextSize(1, 1);
        builder.addText('Website: ' + metaData.website + '\n');
        // builder.addTextSize(1, 1);
        builder.addText('Add: ' + metaData.address + '\n');
        // builder.addTextSize(1, 1);
        builder.addText(
            'Tax code: ' +
        metaData.taxCode +
        '\t' +
        'Hotline: ' +
        metaData.phone +
        '\n'
        );
        // builder.addTextSize(1, 1);
        builder.addText('------------------------------------------------\n');
        builder.addTextAlign(builder.ALIGN_CENTER);
        builder.addTextSize(2, 2);
        builder.addTextFont(builder.FONT_C);
        builder.addText('Captain order\n\n');
        builder.addTextAlign(builder.ALIGN_LEFT);
        builder.addTextSize(1, 1);
        builder.addTextFont(builder.FONT_B);
        builder.addText(
            'Order Time: ' +
        items[0].order_time +
        '\t' +
        'Outlet: ' +
        items[0].rvcname +
        '\n'
        );
        builder.addTextSize(1, 1);
        builder.addText(
            'Order: ' +
        items[0].order_no +
        '\t\t' +
        '#Table: ' +
        items[0].table_no +
        '\n\n'
        );
        builder.addTextStyle(false, false, true);
        builder.addText(
            'No' +
        '\t' +
        'Item' +
        '\t\t' +
        'Unit' +
        '\t' +
        'Qty' +
        '\t' +
        'Remark' +
        '\n'
        );
        builder.addHLine(0, 200, builder.LINE_THIN_DOUBLE);
        builder.addTextFont(builder.FONT_A);
        builder.addTextStyle(false, false, false);
        let indexItem = 0;
        items.forEach((item, index) => {
            let qtySend = 0;
            if (item.deleted === 'Y') {
                if (item.last_sent_qty > 0) {
                    qtySend = 0 - item.last_sent_qty;
                }
            } else if (item.is_void === 'Y') {
                if (item.last_sent_qty > 0) {
                    qtySend = 0 - item.void_qtty;
                }
            } else {
                qtySend = item.quanitity - item.last_sent_qty;
            }
            if (qtySend !== 0) {
                indexItem += 1;
                builder.addText(indexItem + '\t' + item.iname + '\n');
                builder.addText('\t\t\t' + (item.unit?.trim() + '\t' + (qtySend) + '\t' +( item.remark ? item.remark : '')) + '\n');
                builder.addText('------------------------------------------------\n');
                isPrint = true;
            }
        });

        builder.addText('\n\n\n------------------------------------------------\n');
        builder.addText(
            'Print date: ' +
        new Date().toLocaleString() +
        ' Staff: ' +
        user.username + 
        '\n'
        );
        builder.addCut(builder.CUT_FEED);
    
        return builder.toString();
    };
    var isPrint = false;
    if (metaData.itemsends && metaData.itemsends.length > 0) {
        let dataGroup = groupBy(metaData.itemsends, 'ipaddress');
        let ipList = Object.keys(dataGroup);
        ipList.forEach((ip, index) => {
            isPrint = false;
            let items = dataGroup[ip];
            const itemIds = items.map(i => i.item_id).join(',');
            if (items.length > 0) {
                let request = '<?xml version="1.0" encoding="utf-8"?><s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body>' 
                + createRequestData(items) + '</s:Body></s:Envelope>';
                const _request = request.replaceAll('"','\'');
                //         var address =
                //   'http://' +
                //   ip +
                //   '/cgi-bin/epos/service.cgi?devid=local_printer&timeout=5000';
                // var epos = new window.epson.ePOSPrint(address);
                // epos.onerror = function (err) {
                //     toast.error(`[${items[0].printeR_NAME}]` + 'In order thất bại. Vui lòng kiểm tra Wifi và thử lại !', {
                //         position: 'top-right',
                //         theme: 'colored'
                //     });
                // };

                // epos.onoffline = function () {
                //     // Action success
                //     toast.error(`[${items[0].printeR_NAME}]` + 'Máy in đang offline', {
                //         position: 'top-right',
                //         theme: 'colored'
                //     });
                // };
                // epos.onreceive = function (res) {
                //     if (res.success) {
                //         toast.success(`[${items[0].printeR_NAME}]` + 'In order thành công !', {
                //             position: 'top-right',
                //             theme: 'colored'
                //         });
                //         // Action success
                //         sendOrderSuccess(
                //             {
                //                 orderNo: items[0].order_no,
                //                 itemIds: itemIds
                //             }
                //         );
                //     } else {
                //         if (res.code === 'EPTR_REC_EMPTY') {
                //             // Empty paper
                //             toast.error(`[${items[0].printeR_NAME}]` + 'Hết giấy', {
                //                 position: 'top-right',
                //                 theme: 'colored'
                //             });
                //         }
                //     }
                // };
                if (isPrint) {
                    // epos.send(request);
                    axios.post( baseURL + '/booking/api/print/send', {
                        data: _request,
                        ip: ip
                    })
                        .then(function (response) {
                            if(response.data) {
                                switch(response.data) {
                                    case 'NO_CONNECTION': 
                                        toast.error(`[${items[0].printeR_NAME}]` + 'Máy in đang offline', {
                                            position: 'top-right',
                                            theme: 'colored'
                                        });
                                        return;
                                    case 'SUCCESS': 
                                        toast.success(`[${items[0].printeR_NAME}]` + 'In order thành công !', {
                                            position: 'top-right',
                                            theme: 'colored'
                                        });
                                        sendOrderSuccess(
                                            {
                                                orderNo: items[0].order_no,
                                                itemIds: itemIds
                                            }
                                        );
                                        return;
                                    case 'FAILED': 
                                        toast.error(`[${items[0].printeR_NAME}]` + 'In order thất bại. Vui lòng kiểm tra Wifi và thử lại !', {
                                            position: 'top-right',
                                            theme: 'colored'
                                        });
                                        return;
                                    case 'EPTR_REC_EMPTY': 
                                        toast.error(`[${items[0].printeR_NAME}]` + 'Hết giấy', {
                                            position: 'top-right',
                                            theme: 'colored'
                                        });
                                        return;
                                    default: 
                                        return;
                                }
                            }
                        })
                        .catch(function (error) {
                            toast.error(`[${items[0].printeR_NAME}]` + 'In thất bại', {
                                position: 'top-right',
                                theme: 'colored'
                            });
                        });
                    // var a = 'http://20.208.210.164/booking/api/print/send';
                    toast.warn(`[${items[0].printeR_NAME}]` + 'Đang in...', {
                        position: 'top-right',
                        theme: 'colored'
                    });
                } else {
                    // No Item to send
                    // toast.error('Không có item để in', {
                    //     position: 'top-right',
                    //     theme: 'colored'
                    // });
                }
            }
        });
    }
};

export default printService;
