/* eslint-disable filenames/match-regex */
export const CURRENT_TABLE_ID = 'CURRENT_TABLE_ID';
export const CURRENT_TABLE_NAME = 'CURRENT_TABLE_NAME';
export const CURRENT_NUM_CHILDREN = 'CURRENT_NUM_CHILDREN';
export const CURRENT_NUM_ELDER = 'CURRENT_NUM_ELDER';
export const SET_SPLITED_ITEM_VALUE = 'SET_SPLITED_ITEM_VALUE';
export const REMOVE_SPLITED_ITEM_VALUE = 'REMOVE_SPLITED_ITEM_VALUE';
export const CLEAR_SPLITED_ITEM_VALUE = 'CLEAR_SPLITED_ITEM_VALUE';
export const SET_LOADING = 'SET_LOADING';
export const SET_ITEM_MENU = 'SET_ITEM_MENU';
