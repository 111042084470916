/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OrderAction from '../../../redux/actions/order/orderAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { splitOrder } from 'service/orderService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Content from './component/content/index';
import { FormLabel } from '@mui/material';
import { SVGReset, SVGSplitAmount } from 'assets/svg';
import './style.scss';
import globalAction from 'redux/actions/global/globalAction';
import LoadingTable from 'components/LoadingTable';
const index = (props) => {
    const { showModal, setShow, setShowSplit, showSplit } = props;
    const userName = localStorage.getItem('Apec_Account');
    // ===========================================================================
    // Selectors
    // ===========================================================================
    const { currentOrderId } = useSelector(
        (state: AppState) => state.currentOrderId
    );
    const { bookedSplitList } = useSelector((state: AppState) => state.bookedSplitList);
    const { currentTableName } = useSelector((state: AppState) => state.global);
    const {loading} = useSelector((state:AppState) => state.global);
    const {currentOrderNo} =  useSelector((state: AppState) => state.currentOrderNo);

    //============================================================================
    // Dispatch
    // ===========================================================================
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const dispatch = useDispatch();
    const _getBookedList = () => dispatch<any>(OrderAction.getBookedList(currentOrderId));
    const _clearItemSplitedList = () => dispatch<any>(globalAction.clearItemSplitedList());
    const _clearBookedSplitList = () => dispatch<any>(OrderAction.clearBookedSplitList());
    const _setLoading = (loading: boolean) => dispatch<any>(globalAction.setLoading(loading));    
    const _updateHeaderOrder = ()  => dispatch<any>(OrderAction.updateHeaderOrder());
    // ===========================================================================
    // Events Handlers
    // ===========================================================================
    const handleBack = () => {
        setShow(!showModal);
        setShowSplit(!showSplit);
    };
    const handleConfirm = async () => {
        _setLoading(true);
        const res = await splitOrder({
            username: JSON.parse(userName)?.username,
            isManual: true,
            isPart: false,
            valueSplit: 0,
            orderId: parseInt(currentOrderId),
            itemIds: formatItemIds()
        });
        if (res?.statusCode === 200) {
            toast.success('Split order successfully', {
                position: 'top-right',
                theme: 'colored'
            });
            _setLoading(false);
            _getBookedList();
            _updateHeaderOrder();
        } else {
            toast.error('Split order failed', {
                position: 'top-right',
                theme: 'colored'
            });
            _setLoading(false);
        }
        setShow(!showModal);
    };

    const handleResetAction = () =>{
        _clearItemSplitedList();
        _clearBookedSplitList();
    };
    // ===========================================================================
    // Ultility
    // ===========================================================================
    const formatItemIds = () => {
        let arr = [];
        bookedSplitList.map((item) => {
            arr.push(item.detailId);
        });
        return arr.join(',');
    };

    return (
        <React.Fragment>
            {loading && <LoadingTable/>}
            <Modal show={showModal} onHide={handleBack} centered={true} className='split-bill-manual'>
                <Modal.Body>
                    <div style={{ display: 'flex', marginBottom:20 }}>
                        <div>
                            <SVGSplitAmount />
                        </div>
                        <div style={{ margin: '5px 15px', width:'100%' }}>
                            <FormLabel
                                id="split-bill-manual-label"
                                style={{ color: '#202223', fontWeight: 600, fontSize: 20 }}
                            >
                                Split bill manual
                            </FormLabel>
                            <div style={{ display: 'flex', justifyContent:'space-between'}}>
                                <div>
                                    <FormLabel
                                        id="split-bill-manual-desc"
                                        style={{ color: '#0A0B0F', fontWeight: 500, fontSize: 16 }}
                                    >
                                        Order:{' '}
                                        <span style={{ color: '#FFA731',fontWeight: 700 }}>{currentOrderNo}</span>
                                    </FormLabel>
                                    <FormLabel
                                        id="split-bill-manual-desc"
                                        style={{ color: '#7A7A7B', fontWeight: 400, fontSize: 16, marginLeft:32 }}
                                    >
                                        Table: <span style={{ color: '#0A0B0F',fontWeight: 700 }}>{currentTableName}</span>
                                    </FormLabel>
                                </div>
                                <FormLabel
                                    onClick={() => handleResetAction()}
                                    id="split-bill-manual-desc"
                                    style={{ cursor:'pointer', color: '#0A0B0F', fontWeight: 700, fontSize: 16, display: 'flex', alignItems:'center', gap:5 }}
                                >
                                    <SVGReset/>
                                    <span style={{ color: '#0A0B0F' }}>Reset</span>
                                </FormLabel>
                            </div>
                        </div>
                    </div>
                    <Content
                    />
                    <div
                        style={{
                            marginTop: 12,
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 16
                        }}
                    >
                        <Button
                            variant="secondary"
                            onClick={() => {
                                handleBack();
                            }}
                            style={{ width: '50%', background: 'none', color: 'black' }}
                        >
                            <ArrowBackIcon fontSize="small" /> Quay lại
                        </Button>
                        <Button
                            onClick={() => handleConfirm()}
                            variant="primary"
                            style={{ width: '50%', background: '#A6815B' }}
                        >
                        Xác nhận
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default index;
