/* eslint-disable filenames/match-regex */
export const NameIcon = {
    DOTS: 'dots',
    RIGHT: 'right'
};

export const NameStyles = {
    TOTAL: 'total'
};

export const StatusType = {
    Active: 'A',
    Close: 'C'
};

export const VoucherType = {
    PERCENT: '2',
    VALUE: '1'
};

export const VoucherTypeNumber = {
    MANY: 'M'
};

export const OptionPaymentType = {
    PAYMENT_DIREC: 1,
    CITY_LEDGER: 2,
    POST_TO_ROOM: 3,
    FOC: 4
};

export const TypeTable = {
    COT: 'COT',
    BAN: 'S4',
    CUA: 'CUA'
};

