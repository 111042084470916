/* eslint-disable import/no-duplicates */
/* eslint-disable filenames/match-regex */
import React, { useEffect } from 'react';
import './styles.scss';

function NumberFlow(props) {
    return (
        <div 
            className="container-number-flow"
        >
            <span className='text-big bold color-white'>{props?.data.value}</span>
        </div>
    );
}

export default NumberFlow;
