/* eslint-disable filenames/match-regex */
import Moment from 'moment';
function formatMMDDYYYYPicker(date?: Date) {
    if (!date) return '';
    return formatDayjsDateMMDDYY(Moment(date).utc(true).format('MM/DD/YYYY'));
}

function formatYYYYMMDDPicker(date?: Date) {
    if (!date) return '';
    return formatDayjsDateMMDDYY(Moment(date).utc(true).format('YYYY/MM/DD'));
}

function formatDayAndTimePicker(date?: Date) {
    if (!date) return '';
    return formatDayjsDateMMDDYY(Moment(date).format('MM-DD-YYYY HH:mm:ss'));
}

function formatDayjsDateMMDDYY(date: string) {
    const data = date.split('/');
    const string = data[1] + '/' + data[0] + '/' + data[2];
    return string;
}

export default{
    formatMMDDYYYYPicker,
    formatDayjsDateMMDDYY,
    formatDayAndTimePicker,
    formatYYYYMMDDPicker
};
