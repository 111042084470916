/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './styles.scss';
import { SVGDown, SVGTable } from 'assets/svg';
import Header from 'layout/header';
import DropDownComponent from 'components/DropDown';
import TableComponent from 'components/Table';
import { useNavigate } from 'react-router-dom';
import { fetchListOrderPaid,  fetchTableMenu,  fetchArea, fetchLocation} from 'service/orderService';
import {  OrderPaidModel, TableModel } from 'models/order';
import { TableDropDown } from 'components/DropDown/types';
import { Language } from 'shared/Language';
import { MyTextInput } from 'components/Input';
import { TextFieldActions } from 'components/Input/types';
import IcSearch from '../../assets/svg/ic_search.svg';
import { TAB_HOME, TYPE_INPUT } from 'constants/AppEnums';
import LoadingTable from 'components/LoadingTable';
import { colorSet } from 'shared/styles/ColorSet';
import { connect } from 'react-redux';
import { changeCurrentLocations, getLocations, getTables } from 'redux/actions/home/homeAction';
import DatePickerDay from 'components/DatePickerDay';
import { arrKey, columns, FlexRow, styleRow } from 'assets/static-data/orderPaid';
import { TypeTable } from 'constants/Defines';
import Format from 'utils/Format';
import DateUtils from 'utils/DateUtils';
import { toast } from 'react-toastify';



function OrderPaidComponent(props) {
    const { getLocations, locations, changeCurrentLocations, getTables, currentLocation, tables } = props;
    const [listTable, setListTable] = useState<OrderPaidModel[]>([]);
    const [listTableName, setListTableName] = useState<TableDropDown[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [tab, setTab] = useState(TAB_HOME.ORDER_PAID);
    const [params, setParams] = useState({
        table: '',
        date: DateUtils.formatMMDDYYYYPicker(new Date()),
        text: '',
        location: '',
        area: ''
    });
    const navigate = useNavigate();
    const refInput = useRef<TextFieldActions>(null);
    const locationLocal = JSON.parse(localStorage.getItem('Location'));

    useEffect(() => {
        onFetchLocation();
    }, []);

    const onFetchLocation = async () => {
        fetchLocation().then((res) => {
            getLocations(res.metadata);
            if (res.metadata && res.metadata.length > 0) {
                onFetchArea(locationLocal);
                changeCurrentLocations(res.metadata.find(item => item.value === locationLocal));
            }
        });
    };

    const onFetchArea = async (value) => {
        fetchArea(value).then((res) => {
            if (res.metadata && res.metadata.length > 0) {
                setParams(last => {
                    last.location = res.metadata[0]?.value;
                    getListTable( res.metadata[0]?.value);

                    return last;
                });
                setParams(last => {
                    last.area = locationLocal;
                    return last;
                });
                getListOderByTable(params.table, params.date, value);
            }
        });
    };

    const getListOderByTable = useCallback(async (table: string, date: string, _location?: string) => {
        setLoading(true);
        const res = await fetchListOrderPaid(table,date, encodeURIComponent(refInput.current.getValue().toUpperCase()) ||'', _location || locationLocal);
        setLoading(false);
        
        if (res.message === 'Success') {
            const data = res.metadata as OrderPaidModel[];
            setListTable(data);
        } else {
            toast.error(res?.message || Language.err.notFound, {
                theme: 'colored'
            });
        }
    }, [locationLocal]);

    const getListTable = useCallback(async (value?: string) => {
        setLoading(true);
        const res = await fetchTableMenu(value || params.location);
        setLoading(false);
        if (res.message === 'Success') {
            const data = res.metadata as TableModel[];
            const tableName = [] as TableDropDown[];
            data?.filter(i => i.type !== TypeTable.COT && i.type !== TypeTable.CUA).map((item: TableModel) => tableName.push({id:item.table_id, value: item.table_name}));
            setListTableName(tableName);
        } else {
            toast.error(res?.message || Language.err.notFound, {
                theme: 'colored'
            });
        }
    }, [params.location]);

    const onChooseTable = (item: TableDropDown) => {
        setParams(last => {
            last.table = item.value;
            getListOderByTable(item.value, params.date);
            return last;
        });
    };

    const onChooseDate = (date: string) => {
        setParams(last => {
            last.date = date;
            getListOderByTable(params.table, date);
            return last;
        });
    };

    const onNavigateDetail = (item: OrderPaidModel) => {
        navigate('/order-detail', {state: item});
    };
 
    return (
        <div className='page-view'>
            <Header setTab={setTab} tab={tab} currentLocation={currentLocation} locations={locations} changeCurrentLocations={changeCurrentLocations} onFetchArea={onFetchArea}></Header>
            <div className='page-order-paid'>
                <div className='jus-between flex-row'>
                    <DropDownComponent 
                        label={Language.orderPaid.table} 
                        stylesContainer='btn' 
                        styleLabel='drop-label' 
                        iconLeft={<SVGTable/>} 
                        iconRight={<SVGDown/>}
                        widthMenu={200}
                        onPress={onChooseTable}
                        data={Format.sortUpTable(listTableName)}
                        styleIconRight={'order-paid-ic-right'}
                        stylesDrops={'width-100 padding'}
                        isClose
                    />
                    <DatePickerDay  onPress={onChooseDate} day={new Date()}/>
                    <MyTextInput 
                        rightIcon={IcSearch}
                        type={TYPE_INPUT.TEXT} 
                        ref={refInput} 
                        value={''}
                        containerInput={'input border'}
                        inputStyle={'input-style'}
                        placeHolder={Language.orderPaid.placeHolder}
                        onClickRightIcon={() => getListOderByTable(params.table, params.date)}
                    />
                </div>
                <TableComponent 
                    data={listTable} 
                    columns={columns} 
                    onPress={onNavigateDetail} 
                    styles='table-view' 
                    keyTable={arrKey} 
                    styleRow={styleRow}
                    flex={FlexRow}
                    nameIcon={'dots'}
                />
            </div>
            {isLoading && <LoadingTable />}
        </div>
    );

    
}

const mapStateToProps = (state, ownProps) => {
    return {
        locations: state.home.locations,
        tables: state.home.tables,
        currentLocation: state.home.currentLocation
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLocations: (payload) => {
            dispatch(getLocations(payload));
        },
        getTables: (payload) => {
            dispatch(getTables(payload));
        },
        changeCurrentLocations: (payload) => {
            dispatch(changeCurrentLocations(payload));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null)(OrderPaidComponent);
