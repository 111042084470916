/* eslint-disable filenames/match-regex */
export const colorSet = {
    default: {
        red: '#B51929',
        gray: '#595959',
        white: '#ffff',
        green: '#19975F',
        yellow: '#DC8108',
        yellow1: '#FFF4E6',
        yellow2: '#edb367'
    }
};
