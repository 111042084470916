/* eslint-disable filenames/match-regex */
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Language } from 'shared/Language';

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean> // Return success

function useCopyToClipboard(): [CopiedValue, CopyFn] {
    const [copiedText, setCopiedText] = useState<CopiedValue>(null);

    const copy: CopyFn = async text => {
        if (!navigator?.clipboard) {
            console.warn('Clipboard not supported');
            return false;
        }
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            toast.success(Language.success.copySuccess, {
                position: 'top-right',
                theme: 'colored'
            });
            return true;
        } catch (error) {
            console.warn('Copy failed', error);
            setCopiedText(null);
            toast.error(Language.err.copyErr, {
                position: 'top-right',
                theme: 'colored'
            });
            return false;
        }
    };

    return [copiedText, copy];
}

export default useCopyToClipboard;
