const DOMAIN_EMAIL_NOTIFICATION = '/admin/email';

export const EMAIL_NOTIFICATION = {
    LIST: `${DOMAIN_EMAIL_NOTIFICATION}/list`,
    GET: (id)=> `${DOMAIN_EMAIL_NOTIFICATION}/get/${id}`,
    CREATE: `${DOMAIN_EMAIL_NOTIFICATION}/create`,
    UPDATE: `${DOMAIN_EMAIL_NOTIFICATION}/update`,
    DETAIL: `${DOMAIN_EMAIL_NOTIFICATION}/find`,
    DELETE: `${DOMAIN_EMAIL_NOTIFICATION}/delete`,
    GET_MAILCHIMP: `${DOMAIN_EMAIL_NOTIFICATION}/template/list/mailchimp`,
    CSV_EMAIL_NOTIFICATION: (id)=>`${DOMAIN_EMAIL_NOTIFICATION}/${id}/csv`,
    UPLOAD_ATTACHMENT_NOTIFICATION: (id)=>`${DOMAIN_EMAIL_NOTIFICATION}/${id}/attachments_upload`,
    TRIGGER: `${DOMAIN_EMAIL_NOTIFICATION}/trigger`
};

export const ORDER = {
    GET_MENU: '/booking/api/pos/order/get-menu',
    GET_DISH: '/booking/api/pos/order/get-items',
    GET_ORDER_BY_TABLE: '/booking/api/table/get-order-by-table',
    GET_TABLE: '/booking/api/table/get-list',
    GET_ORDER_INFO: '/booking/api/pos/order/get-order',
    CREATE_ORDER: '/booking/api/pos/order/create',
    ADD_ITEM_INTO_ORDER: '/booking/api/pos/order/add-items',
    UPDATE_ORDER: '/booking/api/pos/order/update-order',
    REMOVE_ITEM: '/booking/api/pos/order/remove-item',
    UPDATE_ITEM: '/booking/api/pos/order/update-item',
    NEW_UPDATE_ITEM: '/booking/api/pos/order/update-items',
    SEND_ORDER: '/booking/api/pos/order/send-order',
    GET_AREA_CODE: '/booking/api/location/get-area',
    SEND_ORDER_SUCCESS: '/booking/api/pos/order/send-success',
    GET_ORDER_PAID: '/booking/api/food-court/get-order?',
    GET_ORDER_DETAIL: '/booking/api/food-court/get-order-detail?orderId=',
    GET_ORDER_UNPAID: '/booking/api/food-court/get-order-unpaid?',
    UPDATE_DISCOUNT_ITEM: '/booking/api/food-court/update-discount-item',
    UPDATE_DISCOUNT_ORDER: '/booking/api/food-court/update-discount-order',
    MERGE_ORDER: '/booking/api/food-court/merge-order',
    GET_VOUCHER: '/booking/api/food-court/get-voucher-info?voucherNo=',
    UPDATE_SVC_VAT: '/booking/api/food-court/update-svc-vat',
    SPLIT_ORDER:'/booking/api/food-court/split-order',
    UPDATE_TABLE: '/booking/api/food-court/update-position-table',
    INFO_HOTEL: '/booking/api/food-court/get-hotel-detail',
    LIST_SOURCE: '/booking/api/food-court/get-list-source?type=',
    PAYMENT_ORDER: '/booking/api/food-court/payment-order',
    LIST_ROOM: '/booking/api/food-court/get-list-room?buildingCode=',
    CITY_INFO: '/booking/api/food-court/get-city-ledger-info?cityLedgerId=',
    ROOM_INFO: '/booking/api/food-court/get-room-info?roomId='
};
