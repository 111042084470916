/* eslint-disable react/react-in-jsx-scope */
import { Modal } from '@mui/material';
import DropDownComponent from 'components/DropDown';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Language } from 'shared/Language';
import { PopupDiscountActions, PopupDiscountProps } from './types';
import IcCopy from '../../../assets/svg/ic_copy.svg';
import Grid from '@mui/material/Grid';
import useCopyToClipboard from 'utils/Copy';
import { VoucherType, VoucherTypeNumber } from 'constants/Defines';
import Format from 'utils/Format';
import { TableDropDown } from 'components/DropDown/types';
import { SVGUpDown } from 'assets/svg';

const PopupDiscount = forwardRef<PopupDiscountActions, PopupDiscountProps>(({ iconHead, title, des, dataDrop, defaultDrop, onPressConfirm, data, onPressItem}: PopupDiscountProps, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const onCopy = useCopyToClipboard();
    const [id, setId] = useState<string>('');
    const [defaultValue, setDefaultValue] = useState<string>(defaultDrop);

    const showModal = (_id: string) => {
        setId(_id);
        setOpen(true);
        setDefaultValue(defaultDrop);
    };

    const hideModal = () => {
        setOpen(false);
    };

    useImperativeHandle(ref, () => ({
        showModal,
        hideModal
    }));

    const onChooseCustomer = (item: TableDropDown) => {
        onPressItem(item, '2');
        setDefaultValue(item.value);
    };

    const renderViewItem = useCallback((label: string, content: string, styleValue: string, icon?: any, onClickIcon?: any) => {
        return(
            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                <span className='text-small-x color-gray-1 regular mgb-5'>{label}</span>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <span className={`${styleValue}`}>{content}</span>
                    {label === Language.payment.voucherModal.valueVoucher && VoucherType.PERCENT === data.valueType && <span className={'text-small-x color-gray-1 regular'}>(Tối đa {data.voucherValue} VNĐ)</span>}
                    {icon && <img src={icon} onClick={() => onClickIcon(content)}/>}
                </div>
            </div>
        );

    }, [data.valueType, data.voucherValue]);

    return (
        <Modal
            open={open} 
            onClose={hideModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onBackdropClick={() => setOpen(false)}
        >
            <div id="popup-modal">
                <div className="popup-container">
                    <div className="popup-head">
                        <div className="popup-head-icon">
                            {iconHead || <img src="assets/icon/Meal/index.svg"/>}
                        </div>
                        <div className="popup-head-detail">
                            <div className="detail-title">
                                {title || 'This is title'}
                            </div>
                            <div className="detail-des">
                                {des}
                            </div>
                        </div>
                    </div>
                    <div className="voucher-info-text">{Language.orderPaid.voucherInfo}:</div>
                    <div className="detail-info">
                        <div className="block-detail">
                            <div className="row-detail">
                                {renderViewItem(Language.payment.voucherModal.voucherCode, `${id}`, 'color-black bold text-normal mgr-5', IcCopy, onCopy[1])}
                                {/* {renderViewItem(Language.payment.voucherModal.nameUser, `${data.listCustomer[0].text}`, 'color-black bold text-normal', IcUpDown)} */}
                                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                               
                                    <span className='text-small-x color-gray-1 regular mgb-5'>{Language.payment.voucherModal.nameUser}</span>
                                    <DropDownComponent 
                                        data={dataDrop}
                                        label={Language.payment.choose}
                                        styleLabel={'color-black bold text-normal'}
                                        stylesContainer={'drops-down-popup-customer'}
                                        defaultValue={defaultValue || dataDrop[0]?.value}
                                        onPress={onChooseCustomer}
                                        iconRight={<SVGUpDown/>}
                                        stylesDrops={'width-100'}
                                    /> 
                                </div>
                            </div>
                            <div className="row-detail">
                                {renderViewItem(Language.payment.voucherModal.expiry, `${data.checkExpiryDate}`, 'color-black bold text-normal mgr-5')}
                                {renderViewItem(Language.payment.voucherModal.type, 
                                    `${data.voucherType === VoucherTypeNumber.MANY ? Language.payment.voucherModal.many : Language.payment.voucherModal.once}`,
                                    `color-white medium text-small ${data.voucherType === VoucherTypeNumber.MANY ? 'txt-bg-blue' : 'txt-bg'}`)}
                            </div>
                            <div className="row-detail mgb-0">
                                {renderViewItem(Language.payment.voucherModal.valueVoucher,  
                                    VoucherType.VALUE === data.valueType ? `${Format.formatMoneyDots(data.voucherValue)} VND` : `${Format.formatMoneyDots(data.valuept)}%`
                                    , 'color-black bold text-normal mgr-5',)}
                                {renderViewItem(VoucherType.VALUE === data.valueType ?  Language.payment.voucherModal.residualValue : Language.payment.voucherModal.remainingNumber,
                                    VoucherType.VALUE === data.valueType ? `${Format.formatMoneyDots(data.voucherValueRemain)}` : `${data.numberRemain} Lần`, 
                                    'color-red bold text-normal')
                                }
                            </div>
                        </div>
                    </div>
                    <div className="voucher-info-text">{Language.payment.voucherModal.listVoucherOfHotel}
                        <span className='text-normal medium color-yellow-5'> {`(${data.saleItem.length})`}</span></div>
                    <div className="detail-info">
                        <Grid container spacing={2} className='grid-container'>
                            {data.saleItem.map((item, index) => {
                                return(
                                    <Grid key={index} item xs={6}>
                                        <span className={'space-nowrap block-detail-row'}>{item.name}
                                            <span className='text-normal color-gray-1 regular'>{`(${item.code})`}</span>
                                        </span>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div onClick={() => setOpen(false)} className="button-left">{Language.common.close}</div>
                    </div>
                </div>
            </div>
        </Modal>
    );
});
export default PopupDiscount;
