/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { SVGCloseMerge, SVGDown, SVGMerge, SVGNote, SVGSuccessInfo, SVGTable } from 'assets/svg';
import DropDownComponent from 'components/DropDown';
import TableComponent from 'components/Table';
import { useNavigate } from 'react-router-dom';
import {  fetchTableMenu,  fetchArea, fetchLocation, fetchListOrderUnPaid, fetchMergeOrder} from 'service/orderService';
import {  OrderPaidModel, TableModel } from 'models/order';
import { TableDropDown } from 'components/DropDown/types';
import { Language } from 'shared/Language';
import { MyTextInput } from 'components/Input';
import { TextFieldActions } from 'components/Input/types';
import IcSearch from '../../assets/svg/ic_search.svg';
import { TYPE_INPUT } from 'constants/AppEnums';
import LoadingTable from 'components/LoadingTable';
import { colorSet } from 'shared/styles/ColorSet';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeCurrentLocations, getLocations, getTables } from 'redux/actions/home/homeAction';
import DatePickerDay from 'components/DatePickerDay';
import MyButton from 'components/Button.tsx';
import HeaderOrder from 'layout/header/header-order';
import { AppState } from 'redux/store';
import GlobalAction from '../../redux/actions/global/globalAction';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/Button';
import OrderAction from '../../redux/actions/order/orderAction';

import { toast } from 'react-toastify';
import { arrKey, columns, FlexRow, styleRow } from 'assets/static-data/merge';
import { TypeTable } from 'constants/Defines';
import Format from 'utils/Format';

function MergeOrderComponent(props) {
    const { getLocations,changeCurrentLocations,currentLocation} = props;
    const [listTable, setListTable] = useState<OrderPaidModel[]>([]);
    const [listTableName, setListTableName] = useState<TableDropDown[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [orderNoMerge, setOrderNoMerge] = useState<string | null>();
    const [orderIdMerge, setOrderIdMerge] = useState<number>();
    const [showSuccess,setShowSuccess] = useState<boolean>(false);
    const currentOrder =  useSelector((state: AppState) => state);

    // ===========================================================================
    // Selectors
    // ===========================================================================
    const { currentOrderId } = useSelector(
        (state: AppState) => state.currentOrderId);
    const { currentOrderNo } = useSelector(
        (state: AppState) => state.currentOrderNo);
    const {currentTableId} = useSelector((state: AppState) => state.global);
    const dispatch = useDispatch();  
    const [params, setParams] = useState({
        table: '',
        date: '',
        text: '',
        location: '',
        area: ''
    });
    // const navigate = useNavigate();
    const refInput = useRef<TextFieldActions>(null);
    const locationLocal = JSON.parse(localStorage.getItem('Location'));

    useEffect(() => {
        // getListOderForMerge('', '');
        // getListTable();
        onFetchLocation();
    }, []);

    const onFetchLocation = useCallback(async () => {
        fetchLocation().then((res) => {
            getLocations(res.metadata);
            if (res.metadata && res.metadata.length > 0) {
                setParams(last => {
                    last.area = res.metadata[0].value;
                    return last;
                });
                onFetchArea(res.metadata[0].value);
                changeCurrentLocations(res.metadata[0]);
            }
        });
    }, []);

    const onFetchArea = useCallback(async (value) => {
        fetchArea(value).then((res) => {
            if (res.metadata && res.metadata.length > 0) {
                setParams(last => {
                    last.location = res.metadata[0]?.value;
                    getListTable(res.metadata[0]?.value);
                    return last;
                });
                setParams(last => {
                    last.area = value;
                    return last;
                });
                getListOderForMerge(params.table, params.date);
            }
        });
    }, []);

    const getListOderForMerge = useCallback(async (table, date) => {
        setLoading(true);
        const res = await fetchListOrderUnPaid(table, date, encodeURIComponent(refInput.current.getValue().toUpperCase()) ||'', locationLocal);
        setLoading(false);

        if (res.message === 'Success') {
            const data = res.metadata as OrderPaidModel[];
            const _data = data.filter(item => item.order_id !== currentOrderId);
            setListTable(_data);
        }

    }, [currentOrderId, locationLocal]);

    const getListTable = useCallback(async (value?: string) => {
        console.log(params);
        setLoading(true);
        const res = await fetchTableMenu(value || params?.location);
        setLoading(false);

        if (res.message === 'Success') {
            const data = res.metadata as TableModel[];
            const tableName = [] as TableDropDown[];
            data?.filter(i => i.type !== TypeTable.COT && i.type !== TypeTable.CUA).map((item: TableModel) => tableName.push({id:item.table_id, value: item.table_name}));
            setListTableName(tableName);
        }
    }, [params]);

    const onChooseTable = (item: TableDropDown) => {
        setParams(last => {
            last.table = item.value;
            getListOderForMerge(item.value, last.date);
            return last;
        });
        dispatch(GlobalAction.changeCurrentTables(item?.id));
        dispatch(GlobalAction.changeCurrentTablesName(item?.value));
    };

    const onChooseDate = (date: string) => {
        setParams(last => {
            last.date = date;
            getListOderForMerge(last.table, date);
            return last;
        });
    };

    const onChooseColumn = (item: OrderPaidModel) => {
        setOrderNoMerge(item.order_no);
        setOrderIdMerge(item.order_id);
    };
    const _getBookedList = () => dispatch<any>(OrderAction.getBookedList(currentOrderId));

    const onMerge = useCallback(async() => {
        setLoading(true);
        const params = {
            orderId: currentOrderId,
            mergedOrderId: orderIdMerge
        };
        const res = await fetchMergeOrder(params);
        setLoading(false);

        if (res.message === 'Success') { 
            _getBookedList();
            setShowSuccess(!showSuccess);
            toast.success(Language.success.mergeSuccess, {
                position: 'top-right',
                theme: 'colored'
            });
        } else {
            toast.error(res?.message || Language.err.mergeErr, {
                position: 'top-right',
                theme: 'colored'
            });
        }
    }, [currentOrderId, orderIdMerge, showSuccess]);

    const closeMerge = () => {
        setOrderNoMerge(null);
    };
    let navigate = useNavigate();
    const ConfirmHandler = () => {
        
        navigate(`/order/${currentOrderId}`);
    };
    return (
        <React.Fragment>
            <div className='page-merge-view'>
                <HeaderOrder
                    label={Language.merge.mergeBill}
                    styleLabel={'text-big-x bold color-black mgr-5'}
                    labelButtonRight={Language.merge.note}
                    stylesButtonRight={'merge-btn-right'}
                    iconButton={<SVGNote color={colorSet.default.red}/>}
                    backUrl = {`/order/${currentOrderId}`}
                />
                <div className='page-order-paid'>
                    <div className='jus-between flex-row-merge'>
                        <DropDownComponent 
                            label={Language.orderPaid.table} 
                            stylesContainer='btn' 
                            styleLabel='text-normal regular color-gray-1 mgl-5' 
                            iconLeft={<SVGTable/>} 
                            iconRight={<SVGDown/>}
                            styleIconRight={'icon-right-drops-merge'}
                            widthMenu={200}
                            onPress={onChooseTable}
                            data={Format.sortUpTable(listTableName)}
                            stylesDrops={'width-100 padding'}
                            isClose
                        />
                        <DatePickerDay  onPress={onChooseDate}/>
                        <MyTextInput 
                            rightIcon={IcSearch}
                            type={TYPE_INPUT.TEXT} 
                            ref={refInput} 
                            value={''}
                            containerInput={'input border'}
                            inputStyle={'input-style'}
                            placeHolder={Language.orderPaid.placeHolder}
                            onClickRightIcon={() => getListOderForMerge(params.table, params.date)}
                        />
                    </div>
                </div>
                <div style={{padding: '0px 24px'}}>
                    <TableComponent 
                        data={listTable} 
                        columns={columns} 
                        onPressColumn={onChooseColumn}
                        styles='table-view-merge' 
                        keyTable={arrKey} 
                        styleRow={styleRow}
                        flex={FlexRow}
                    />
                </div>

                <div className='merge-container'>
                    <div className='flex-row center just-start'>  
                        <span className='text-big-x regular color-black-1'>{Language.merge.mergeOrder}</span>
                        <span className='text-big regular color-white color-orange'>{currentOrder?.currentOrderNo?.currentOrderNo}</span>
                        <span className='text-big-x regular color-black-1'>{Language.merge.withOrder}</span>
                        {orderNoMerge && <div className='color-yellow'>
                            <span className='text-big regular color-yellow-5 mgr-5'>{`${orderNoMerge}`}</span>
                            <div className='center' onClick={closeMerge}><SVGCloseMerge/></div>
                        </div>}
                    </div>
                    <MyButton 
                        label={Language.merge.mergeBill} 
                        iconLeft={<SVGMerge/>}
                        styleContainer={'btn-merge'}
                        styleLabel={'text-normal color-white bold mgl-10'}
                        onPress={onMerge}
                    /> 
                </div> 
                {isLoading && <LoadingTable />}
            </div>
           
            <Modal show = {showSuccess} centered>
                <Modal.Body>
                    <div style={{display:'flex', justifyContent:'center'}}><SVGSuccessInfo/></div>
                    <div style={{display:'flex',justifyContent:'center', color:'#0A0B0F',marginBottom:24, fontSize:20, fontWeight:600}}>Successful</div>
                    <Button
                        onClick={() => ConfirmHandler()}
                        variant="primary"
                        style={{ margin: 'auto', background: '#A6815B', width:'100%' }}
                    >
                        Xác nhận
                    </Button>
                </Modal.Body>
            </Modal>
            {isLoading && <LoadingTable />}
        </React.Fragment>
    );

    
}

const mapStateToProps = (state, ownProps) => {
    return {
        locations: state.home.locations,
        tables: state.home.tables,
        currentLocation: state.home.currentLocation
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLocations: (payload) => {
            dispatch(getLocations(payload));
        },
        getTables: (payload) => {
            dispatch(getTables(payload));
        },
        changeCurrentLocations: (payload) => {
            dispatch(changeCurrentLocations(payload));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null)(MergeOrderComponent);
