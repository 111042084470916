/* eslint-disable react/react-in-jsx-scope */
import { Modal } from '@mui/material';
import { TypePrint } from 'constants/AppEnums';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Language } from 'shared/Language';
import { PopupPrintfAction, PopupPrintfProps } from './types';
import './styles.scss';

const PopupPrint = forwardRef<PopupPrintfAction, PopupPrintfProps>(({title, onConfirm}: PopupPrintfProps, ref) => {
    const [type, setType] = useState<Number>(TypePrint.INVOICE);
    const [visible, setVisible] = useState<boolean>(false);

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const renderCheckBox = (types: number, label: string) => {
        
        const onChooseType = () => {
            setType(types);
        };

        return(
            <div onClick={onChooseType}>
                <div className='bill-item'>
                    {
                        type === types ? (<img src="assets/icon/RadioCheckbox/Checked.svg" alt="checkbox" />) : (<img src="assets/icon/RadioCheckbox/UnChecked.svg" alt="checkbox" />)
                    }
                    <div style={{marginLeft: 8}}>{label}</div>
                </div>
            </div>
        );
    };

    const onConfirmPrintf = () => {
        onConfirm?.(type);
        hideModal();
    };

   
    useImperativeHandle(ref, () => ({
        showModal,
        hideModal
    }));

    return (
        <Modal open={visible}>
            <div className='modal-print'>
                <div className="popup-print-container">
                    <div className="popup-print-top">
                        <div className="header text-big color-black bold">
                            <img width={22} height={22} src="assets/icon/Print/index.svg" alt="print" className='mgr-5'/> 
                            {title}
                        </div>
                        {renderCheckBox(TypePrint.INVOICE, Language.payment.printInvoice)}
                        {renderCheckBox(TypePrint.BILL, Language.payment.print)}
                        {renderCheckBox(TypePrint.BILL_MN,Language.payment.printBillMN)}
                        <div className="button-contain">
                            <div onClick={hideModal} className="button-left">{Language.common.cancel}</div>
                            <div className="button-right" onClick={onConfirmPrintf}>{Language.common.confirm}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
});
export default PopupPrint;
