/* eslint-disable @typescript-eslint/no-shadow */
// eslint-disable-next-line filenames/match-regex
import { SVGSwitch } from 'assets/svg';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import React from 'react';
import OrderAction from '../../../../../redux/actions/order/orderAction';
import globalAction from '../../../../../redux/actions/global/globalAction';
const OrderNo = (props) => {
    const { listOrderBooked,booked, text } = props;
    // ===========================================================================
    // Selectors
    // ===========================================================================
    const {currentOrderNo} =  useSelector((state: AppState) => state.currentOrderNo);
    const {splitedItem} =  useSelector((state: AppState) => state.global);

    // ===========================================================================
    // Dispatch
    // ===========================================================================
    const dispatch = useDispatch();
    const _addBookedSplitList = (bookedSplitList:any) => dispatch<any>(OrderAction.addBookedSplitList(bookedSplitList));
    const _addSplitedItem = (splitedItem:any) => dispatch<any>(globalAction.addSplitedItem(splitedItem));
    const _removeItemSplitedList = (detailId: number) => dispatch<any>(globalAction.removeItemSplitedList(detailId));
    const _removeBookedSplitList = (detailId:number) => dispatch<any>(OrderAction.removeBookedSplitList(detailId));
    
    // ===========================================================================
    // Event handlers
    // ===========================================================================
    const handlerSwitch = (detailId: number) => {
        if(booked){
            const itemAddSplit = listOrderBooked.find(item => item.detailId === detailId);
            _addBookedSplitList(itemAddSplit);
            _addSplitedItem(detailId);       
        }
        else{
            _removeItemSplitedList(detailId);
            _removeBookedSplitList(detailId);
        }
    };
    return (
        <React.Fragment>
            <div className="order-no-contain">
                <div className="text-order-no">Order No:</div>
                <div style ={{display:'flex', justifyContent:'space-between', alignItems:'center', width: '100%', overflowY: 'auto'}}>
                    <div className="code-order-no">{currentOrderNo}{text}</div>
                    <div style={{background:'#F0F0F0', color: '#595959', padding:'2px 8px', borderRadius:100, whiteSpace: 'nowrap'}}><span>{booked ? listOrderBooked?.length- splitedItem?.length:listOrderBooked?.length} items</span></div>
                </div>
            </div>
            <div className='order-list'>
                {
                    booked && listOrderBooked.map((item, index) =>
                    {
                        return (
                            !splitedItem.includes(item.detailId) && <div className='order-item' key ={index}>
                                <div className='order-item-left'>
                                    <div className='order-item-left-price'>
                                        <img src={'../../../../../assets/icon/DefaultImageMeal/index.svg'} style={{ width: 55, height: 55, borderRadius:4 }} alt="can't load" />
                                        <div>
                                            <h5>{item?.itemName}</h5>
                                            <div className='order-item-price-qty'>
                                                <p>{item?.price}</p>
                                                <p style={{color:'#0A0B0F', fontWeight:500}}>x{item?.qty}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='order-item-right' onClick={() => handlerSwitch(item.detailId)}>
                                    <SVGSwitch/>
                                </div>
                            </div>
                        );
                    })
                }
                {
                    !booked && listOrderBooked.map((item, index) =>
                    {
                        return (
                            <div className='order-item' key ={index}>
                                <div className='order-item-left'>
                                    <div className='order-item-left-price'>
                                        <img src={'../../../../../assets/icon/DefaultImageMeal/index.svg'} style={{ width: 55, height: 55, borderRadius:4 }} alt="can't load" />
                                        <div>
                                            <h5>{item?.itemName}</h5>
                                            <div className='order-item-price-qty'>
                                                <p>{item?.price}</p>
                                                <p style={{color:'#0A0B0F', fontWeight:500}}>x{item?.qty}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='order-item-right' onClick={() => handlerSwitch(item.detailId)}>
                                    <SVGSwitch/>
                                </div>
                            </div>
                        );
                    })
                }
            </div>

        </React.Fragment>
    );
}; 
export default OrderNo;
