/* eslint-disable filenames/match-regex */
import { InfoHotelModel, OrderModel } from 'models/order';
import DateUtils from 'utils/DateUtils';
import Format from 'utils/Format';

export function renderHTMLBill(data: OrderModel, infoHotel: InfoHotelModel) {
    const newDate = DateUtils.formatDayAndTimePicker(new Date()).split('/')[1];
    const date = newDate.split(' ');
    const renderColumn = () => {
        let _column = '';
        for(let i=0; i < data.items.length; i++) {
            _column += `<tr style="vertical-align:top;">
                <td style="width:0px;height:19px;"></td>
                <td class="csD5125DAB" colspan="3" style="width:12px;height:12px;line-height:10px;text-align:center;vertical-align:middle;"><nobr>${i + 1}</nobr></td>
                <td class="csC76BB281" colspan="7" style="width:68px;height:12px;line-height:10px;text-align:left;vertical-align:middle;"><nobr>${data?.items?.[i]?.itemName}</nobr></td>
                <td class="csD5125DAB" colspan="2" style="width:20px;height:12px;line-height:10px;text-align:center;vertical-align:middle;"><nobr>${data?.items?.[i]?.unitName}</nobr></td>
                <td class="csD5125DAB" colspan="3" style="width:22px;height:12px;line-height:10px;text-align:center;vertical-align:middle;"><nobr>${data?.items?.[i]?.qty}</nobr></td>
                <td class="csD5125DAB" colspan="7" style="width:41px;height:12px;line-height:10px;text-align:right;vertical-align:middle;"><nobr>${data?.items?.[i]?.price}</nobr></td>
                <td class="csD5125DAB" colspan="5" style="width:46px;height:12px;line-height:10px;text-align:right;vertical-align:middle;"><nobr>${data?.items?.[i]?.total}</nobr></td>
            </tr>`;
        }
        return _column;
    };
    return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<!-- saved from url=(0016)http://localhost -->
<html>
<head>
	<title>Document</title>
	<meta HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"/>
	<style type="text/css">
		.cs4FADFF8F {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right:#FFFFFF 2px solid;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:normal; }
		.cs4B1904F1 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right:transparent 1px solid;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:normal; }
		.csC76BB281 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom:#000000 1px dashed;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-top:3px;padding-left:3px;padding-bottom:3px;}
		.csD5125DAB {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom:#000000 1px dashed;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-top:3px;padding-left:3px;padding-right:3px;padding-bottom:3px;}
		.cs8EA18762 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:bold; font-style:normal; padding-left:2px;}
		.csFEAFFC4D {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:bold; font-style:normal; padding-left:2px;padding-right:2px;}
		.cs8F0C1850 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:italic; padding-left:2px;padding-right:2px;}
		.cs5F7AA6EF {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:normal; padding-left:2px;padding-right:2px;}
		.cs6FC5B17 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:13px; font-weight:bold; font-style:normal; padding-left:2px;padding-right:2px;}
		.csA8DCAC4A {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:13px; font-weight:normal; font-style:normal; }
		.cs636938EA {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:italic; padding-left:2px;}
		.csA676D0BF {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:normal; }
		.csB8BEB134 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:normal; padding-left:1px;}
		.csADEC2D6F {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:normal; padding-left:2px;}
		.csB839DCA6 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-left:3px;}
		.cs27313B43 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-left:4px;}
		.cs9691FC92 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-left:7px;}
		.cs693942EE {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-top:3px;padding-left:3px;padding-bottom:3px;}
		.csDA17CD4C {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-top:3px;padding-left:3px;padding-right:3px;padding-bottom:3px;}
		.csAC6A4475 {height:0px;width:0px;overflow:hidden;font-size:0px;line-height:0px;}
	</style>
</head>
<body leftMargin=10 topMargin=10 rightMargin=10 bottomMargin=10 style="background-color:#FFFFFF; overflow-y: auto; width: 330px; margin-top: 20px">
<table cellpadding="0" cellspacing="0" border="0" style="border-width:0px;empty-cells:show;width:242px;height:410px;position:relative; ">
	<tr>
		<td style="width:0px;height:0px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:8px;"></td>
		<td style="height:0px;width:9px;"></td>
		<td style="height:0px;width:6px;"></td>
		<td style="height:0px;width:7px;"></td>
		<td style="height:0px;width:10px;"></td>
		<td style="height:0px;width:4px;"></td>
		<td style="height:0px;width:5px;"></td>
		<td style="height:0px;width:23px;"></td>
		<td style="height:0px;width:16px;"></td>
		<td style="height:0px;width:15px;"></td>
		<td style="height:0px;width:11px;"></td>
		<td style="height:0px;width:10px;"></td>
		<td style="height:0px;width:4px;"></td>
		<td style="height:0px;width:14px;"></td>
		<td style="height:0px;width:6px;"></td>
		<td style="height:0px;width:6px;"></td>
		<td style="height:0px;width:5px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:4px;"></td>
		<td style="height:0px;width:3px;"></td>
		<td style="height:0px;width:22px;"></td>
		<td style="height:0px;width:16px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:30px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:4px;"></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:12px;"></td>
		<td></td>
		<td></td>
		<td class="csA676D0BF" colspan="24" style="width:229px;height:12px;line-height:10px;text-align:left;vertical-align:top;"><nobr>${infoHotel.hotelName}</nobr></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:13px;"></td>
		<td></td>
		<td></td>
		<td class="csA676D0BF" colspan="5" style="width:36px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>Website:</nobr></td>
		<td class="cs636938EA" colspan="18" style="width:190px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>${infoHotel.website}</nobr></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:13px;"></td>
		<td></td>
		<td></td>
		<td class="csA676D0BF" colspan="3" style="width:22px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>Add:</nobr></td>
		<td class="csB8BEB134" colspan="21" rowspan="2" style="width:206px;height:23px;line-height:10px;text-align:left;vertical-align:top;"><nobr>${infoHotel.address}</nobr></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:10px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:13px;"></td>
		<td></td>
		<td></td>
		<td class="csA676D0BF" colspan="6" style="width:41px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>Tax&nbsp;code:</nobr></td>
		<td class="csADEC2D6F" colspan="4" rowspan="2" style="width:80px;height:24px;line-height:10px;text-align:left;vertical-align:top;"><nobr>${infoHotel.taxCode}</nobr></td>
		<td></td>
		<td class="csA676D0BF" colspan="6" style="width:36px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>Hotline:</nobr></td>
		<td class="csADEC2D6F" colspan="8" style="width:76px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>${infoHotel.fax}</nobr></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:11px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:11px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:2px;"></td>
		<td></td>
		<td class="csA8DCAC4A" colspan="26" style="width:241px;height:2px;text-align:left;vertical-align:top;"><div style="overflow:hidden;width:241px;height:2px;">
			<!--[if lt IE 7]><img alt="" src="_files/4133685977.gif" style="width:241px;height:2px;filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='_files/3610321722.png',sizingMethod='scale');" /><div style="display:none"><![endif]--><img alt="" src="_files/3610321722.png" style="width:241px;height:2px;" /><!--[if lt IE 7]></div><![endif]--></div>
		</td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:22px;"></td>
		<td class="cs6FC5B17" colspan="27" style="width:238px;height:22px;line-height:15px;text-align:center;vertical-align:middle;"><nobr>H&#211;A&nbsp;ĐƠN&nbsp;B&#193;N&nbsp;H&#192;NG</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:10px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:13px;"></td>
		<td class="csADEC2D6F" colspan="5" style="width:29px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>Ng&#224;y:</nobr></td>
		<td class="cs27313B43" colspan="8" style="width:87px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>${data.createdDate}</nobr></td>
		<td class="csADEC2D6F" colspan="9" style="width:51px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>Địa&nbsp;điểm:</nobr></td>
		<td class="csB839DCA6" colspan="10" style="width:84px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>${data.location}</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:9px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:13px;"></td>
		<td class="csADEC2D6F" colspan="6" style="width:39px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>Order:</nobr></td>
		<td class="cs9691FC92" colspan="8" style="width:84px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>${data.orderNo}</nobr></td>
		<td class="csADEC2D6F" colspan="8" style="width:48px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>Ng&#224;y&nbsp;in:</nobr></td>
		<td class="csB839DCA6" colspan="11" rowspan="2" style="width:90px;height:23px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>${date[0]}<br/>${date[1]}</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:2px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:24px;"></td>
		<td class="cs4FADFF8F" colspan="3" style="width:16px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>STT</nobr></td>
		<td class="cs4FADFF8F" colspan="7" style="width:69px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>Mặt&nbsp;h&#224;ng</nobr></td>
		<td class="cs4FADFF8F" colspan="2" style="width:24px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>ĐVT</nobr></td>
		<td class="cs4FADFF8F" colspan="3" style="width:26px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>SL</nobr></td>
		<td class="cs4FADFF8F" colspan="7" style="width:45px;height:24px;line-height:9px;text-align:right;vertical-align:middle;"><nobr>Đơn&nbsp;gi&#225;</nobr></td>
		<td class="cs4B1904F1" colspan="5" style="width:51px;height:24px;line-height:9px;text-align:right;vertical-align:middle;"><nobr>Th&#224;nh&nbsp;tiền</nobr></td>
	</tr>
    ${renderColumn()}
	<tr style="vertical-align:top;">
		<td style="width:0px;height:10px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:17px;"></td>
		<td class="cs8EA18762" colspan="9" style="width:71px;height:17px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Th&#224;nh&nbsp;tiền:</nobr></td>
		<td class="csFEAFFC4D" colspan="14" style="width:129px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data.amountNet)}</nobr></td>
		<td class="csFEAFFC4D" colspan="4" style="width:32px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:16px;"></td>
		<td class="cs8EA18762" colspan="8" style="width:48px;height:16px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Giảm&nbsp;gi&#225;:</nobr></td>
		<td class="cs8EA18762" colspan="4" style="width:63px;height:16px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csFEAFFC4D" colspan="11" style="width:87px;height:16px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data.totalDiscount)}</nobr></td>
		<td class="csFEAFFC4D" colspan="4" style="width:32px;height:16px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:17px;"></td>
		<td class="cs8EA18762" colspan="11" style="width:102px;height:17px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Thuế&nbsp;&amp;&nbsp;ph&#237;&nbsp;dịch&nbsp;vụ:</nobr></td>
		<td class="csFEAFFC4D" colspan="13" style="width:99px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(Number(data.svc) + Number(data.vat))}</nobr></td>
		<td class="csFEAFFC4D" colspan="3" style="width:31px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:17px;"></td>
		<td class="cs8EA18762" colspan="14" style="width:127px;height:17px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Tổng&nbsp;tiền&nbsp;phải&nbsp;thanh&nbsp;to&#225;n:</nobr></td>
		<td class="csFEAFFC4D" colspan="10" style="width:74px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data.total)}</nobr></td>
		<td class="csFEAFFC4D" colspan="3" style="width:31px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:16px;"></td>
		<td class="cs8EA18762" colspan="10" style="width:87px;height:16px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Tiền&nbsp;kh&#225;ch&nbsp;trả:</nobr></td>
		<td class="csFEAFFC4D" colspan="13" style="width:113px;height:16px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>0</nobr></td>
		<td class="csFEAFFC4D" colspan="4" style="width:32px;height:16px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:17px;"></td>
		<td class="cs8EA18762" colspan="9" style="width:71px;height:17px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Tiền&nbsp;trả&nbsp;lại:</nobr></td>
		<td class="csFEAFFC4D" colspan="14" style="width:129px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>(${Format.formatMoneyNotD(data.dueBack)})</nobr></td>
		<td class="csFEAFFC4D" colspan="4" style="width:32px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:25px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:7px;"></td>
		<td class="csA8DCAC4A" colspan="27" style="width:242px;height:7px;text-align:left;vertical-align:top;"><div style="overflow:hidden;width:242px;height:7px;">
			<!--[if lt IE 7]><img alt="" src="_files/4133685977.gif" style="width:242px;height:7px;filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='_files/150284887.png',sizingMethod='scale');" /><div style="display:none"><![endif]--><img alt="" src="_files/150284887.png" style="width:242px;height:7px;" /><!--[if lt IE 7]></div><![endif]--></div>
		</td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:11px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:14px;"></td>
		<td class="cs5F7AA6EF" colspan="27" style="width:238px;height:14px;line-height:12px;text-align:center;vertical-align:top;"><nobr>(Gi&#225;&nbsp;đ&#227;&nbsp;bao&nbsp;gồm&nbsp;thuế&nbsp;GTGT)</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:17px;"></td>
		<td class="cs8F0C1850" colspan="27" style="width:238px;height:17px;line-height:12px;text-align:center;vertical-align:top;"><nobr>Cảm&nbsp;ơn&nbsp;qu&#253;&nbsp;kh&#225;ch&nbsp;v&#224;&nbsp;hẹn&nbsp;gặp&nbsp;lại&nbsp;!</nobr></td>
	</tr>
</table>
</body>
</html>
    `;
}
