/* eslint-disable import/no-duplicates */
/* eslint-disable filenames/match-regex */
import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import BgTableTwo from '../../../../assets/img/bg_table_2.png';
import BgTableFour from '../../../../assets/img/bg_table_4.png';
import BgTableSix from '../../../../assets/img/bg_table_6.png';
import BgTableEight from '../../../../assets/img/bg_table_8.png';
import { colorSet } from 'shared/styles/ColorSet';
import { MyTextInput } from 'components/Input';
import { NumberChair, TYPE_INPUT } from 'constants/AppEnums';
import { useUpdateNodeInternals } from 'reactflow';
import { drag } from 'd3-drag';
import { select } from 'd3-selection';

import 'reactflow/dist/style.css';
import useComponentVisible from 'hook/IsOutSizeHook';

function BlockTable(props) {

    const [bg, setBg] = React.useState<string>(BgTableTwo);

    const rotateControlRef = useRef(null);
    const updateNodeInternals = useUpdateNodeInternals();
    const [rotation, setRotation] = useState(props?.data.rotate || 0);
    const [toggle, setToggle] = useState(false);
    const { refFocus, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    useEffect(() => {
        setRotation(props?.data?.rotate);
        if (!rotateControlRef.current ) {
            return;
        }
        const selection = select(rotateControlRef.current);
        const dragHandler = drag().on('drag', (evt) => {
            const dx = evt.x - 100;
            const dy = evt.y - 100;
            const rad = Math.atan2(dx, dy);
            const deg = rad * (180 / Math.PI);
            setRotation(180 - deg);
            updateNodeInternals(props.id);
        });

        selection.call(dragHandler);
    }, [props, updateNodeInternals, isComponentVisible, toggle]);

    useEffect(() => {
        const number = props?.data?.numberChair;
        if(number >= NumberChair.EIGHT) { 
            return setBg(BgTableEight);
        } else if(number >= NumberChair.SIX) {
            return setBg(BgTableSix);
        } else if(number >= NumberChair.FOUR) {
            return setBg(BgTableFour);
        } else {
            return setBg(BgTableTwo);
        }
    }, [props]);

    const onClickTable = () => {
        setToggle(!toggle);
        props?.data?.onFocus(props);
        props?.data?.onChangeRotate(rotation, props.id);
        setIsComponentVisible(last => !last);
    };

    const renderOrderNo = (order_no) => {
        if (order_no) {
            const listOrder = order_no.split(',');
            if (listOrder[0].length >= 6 || listOrder.length >= 2) {
                return `${listOrder[0].slice(0,4)}...`;
            }
            return order_no;
        }
        return '';
    };
    
    return (
        <div 
            className="container-table-flow"
            style={{ backgroundImage: `url(${bg})`,  transform: `rotate(${90}deg)`}}
            ref={refFocus}
            onClick={onClickTable}
        >
            {/* {isComponentVisible && <div
                style={{
                    display: 'block'
                }}
                className={'rotate-handle'}
                ref={rotateControlRef}
            />} */}
            {isComponentVisible && <div
                className={'rotate-line'}
            />}
            <div 
                style={{
                    backgroundColor: props?.data?.orderNo === null ? colorSet.default.green : colorSet.default.yellow, 
                    transform: `rotate(${-90}deg)`,
                    flexDirection: 'column',
                    zIndex: 100
                }} 
                className='bg-circle'
            >
                {/* <MyTextInput 
                    type={TYPE_INPUT.TEXT} 
                    inputStyle='text-big bold color-white input-styles' 
                    containerStyle={'input-container'}
                    value={props?.data?.value || ''}
                    onChangeText={(e) => props.data?.onChange(e, props.id)}
                    disabled
                />  */}
                <span className='text-big-x bold color-white input-styles'> {props?.data?.value}</span>
                <span className='text-small color-white'>{renderOrderNo(props?.data?.orderNo)}</span>
            </div>

        </div>
    );
}

export default BlockTable;
