/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/react-in-jsx-scope */
import { Modal } from '@mui/material';
import DropDownComponent from 'components/DropDown';
import { TableDropDown } from 'components/DropDown/types';
import { MyTextInput } from 'components/Input';
import { TextFieldActions } from 'components/Input/types';
import { TYPE_INPUT } from 'constants/AppEnums';
import { ItemOderDetailModel } from 'models/order';
import { forwardRef,  useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Language } from 'shared/Language';
import Format from 'utils/Format';
import { PopupDiscountActions, PopupDiscountProps } from '../popup-discount/types';
import { SVGDown } from 'assets/svg';
import {  ParamsOrderDetail } from '../types';
import { dropsDown } from 'assets/static-data/payment';


const PopupEditDiscount = forwardRef<PopupDiscountActions, PopupDiscountProps>(({ iconHead, title, onPressConfirm, value}: PopupDiscountProps, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const refInput = useRef<TextFieldActions>(null);
    const [defaultDropDown, setDefaultDropDown] = useState<string>('Percent');
    const [data, setData] = useState<ItemOderDetailModel>();
    const [discount, setDiscount] = useState<number | string>(value || '');
    const _user = localStorage.getItem('Apec_Account');
    const user = _user && JSON.parse(_user);
    
    const showModal = (item: ItemOderDetailModel) => {
        setDiscount('');
        setOpen(true);
        setData(item);
        setDefaultDropDown(item?.isDiscountPer === 'Y' ? 'Percent' : 'VND');
        getValueDiscountItem(item);

    };

    const onChooseDropDown = (item: TableDropDown) => {
        setDefaultDropDown(item?.value);
        setDiscount(refInput.current.getValue());
    };

    const hideModal = () => {
        setOpen(false);
    };

    const onValidate = () => {
        const err =  Number(refInput.current?.getValue());
        if(err > data?.price * data?.qty) {
            refInput?.current?.setErrorMsg('Số tiền discount phải nhỏ hơn tổng tiền của món!');
            return false;
        }
        return true;
    };

    const onConfirm = () => {
        if(onValidate()) {
            const params = {
                valueDiscount: Number(refInput.current.getValue()),
                isPercent: defaultDropDown === 'Percent',
                detailId: data.detailId,
                username: user.username
            } as ParamsOrderDetail;
            onPressConfirm?.(params);
            hideModal();
        }
    };

    useImperativeHandle(ref, () => ({
        showModal,
        hideModal
    }));

    const getValueDiscountItem = (_data: ItemOderDetailModel) => {
        if(_data?.price === 0  || Number(_data?.discount) === 0) return '0';
        setDiscount(Number(_data?.discount));

    };


    const renderInput = useMemo(() => {
        return(
            <MyTextInput 
                type={TYPE_INPUT.NUMBER} 
                ref={refInput} 
                containerInput={'input-discount-payment border  mgr-10'}
                inputStyle={'input-style text-normal'}
                placeHolder={Language.payment.enterValue}
                labelStyles={'text-normal regular color-gray mgt-16 mgb-10'}
                value={discount.toString()}
                isPercent={defaultDropDown === 'Percent'}
                containerStyle={'container-my-input'}
            />
        );
    }, [discount, defaultDropDown]);

    return (
        <Modal open={open}>
            <div id="popup-modal">
                <div className="popup-container">
                    <div className="popup-head">
                        <div className="popup-head-icon">
                            {iconHead || <img src="assets/icon/Meal/index.svg"/>}
                        </div>
                        <div className="popup-head-detail">
                            <div className="detail-title">
                                {title || ''}
                            </div>
                            <div className="detail-des">
                                {Format.formatMoneyNotD(data?.price)} đ
                            </div>
                        </div>  
                    </div>
                    <div className='text-normal regular color-gray mgt-16 mgb-10'>{Language.payment.inputValueDiscount}</div>
                    <div className="flex-row jus-end">
                        {renderInput}
                        <DropDownComponent 
                            data={dropsDown}
                            label={Language.payment.choose}
                            styleLabel={'text-normal color-brown regular'}
                            stylesContainer={'drops-down-money'}
                            defaultValue={defaultDropDown}
                            onPress={onChooseDropDown}
                            stylesDrops={'width-100'}
                            iconRight={<SVGDown/>}
                            styleIconRight={'discount-icon-right'}
                        /> 
                    </div>
                    <div className="button-contain">
                        <div onClick={hideModal} className="button-left">{Language.common.cancel}</div>
                        <div className="button-right" onClick={onConfirm}>{Language.common.confirm}</div>
                    </div>
                </div>
            </div>
        </Modal>
    );
});
export default PopupEditDiscount;

