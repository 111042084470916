/* eslint-disable filenames/match-regex */
import { Language } from 'shared/Language';
/* eslint-disable filenames/match-regex */
import Service from 'api/apiService';
import * as enpoint from '../api/endpoints';
import * as itf from './type'; 
import { AccountModel, DataResponse } from 'interface/orderInterFace';
import { toast } from 'react-toastify';

const service = new Service();
export const fetchLogin = (data: any) => {
    const params = '/booking/api/pos/order/login'; 
    return service
        .post(params,data, null)
        .then((res: DataResponse<AccountModel[]>) => {
        
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const fetchMenu: (locationCode?: string) => Promise<any> = async (locationCode = '') => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.GET_MENU}?locationCode=${locationCode}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const fetchAreaCode: (locationCode?: string) => Promise<any> = async (locationCode = '') => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.GET_AREA_CODE}?locationCode=${locationCode}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const fetchDish: (locationCode?: string, menuId?: string, childId?: string) => Promise<any> = async (locationCode = '', menuId= '', childId= '') => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.GET_DISH}?locationCode=${locationCode}&menuId=${menuId}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};

export const fetchInfoHotel: () => Promise<any> = async () => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.INFO_HOTEL}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const fetchOrderByTable: (tableId?: string) => Promise<any> = async (tableId= '') => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.GET_ORDER_BY_TABLE}?tableId=${tableId}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const fetchTable: (areaCode?: string) => Promise<any> = async (areaCode= '') => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.GET_TABLE}?areaCode=${areaCode}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};

export const fetchOrderInfo: (orderId?: string) => Promise<any> = async (orderId= '') => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.GET_ORDER_INFO}?orderId=${orderId}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};


export const createNewOrder: (body: itf.ICreateNewOrder) => Promise<any> = async (body) => {
    try {
        const result: any = await service.post(enpoint.ORDER.CREATE_ORDER, body, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};

export const addItemIntoOrder: (body: itf.IAddItemIntoOrder) => Promise<any> = async (body) => {
    try {
        const result: any = await service.post(enpoint.ORDER.ADD_ITEM_INTO_ORDER, body, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const splitOrder: (body: itf.ISplitOrder) => Promise<any> = async (body) => {
    try {
        const result: any = await service.post(enpoint.ORDER.SPLIT_ORDER, body, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const updateOrder: (body: itf.IUpdateOrder) => Promise<any> = async (body) => {
    try {
        const result: any = await service.post(enpoint.ORDER.UPDATE_ORDER, body, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const removeItemInOrder: (body: itf.IRemoveItem) => Promise<any> = async (body) => {
    try {
        const result: any = await service.delete(enpoint.ORDER.REMOVE_ITEM, body);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const updateItem: (body: itf.IUpdateItem) => Promise<any> = async (body) => {
    try {
        const result: any = await service.post(enpoint.ORDER.UPDATE_ITEM, body, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const newUpdateItem: (body: itf.INewUpdateItem) => Promise<any> = async (body) => {
    try {
        const result: any = await service.post(enpoint.ORDER.NEW_UPDATE_ITEM, body, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const sendOrder: (orderId?: string) => Promise<any> = async (orderId= '') => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.SEND_ORDER}?orderId=${orderId}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};
export const sendOrderSuccess = (data: any) => {
    console.log('data', data);
    const url = enpoint.ORDER.SEND_ORDER_SUCCESS;
    return service
        .post(url, data, null)
        .then((res: any) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};
export const fetchLocation = () => {
    const params = '/booking/api/location/get-list'; 
    return service
        .get(params)
        .then((res: any) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};
export const fetchArea = (location: any) => {
    const params = `/booking/api/location/get-area?locationCode=${location}`; 
    return service
        .get(params)
        .then((res: any) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};
export const fetchMeal = (location: any) => {
    const params = `/booking/api/location/get-meal?locationCode=${location}`; 
    return service
        .get(params)
        .then((res: any) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};
export const fetchTableMenu = (area: any) => {
    const params = `/booking/api/table/get-list?areaCode=${area || ''}`; 
    return service
        .get(params)
        .then((res: any) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const fetchOrder = (tableId: any) => {
    const params = `/booking/api/table/get-order-by-table?tableId=${tableId}`; 
    return service
        .get(params)
        .then((res: any) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};
export const fetchMoveOrderToTable = (data: any) => {
    const params = '/booking/api/pos/order/move-order';
    return service
        .post(params, data, null)
        .then((res: any) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const fetchListOrderPaid: (table?: string, startDate?: string, endDate?: string, location?: string) => Promise<any> = async (table, startDate, endDate, location) => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.GET_ORDER_PAID}table=${table||''}&orderDate=${startDate || ''}&orderNo=${endDate || ''}&location=${location || 'FR'}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
        toast.error(Language.err.notFound, {
            position: 'top-right',
            theme: 'colored'
        });
    }
};

export const fetchListOrderUnPaid: (table?: string, startDate?: string, endDate?: string, location?: string) => Promise<any> = async (table, startDate, endDate, location) => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.GET_ORDER_UNPAID}table=${table||''}&orderDate=${startDate || ''}&orderNo=${endDate || ''}&orderLocation=${location}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
        toast.error(Language.err.notFound, {
            position: 'top-right',
            theme: 'colored'
        });
    }
};

export const fetchOrderDetail: (id) => Promise<any> = async (id) => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.GET_ORDER_DETAIL}${id}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
        toast.error(Language.err.notFound, {
            position: 'top-right',
            theme: 'colored'
        });
    }
};

export const fetchVoucher: (id, orderId) => Promise<any> = async (id, orderId) => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.GET_VOUCHER}${id}&orderId=${orderId}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};


export const fetchListSource: (id) => Promise<any> = async (id) => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.LIST_SOURCE}${id}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};

export const fetchListRoom: (id) => Promise<any> = async (id) => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.LIST_ROOM}${id}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};

export const fetchRoomInfo: (id) => Promise<any> = async id => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.ROOM_INFO}${id}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};

export const fetchCityInfo: (id) => Promise<any> = async (id) => {
    try {
        const result: any = await service.get(`${enpoint.ORDER.CITY_INFO}${id}`);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};

export const fetchUpdateDiscount: (params) => Promise<any> = async (params) => {

    try {
        const result: any = await service.post(`${enpoint.ORDER.UPDATE_DISCOUNT_ITEM}`, params, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
        toast.error(Language.toast.payment.errUpdate, {
            position: 'top-right',
            theme: 'colored'
        });
    }
};



export const fetchUpdateDiscountOrder: (params) => Promise<any> = async (params) => {
    try {
        const result: any = await service.post(`${enpoint.ORDER.UPDATE_DISCOUNT_ORDER}`, params, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};

export const fetchUpdateUpdateVATAndSVC: (params) => Promise<any> = async (params) => {
    try {
        const result: any = await service.post(`${enpoint.ORDER.UPDATE_SVC_VAT}`, params, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};

export const fetchMergeOrder: (params) => Promise<any> = async (params) => {
    try {
        const result: any = await service.post(`${enpoint.ORDER.MERGE_ORDER}`, params, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
    }
};

export const fetchUpdateTable: (params) => Promise<any> = async (params) => {
    try {
        const result: any = await service.post(`${enpoint.ORDER.UPDATE_TABLE}`, params, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
        toast.error(Language.err.updateErr, {
            position: 'top-right',
            theme: 'colored'
        });    }
};

export const fetchPayment: (params) => Promise<any> = async (params) => {
    try {
        const result: any = await service.post(`${enpoint.ORDER.PAYMENT_ORDER}`, params, null);
        const { metadata, statusCode, message } = result;
        return { metadata, statusCode, message };
    } catch (error) {
        console.log(error);
        toast.error(Language.err.updateErr, {
            position: 'top-right',
            theme: 'colored'
        });    }
};



