import '../bookedList/styles.scss';
// eslint-disable-next-line filenames/match-regex
import React from 'react';
import OrderColumn from '../bookedList/index';
import { SVGInfo } from 'assets/svg';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
export const Content = () => {
    // ===========================================================================
    // Selectors
    // ===========================================================================
    const {bookedSplitList} =  useSelector((state: AppState) => state.bookedSplitList);
    const {bookedList} =  useSelector((state: AppState) => state.bookedList);
    
    return (
        <React.Fragment>
            <div id="page-split-order">
                <div className="order-part">
                    <OrderColumn  listOrderBooked={bookedList} booked ={true} text={'.01'}/>
                </div>
                <div className="order-part" >
                    <OrderColumn listOrderBooked={bookedSplitList} booked ={false} text={'.02'} />
                </div>
            </div>
            <div className='order-note'>
                <SVGInfo/>
                <p>Select and adjust the number of items you want to split in to a new bill!</p>
            </div>
        </React.Fragment>
    );
};

export default Content;
