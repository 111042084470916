/* eslint-disable react/react-in-jsx-scope */
import clsx from 'clsx';
import Cell from './Cell';
import Header from './Header';
import './styles.scss';
import { CheckBoxModel, TableProps } from './type';
import Checkbox from '@mui/material/Checkbox';
import {  useState } from 'react';
import { OrderDetailModel } from 'models/order';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function TableComponent({
    columns, 
    data, 
    onPress, 
    styles,
    isCheckBox,
    keyTable,
    styleRow,
    flex,
    labelIcon, 
    nameIcon,
    onPressColumn
} : TableProps) {

    const [arrCheckBox, setArrCheckBox] = useState<CheckBoxModel[]>([]);
    const [active, setActive] = useState<number>();

    const onCheckBoxAll = () => {
        if(arrCheckBox.length < data.length) {
            const arrChecked = [] as CheckBoxModel[];
            data.map(item => arrChecked.push({id: item?.[keyTable[0]]}));
            setArrCheckBox(arrChecked);
        } else {
            setArrCheckBox([]);
        }
    };

    const onCheckBox = (item: CheckBoxModel) => {
        if(arrCheckBox.filter(e => e.id === item.id).length === 0) {
            setArrCheckBox([...arrCheckBox, item]);
        } else {
            const arr = arrCheckBox.filter((object => object.id !== item.id));
            setArrCheckBox(arr);
        }
    };
 
    const onPressActiveColumn = (item: OrderDetailModel, index: number) => {
        setActive(index);
        onPressColumn?.(item);
    };

    return (
        <div className={clsx(`${styles} table`)}>
            <div style={{flex: 1, display: 'flex'}} className='header'>
                {isCheckBox && <div className= 'th' style={{flex: 1}}>
                    <Checkbox 
                        {...label} 
                        indeterminate={arrCheckBox.length > 0 && arrCheckBox.length !== data.length} 
                        onChange={onCheckBoxAll} 
                        checked={data?.length === arrCheckBox.length}
                    />
                </div>}
                {columns?.map((headerGroup, index) => ( 
                    <Header column={headerGroup} index={index} key={index} isCheckBox={isCheckBox} flexHeader={flex}/>
                ))}
            </div>
            <div className={'flex-col'}>
                {data?.map((row, i) => (
                    <Cell 
                        value={row} 
                        index={i} 
                        onPressCheckBox={onCheckBox} 
                        key={i} 
                        isCheckBox={isCheckBox}
                        onPress={() => onPress(row)}
                        defaultCheckBox={arrCheckBox.filter(object => object.id === row?.[keyTable[0]]).length > 0}
                        icon={nameIcon}
                        keyCell={keyTable}
                        styleItem={styleRow}
                        flexCell={flex}
                        labelIcon={labelIcon}
                        active={active === i}
                        onPressColumn={() => onPressActiveColumn(row, i)}
                    />
                )
                )}
            </div>
        </div>
    );
}
