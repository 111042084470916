// import * as React from 'react';
// import './styles.scss';
// import DateUtils from 'utils/DateUtils';


// export default function DatePickerDay({day, onPress}: {day?: string, onPress: any}) {

//     const [value, setValue] = React.useState<string>('');

//     const onChangeDate = (e) => {
//         console.log(e.target.value);
//         setValue((e.target.value));
//         // onPress?.(DateUtils.formatMMDDYYYYPicker(e.$d));
//     };

//     return (
//         <div className='date-picker-container'>
//             <input type="date" name="date" id="date"
//                 max={DateUtils.formatYYYYMMDDPicker(new Date())}
//                 onChange={onChangeDate}
//                 className='input-date border'
//             />
//         </div>
      
//     );
// }
import { SVGCalendar, SVGDown, SVGIconClose } from 'assets/svg';
import React, {useState} from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateUtils from 'utils/DateUtils';
import './styles.scss';
export default function DatePickerDay({day, onPress}: {day?: any, onPress: any}) {
    const [dateState, setDateState] = useState(day);
    const [open, setOpen] = useState<boolean>(false);

    const onShowCalendar = () => {
        setOpen(last => !last);
    };

    const changeDate = (e) => {
        onPress?.(DateUtils.formatMMDDYYYYPicker(e));
        setDateState(e);
        setOpen(false);
    };

    const onClose = () => {
        onPress?.('');
        setDateState('');
        setOpen(false);
    };

    return (
        <div className='calendar'>
            <div className='input-date border' >
                <div className='view-left' onClick={onShowCalendar} style={{flex: 1}}>
                    <SVGCalendar/>
                    <span className='mgl-10 text-normal regular color-gray-1'>
                        {DateUtils.formatMMDDYYYYPicker(dateState) || 'Date' }
                    </span>
                </div>
                {dateState ?  <div onClick={onClose}> <SVGIconClose /> </div>:  <SVGDown/>}
            </div>
            {open && <Calendar 
                value={dateState}
                onChange={changeDate}
                className='date'
                maxDate={new Date()}
            />}
        </div>
    );
}

