import React from 'react';
import './loading.scss';

const LoadingTable = () => {
    return (
        <div className="app-loading-table">
            <div className="loader-spin">
                <span className="crema-dot crema-dot-spin">
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                </span>
            </div>
        </div>
    );
};

export default LoadingTable;
