import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { FormLabel } from '@mui/material';
import './styles.scss';
import PoupSplitBillInto from '../popup-spit-bill-into/index';
import PopupSplitBillAmount from '../popup-split-bill-amount/index';
import PopupSplitManual from '../popup-split-bill-manual/index';
import { useDispatch } from 'react-redux';
import OrderAction from '../../../redux/actions/order/orderAction';
import globalAction from 'redux/actions/global/globalAction';

const index = (props) => {
    const {showModal,setShow} = props;
    const [showSplitInto,setShowSplitInto] = useState(false);
    const [showSplitAmount,setShowSplitAmount] = useState(false);
    const [showSplitManual,setShowSplitManual] = useState(false);
    const [radioValue,setRadioValue] = useState('into');
    const onHidden = () =>{ 
        setShow(!showModal); 
    };
    // ===========================================================================
    // Dispatch
    // ===========================================================================
    const dispatch = useDispatch();
    const _clearBookedSplitList = () => dispatch<any>(OrderAction.clearBookedSplitList());
    const _clearItemSplitedList = () => dispatch<any>(globalAction.clearItemSplitedList());

    // ===========================================================================
    // Hooks
    // ===========================================================================
    useEffect(
        () =>{
            _clearBookedSplitList();
            _clearItemSplitedList();
        },[showModal]
    );
    const ConfirmHandler = () =>{
        setShow(!showModal);
        switch(radioValue){
            case 'into':
                setShowSplitInto(!showSplitInto);
                break;
            case 'amount':
                setShowSplitAmount(!showSplitAmount);
                break;
            case 'manual':
                setShowSplitManual(!showSplitManual);
                break;
            default:
                setShowSplitInto(!showSplitInto);
        }
        
    };
    const handleChangeRadioGroup = (event: React.ChangeEvent<HTMLInputElement>) =>{
        setRadioValue((event.target as HTMLInputElement).value);
    };
    return (
        <React.Fragment>
            <Modal show={showModal} onHide={onHidden} centered={true}>
                <Modal.Body>
                    <FormControl>
                        <FormLabel id="split-bill-radio-buttons-group-label" style={{color:'#202223', fontWeight: 600, fontSize:20}}>Split Bill</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="into"
                            value={radioValue}
                            name="radio-buttons-group"
                            onChange={handleChangeRadioGroup}
                        >
                            <FormControlLabel value="into" control={<Radio />} label="Split bill into" />
                            <FormControlLabel value="amount" control={<Radio />} label="Split bill with amount" />
                            <FormControlLabel value="manual" control={<Radio />} label="Manual" />
                        </RadioGroup>
                    </FormControl>
                    <div style={{marginTop:12, display:'flex', justifyContent:'center', gap:16}}>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                onHidden();
                            }}
                            style={{ width: 130, background: 'none', color: 'black' }}
                        >
                        Huỷ
                        </Button>
                        <Button
                            onClick={() => ConfirmHandler()}
                            variant="primary"
                            style={{ width: 130, background: '#A6815B' }}
                        >
                        Xác nhận <ArrowForwardIcon fontSize="small" />
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <PoupSplitBillInto
                showModal ={showSplitInto}
                setShow={setShowSplitInto}
                setShowSplit ={setShow}
                showSplit={showModal}
            />
            <PopupSplitBillAmount
                showModal ={showSplitAmount}
                setShow={setShowSplitAmount}
                setShowSplit ={setShow}
                showSplit={showModal}
            />
            <PopupSplitManual
                showModal ={showSplitManual}
                setShow={setShowSplitManual}
                setShowSplit ={setShow}
                showSplit={showModal}
            />
        </React.Fragment>
    );
};

export default index;
