/* eslint-disable filenames/match-regex */

import React, { useRef} from 'react';
import { useSelector } from 'react-redux';
import ReactFlow, { Background} from 'reactflow';
import { AppState } from 'redux/store';

import ConnectionLine from './ConnectionLine';

 enum BackgroundVariant {
    Lines = 'lines',
    Dots = 'dots',
    Cross = 'cross'
}

// const {width, height} = D;

const ConnectionLineFlow = React.forwardRef((props: any) => {
    const {nodes, onChangePosition, onNodesChange, setReactFlowInstance, edges, onEdgesChange, onConnect, onDrop, onDragOver, nodeTypes, edgeTypes } = props;
  
    const refFlow = useRef(null);
    const {maxX, maxY, minX, minY, isEdit} = useSelector((state: AppState) => state.common);
    return (
        <ReactFlow
            ref={refFlow}
            nodes={nodes}
            edges={edges} 
            onNodesChange={(e) => {
                if(isEdit ) {
                    onNodesChange(e);
                    onChangePosition(e);
                }
            }}
            onEdgesChange={onEdgesChange}
            connectionLineStyle={ConnectionLine as any}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            nodeTypes={nodeTypes}
            onDragOver={onDragOver} 
            maxZoom={1}
            minZoom={0.2}
            edgeTypes={edgeTypes}
            defaultViewport={{ zoom: 0.7, x: (Math.abs(maxX) - Math.abs(minX))/2, y: (Math.abs(maxY) - Math.abs(minY))/2}}
            nodeExtent={[[minX ,minY], [maxX , maxY]]}
            translateExtent={[[minX + 200 , minY], [maxX , maxY]]}
        >
            <Background variant={BackgroundVariant.Dots} style={{backgroundColor: '#F0F0F0'}}/>
        </ReactFlow>
    );
});

export default ConnectionLineFlow;
