/* eslint-disable filenames/match-regex */

import { InfoHotelModel, OrderModel } from 'models/order';
import DateUtils from 'utils/DateUtils';
import Format from 'utils/Format';
export function renderHTMLBillBG (data: OrderModel, infoHotel: InfoHotelModel) {
    const newDate = DateUtils.formatDayAndTimePicker(new Date()).split('/')[1];
    const _user = localStorage.getItem('Apec_Account');
    const user = _user && JSON.parse(_user).username;
    const columnOder = () => {
        let _column = '';
        for(let i=0; i < data.items.length; i++) {
            _column += `<tr style="vertical-align:top;">
            <td style="width:0px;height:29px;"></td>
            <td class="csDA17CD4C" colspan="3" style="width:12px;height:23px;line-height:10px;text-align:center;vertical-align:middle;"><nobr>${i + 1}</nobr></td>
            <td class="cs693942EE" colspan="7" style="width:68px;height:23px;line-height:10px;text-align:left;vertical-align:middle;"><nobr>${data?.items?.[i]?.itemName}</nobr></td>
            <td class="csDA17CD4C" colspan="2" style="width:20px;height:23px;line-height:10px;text-align:center;vertical-align:middle;"><nobr>${data?.items?.[i].unitName}</nobr></td>
            <td class="csDA17CD4C" colspan="6" style="width:22px;height:23px;line-height:10px;text-align:center;vertical-align:middle;"><nobr>${data?.items?.[i].qty}</nobr></td>
            <td class="csDA17CD4C" colspan="4" style="width:41px;height:23px;line-height:10px;text-align:right;vertical-align:middle;"><nobr>${data?.items?.[i].price}</nobr></td>
            <td class="csDA17CD4C" colspan="7" style="width:46px;height:23px;line-height:10px;text-align:right;vertical-align:middle;"><nobr>${data?.items?.[i].total}</nobr></td>
        </tr>`;
        } 
        return _column;
    };

    const table = `<tr style="vertical-align:top;">
    <td style="width:0px;height:24px;"></td>
    <td class="cs4FADFF8F" colspan="3" style="width:16px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>No</nobr></td>
    <td class="cs4FADFF8F" colspan="7" style="width:69px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>Item</nobr></td>
    <td class="cs4FADFF8F" colspan="2" style="width:24px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>Unit</nobr></td>
    <td class="cs4FADFF8F" colspan="6" style="width:26px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>Qty</nobr></td>
    <td class="cs4FADFF8F" colspan="4" style="width:45px;height:24px;line-height:9px;text-align:right;vertical-align:middle;"><nobr>Price</nobr></td>
    <td class="cs4B1904F1" colspan="7" style="width:51px;height:24px;line-height:9px;text-align:right;vertical-align:middle;"><nobr>Amount</nobr></td>
</tr>${columnOder()}`;


    return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <!-- saved from url=(0016)http://localhost -->
    <html>
    <head>
        <title>Document</title>
        <meta HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"/>
        <style type="text/css">
            .cs4FADFF8F {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right:#FFFFFF 2px solid;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:normal; }
            .cs4B1904F1 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right:transparent 1px solid;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:normal; }
            .csC76BB281 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom:#000000 1px dashed;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-top:3px;padding-left:3px;padding-bottom:3px;}
            .csD5125DAB {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom:#000000 1px dashed;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-top:3px;padding-left:3px;padding-right:3px;padding-bottom:3px;}
            .cs8EA18762 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:bold; font-style:normal; padding-left:2px;}
            .csFEAFFC4D {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:bold; font-style:normal; padding-left:2px;padding-right:2px;}
            .csD589D4E {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:normal; padding-left:2px;}
            .cs5F7AA6EF {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:normal; padding-left:2px;padding-right:2px;}
            .csE6322FFC {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:12px; font-weight:bold; font-style:normal; padding-left:2px;padding-right:2px;}
            .cs6FC5B17 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:13px; font-weight:bold; font-style:normal; padding-left:2px;padding-right:2px;}
            .csA8DCAC4A {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:13px; font-weight:normal; font-style:normal; }
            .cs636938EA {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:italic; padding-left:2px;}
            .csA676D0BF {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:normal; }
            .csB8BEB134 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:normal; padding-left:1px;}
            .csADEC2D6F {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:bold; font-style:normal; padding-left:2px;}
            .cs5E2CCECE {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:italic; padding-left:2px;}
            .cs9FBDB683 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:italic; padding-left:2px;padding-right:2px;}
            .csABE5B009 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:italic; padding-left:3px;}
            .csB839DCA6 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-left:3px;}
            .cs27313B43 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-left:4px;}
            .cs9691FC92 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-left:7px;}
            .cs693942EE {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-top:3px;padding-left:3px;padding-bottom:3px;}
            .csDA17CD4C {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:9px; font-weight:normal; font-style:normal; padding-top:3px;padding-left:3px;padding-bottom:3px;}
            .csAC6A4475 {height:0px;width:0px;overflow:hidden;font-size:0px;line-height:0px;}
        </style>
    </head>
    
<body leftMargin=10 topMargin=10 rightMargin=10 bottomMargin=10  style="background-color: #FFFFFF; width: 330px; margin-top: 20px">
<table cellpadding="0" cellspacing="0" border="0" style="border-width:0px;empty-cells:show;width:242px;height:481px;position:relative;">
            <tr>
                <td style="width:0px;height:0px;"></td>
                <td style="height:0px;width:1px;"></td>
                <td style="height:0px;width:8px;"></td>
                <td style="height:0px;width:9px;"></td>
                <td style="height:0px;width:6px;"></td>
                <td style="height:0px;width:7px;"></td>
                <td style="height:0px;width:6px;"></td>
                <td style="height:0px;width:8px;"></td>
                <td style="height:0px;width:5px;"></td>
                <td style="height:0px;width:29px;"></td>
                <td style="height:0px;width:10px;"></td>
                <td style="height:0px;width:1px;"></td>
                <td style="height:0px;width:25px;"></td>
                <td style="height:0px;width:5px;"></td>
                <td style="height:0px;width:5px;"></td>
                <td style="height:0px;width:4px;"></td>
                <td style="height:0px;width:7px;"></td>
                <td style="height:0px;width:4px;"></td>
                <td style="height:0px;width:3px;"></td>
                <td style="height:0px;width:17px;"></td>
                <td style="height:0px;width:1px;"></td>
                <td style="height:0px;width:1px;"></td>
                <td style="height:0px;width:28px;"></td>
                <td style="height:0px;width:16px;"></td>
                <td style="height:0px;width:1px;"></td>
                <td style="height:0px;width:1px;"></td>
                <td style="height:0px;width:27px;"></td>
                <td style="height:0px;width:2px;"></td>
                <td style="height:0px;width:1px;"></td>
                <td style="height:0px;width:4px;"></td>
            </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:12px;"></td>
            <td></td>
            <td></td>
            <td class="csA676D0BF" colspan="26" style="width:229px;height:12px;line-height:10px;text-align:left;vertical-align:top;"><nobr>${infoHotel.hotelName}</nobr></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:13px;"></td>
            <td></td>
            <td></td>
            <td class="csA676D0BF" colspan="5" style="width:36px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>Website:</nobr></td>
            <td class="cs636938EA" colspan="20" style="width:190px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>${infoHotel.website}</nobr></td>
            <td></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:13px;"></td>
            <td></td>
            <td></td>
            <td class="csA676D0BF" colspan="3" style="width:22px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>Add:</nobr></td>
            <td class="csB8BEB134" colspan="23" rowspan="2" style="width:206px;height:23px;line-height:10px;text-align:left;vertical-align:top;"><nobr>${infoHotel.address}</nobr></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:10px;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:13px;"></td>
            <td></td>
            <td></td>
            <td class="csA676D0BF" colspan="6" style="width:41px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>Tax&nbsp;code:</nobr></td>
            <td class="csADEC2D6F" colspan="4" rowspan="2" style="width:63px;height:24px;line-height:10px;text-align:left;vertical-align:top;"><nobr>${infoHotel.taxCode}</nobr></td>
            <td></td>
            <td></td>
            <td class="csA676D0BF" colspan="6" style="width:36px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>Hotline:</nobr></td>
            <td class="csADEC2D6F" colspan="9" style="width:76px;height:13px;line-height:10px;text-align:left;vertical-align:top;"><nobr>${infoHotel.fax}</nobr></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:11px;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:11px;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:2px;"></td>
            <td></td>
            <td class="csA8DCAC4A" colspan="28" style="width:241px;height:2px;text-align:left;vertical-align:top;"><div style="overflow:hidden;width:241px;height:2px;">
                <!--[if lt IE 7]><img alt="" src="_files/4133685977.gif" style="width:241px;height:2px;filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='_files/3610321722.png',sizingMethod='scale');" /><div style="display:none"><![endif]--><img alt="" src="_files/3610321722.png" style="width:241px;height:2px;" /><!--[if lt IE 7]></div><![endif]--></div>
            </td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:22px;"></td>
            <td class="cs6FC5B17" colspan="29" style="width:238px;height:22px;line-height:15px;text-align:center;vertical-align:middle;"><nobr>Receipt</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:10px;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:13px;"></td>
            <td class="csADEC2D6F" colspan="4" style="width:22px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>Date:</nobr></td>
            <td class="cs27313B43" colspan="11" style="width:101px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>${data.createdDate}</nobr></td>
            <td class="csADEC2D6F" colspan="4" style="width:29px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>Outlet:</nobr></td>
            <td class="csB839DCA6" colspan="10" style="width:79px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>${data.location}</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:14px;"></td>
            <td class="csADEC2D6F" colspan="5" style="width:29px;height:14px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>Order:</nobr></td>
            <td class="cs9691FC92" colspan="10" style="width:91px;height:14px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>${data.orderNo}</nobr></td>
            <td class="csADEC2D6F" colspan="4" style="width:29px;height:14px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>Guest:</nobr></td>
            <td class="csB839DCA6" colspan="10" style="width:79px;height:14px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>${data.noOfGuest}</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:1px;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
       ${table}
        <tr style="vertical-align:top;">
            <td style="width:0px;height:2px;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:17px;"></td>
            <td class="cs8EA18762" colspan="10" style="width:87px;height:17px;line-height:12px;text-align:left;vertical-align:middle; border-top:#000000 1px dashed"><nobr>Sub&nbsp;Total</nobr></td>
            <td></td>
            <td class="csFEAFFC4D" colspan="12" style="width:112px;height:17px;line-height:12px;text-align:right;vertical-align:middle; border-top:#000000 1px dashed"><nobr>${Format.formatMoneyNotD(data.amountNet)}</nobr></td>
            <td class="csFEAFFC4D" colspan="6" style="width:32px;height:17px;line-height:12px;text-align:right;vertical-align:middle; border-top:#000000 1px dashed"><nobr>VND</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:17px;"></td>
            <td class="cs8EA18762" colspan="9" style="width:77px;height:17px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Discount&nbsp;Amt</nobr></td>
            <td class="cs8EA18762" colspan="6" style="width:48px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
            <td class="csFEAFFC4D" colspan="8" style="width:73px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data.totalDiscount)}</nobr></td>
            <td class="csFEAFFC4D" colspan="6" style="width:32px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:16px;"></td>
            <td class="cs8EA18762" colspan="10" style="width:87px;height:16px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Service&nbsp;charge</nobr></td>
            <td></td>
            <td class="csFEAFFC4D" colspan="13" style="width:113px;height:16px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data.svc)}</nobr></td>
            <td class="csFEAFFC4D" colspan="5" style="width:31px;height:16px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:17px;"></td>
            <td class="cs8EA18762" colspan="10" style="width:87px;height:17px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>VAT</nobr></td>
            <td></td>
            <td class="csFEAFFC4D" colspan="13" style="width:113px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data.vat)}</nobr></td>
            <td class="csFEAFFC4D" colspan="5" style="width:31px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:17px;"></td>
            <td></td>
            <td class="cs8EA18762" colspan="10" style="width:87px;height:16px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Total</nobr></td>
            <td class="csFEAFFC4D" colspan="14" style="width:114px;height:16px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data.total)}</nobr></td>
            <td class="csFEAFFC4D" colspan="4" style="width:30px;height:16px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:8px;"></td>
            <td class="csA8DCAC4A" colspan="29" style="width:242px;height:8px;text-align:left;vertical-align:top;"><div style="overflow:hidden;width:242px;height:8px;">
                <!--[if lt IE 7]><img alt="" src="_files/4133685977.gif" style="width:242px;height:8px;filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='_files/150284887.png',sizingMethod='scale');" /><div style="display:none"><![endif]--><img alt="" src="_files/150284887.png" style="width:242px;height:8px;" /><!--[if lt IE 7]></div><![endif]--></div>
            </td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:16px;"></td>
            <td></td>
            <td class="csD589D4E" colspan="10" style="width:87px;height:17px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Amount&nbsp;paid</nobr></td>
            <td class="cs5F7AA6EF" colspan="13" style="width:113px;height:17px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data.amountPaid)}</nobr></td>
            <td></td>
            <td class="cs5F7AA6EF" colspan="4" style="width:30px;height:16px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>VND</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:15px;"></td>
            <td></td>
            <td class="csD589D4E" colspan="9" rowspan="3" style="width:86px;height:16px;line-height:12px;text-align:left;vertical-align:middle; border-bottom:#000000 1px dashed"><nobr>Due&nbsp;back</nobr></td>
            <td class="cs5F7AA6EF" colspan="14" rowspan="3" style="width:114px;height:16px;line-height:12px;text-align:right;vertical-align:middle;border-bottom:#000000 1px dashed"><nobr>(${Format.formatMoneyNotD(data.dueBack)})</nobr></td>
            <td class="cs5F7AA6EF" colspan="5" rowspan="3" style="width:31px;height:16px;line-height:12px;text-align:right;vertical-align:middle;border-bottom:#000000 1px dashed"><nobr>VND</nobr></td>
        </tr>
        
        <tr style="vertical-align:top;">
            <td style="width:0px;height:1px;"></td>
            <td></td>
            <td class="csD589D4E" colspan="19" rowspan="4" style="width:151px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:1px;"></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
        <td style="width:0px;height:15px;"></td>
        <td></td>
        <td class="cs8EA18762" colspan="9" rowspan="3" style="width:86px;height:16px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>Payment Method</nobr></td>
        <td class="cs5F7AA6EF" colspan="19" rowspan="3" style="width:31px;height:16px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${data?.payment}</nobr></td>
    </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:13px;"></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:2px;"></td>
            <td></td>
            <td class="csD589D4E" colspan="19" rowspan="3" style="width:151px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:1px;"></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:14px;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:6px;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:16px;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="csE6322FFC" colspan="10" style="width:95px;height:16px;line-height:13px;text-align:center;vertical-align:top;"><nobr>Guest&nbsp;signature</nobr></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:46px;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:8px;"></td>
            <td class="cs9FBDB683" colspan="29" rowspan="2" style="width:238px;height:14px;line-height:10px;text-align:center;vertical-align:top;"><nobr>Thank&nbsp;you&nbsp;and&nbsp;See&nbsp;you&nbsp;again!</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:6px;"></td>
            <td class="csA8DCAC4A" colspan="29" rowspan="3" style="width:242px;height:10px;text-align:left;vertical-align:top;"><div style="overflow:hidden;width:242px;height:10px;">
                <!--[if lt IE 7]><img alt="" src="_files/4133685977.gif" style="width:242px;height:10px;filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='_files/2241472986.png',sizingMethod='scale');" /><div style="display:none"><![endif]--><img alt="" src="_files/2241472986.png" style="width:242px;height:10px;" /><!--[if lt IE 7]></div><![endif]--></div>
            </td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:1px;"></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:3px;"></td>
            <td class="cs5E2CCECE" colspan="7" rowspan="2" style="width:43px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>Print&nbsp;date:</nobr></td>
            <td class="csB839DCA6" colspan="7" rowspan="2" style="width:80px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>${newDate}</nobr></td>
            <td class="cs5E2CCECE" colspan="9" rowspan="2" style="width:45px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>-&nbsp;Staff:</nobr></td>
            <td class="csABE5B009" colspan="12" rowspan="2" style="width:99px;height:13px;line-height:9px;text-align:left;vertical-align:middle;"><nobr>${user}</nobr></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:10px;"></td>
        </tr>
    </table>
    </body>
    </html>
    `;
}
