/* eslint-disable filenames/match-regex */

import { CLEAR_SPLITED_ITEM_VALUE, CURRENT_NUM_CHILDREN, CURRENT_NUM_ELDER, CURRENT_TABLE_ID, CURRENT_TABLE_NAME, REMOVE_SPLITED_ITEM_VALUE, SET_ITEM_MENU, SET_LOADING, SET_SPLITED_ITEM_VALUE } from 'redux/constants/globalConstants';


const changeCurrentTables = (payload) => ({
    type: CURRENT_TABLE_ID,
    payload
});
const changeCurrentTablesName = (payload) => ({
    type: CURRENT_TABLE_NAME,
    payload
});
const addSplitedItem = (payload) => ({
    type: SET_SPLITED_ITEM_VALUE,
    payload
});

const updateNumberElder = (payload) => ({
    type: CURRENT_NUM_ELDER,
    payload
});
const updateNumberChildren = (payload) => ({
    type: CURRENT_NUM_CHILDREN,
    payload
});

const clearItemSplitedList = () => (dispatch) =>{
    dispatch({type: CLEAR_SPLITED_ITEM_VALUE});
};
const removeItemSplitedList =(payload) =>(dispatch:any,getState) => {
    const {
        global: { splitedItem }
    } = getState();
    dispatch({
        type: REMOVE_SPLITED_ITEM_VALUE,
        payload:splitedItem.filter(item => item !== payload)});
};
const setLoading = (payload) => ({
    type: SET_LOADING,
    payload
});
const setItemMenu = (payload) => ({
    type: SET_ITEM_MENU,
    payload
});
const exportDefault = {
    changeCurrentTables,
    updateNumberElder,
    updateNumberChildren,
    addSplitedItem,
    clearItemSplitedList,
    removeItemSplitedList,
    changeCurrentTablesName,
    setLoading,
    setItemMenu
};
export default exportDefault;
