/* eslint-disable react/react-in-jsx-scope */
import Modal from '@mui/material/Modal';
import { PopupPrintfAction, PopupPrintfProps } from 'components/popup-print/types';
import { TypePrint } from 'constants/AppEnums';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Language } from 'shared/Language';
import Box from '@mui/material/Box';
import parse, {
    DOMNode,
    Element,
    HTMLReactParserOptions
} from 'html-react-parser';
import './styles.scss';
import { renderHTMLBillBG } from './BillBG';
import { renderHTMLBill } from './Bill';
import { renderHTMLBillInvoice } from './BillInvoice';
import { InfoHotelModel, OrderModel } from 'models/order';
import { fetchArea, fetchInfoHotel } from 'service/orderService';

const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
        if (
            domNode instanceof Element &&
        domNode.attribs &&
        domNode.attribs.class === 'remove'
        ) {
            return <></>;
        }
    }
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};

const PopupPrintOrder = forwardRef<PopupPrintfAction, PopupPrintfProps>(({title}: PopupPrintfProps, ref) => {

    const [visible, setVisible] = useState<boolean>(false);
    const [type, setType] = useState<number>(0);
    const [data, setData] = useState<OrderModel>();
    const [infoHotel, setInfoHotel] = useState<InfoHotelModel>();
    const componentRef = useRef();

    const showModal = (value, item) => {
        setVisible(true);
        setType(value);
        setData(item);
        getInfoHotel();
    };

    const hideModal = () => {
        setVisible(false);
    };

    const onConfirm = () => {
        handlePrint();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const getInfoHotel = useCallback(async() => {
        const res = await fetchInfoHotel();
        if(res.message === 'Success') {
            const _data = res.metadata[0] as InfoHotelModel;
            setInfoHotel(_data);
        }
    }, []);

    const renderHTML = () => {
        switch(type) {
            case TypePrint.INVOICE:
                return renderHTMLBillInvoice(data, infoHotel);
            case TypePrint.BILL:
                return renderHTMLBill(data, infoHotel);
            case TypePrint.BILL_MN:
                return renderHTMLBillBG(data, infoHotel);
            default: 
                return '';
        }
    };
   
    useImperativeHandle(ref, () => ({
        showModal,
        hideModal
    }));

    return (
        <Modal open={visible}   
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
                          
            <Box sx={style}>
                <div className='popup-print-order'>
                    <div className="popup-print-container">
                        {infoHotel && data && <div className="popup-print-top"  ref={componentRef}>
                            {parse(renderHTML(), options)}
                        </div>}
                   
                    </div>
                    <div className="button-contain">
                        <div onClick={hideModal} className="button-left">{Language.common.cancel}</div>
                        <div className="button-right" onClick={onConfirm} >{Language.common.print}</div>
                    </div>
                </div>
            </Box>
          
        </Modal>
    );
});
export default PopupPrintOrder;
