/* eslint-disable react/jsx-key */
// eslint-disable-next-line filenames/match-regex
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { fetchMoveOrderToTable } from 'service/orderService';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import '../../styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import OrderAction from '../../../redux/actions/order/orderAction';
import { AppState } from 'redux/store';
import GlobalAction from '../../../redux/actions/global/globalAction';
import Format from 'utils/Format';

function PopupChooseTable(props) {
    const {
        tables,
        isModalChooseTable,
        ordersByTable,
        onHidden,
        onFetchArea,
        currentLocation,
        setIsModalChooseTable,
        onConfirm,
        onCancel,
        onMove,
        valueTable
    } = props;

    const [currentOrder, setCurrentOrder] = useState<any>();
    const [moveToTableId, setMoveToTableId] = useState<any>();
    const {currentTableId} = useSelector((state: AppState) => state.global);
    const {currentTableName} = useSelector((state: AppState) => state.global);
    const {currentOrderId} = useSelector((state: AppState) => state.currentOrderId);
    const [orderChoose, setOrderChoose] = useState<any>();
    const [isMove, setIsMove] = useState(false);
    const _clearItemsOrderList = () => dispatch<any>(OrderAction.clearItemsOrderList());
    let navigate = useNavigate();
    function onFetchMoveOrderToTable(orderId, tableId ) {
        if (tableId === '') {
            alert('Hãy chọn bàn muốn chuyển');
        } else {
            orderId = parseInt(orderId);
            tableId = parseInt(tableId);
            fetchMoveOrderToTable({ orderId, tableId }).then((res) => {
                if (
                    res.statusCode === 200 &&
          res.message &&
          res.message === 'Success'
                ) {
                    toast.success('Chuyển bàn thành công', {
                        theme: 'colored'
                    });
                    onHidden();
                    onFetchArea(currentLocation.value);
                    if(currentOrder?.orderId !== currentOrderId) {
                        onMove?.({id: currentTableId, value: currentTableName});
                    } else {
                        onMove?.(moveToTableId);
                    }
                } else {
                    toast.warn('chuyển bàn không thành công', {
                        theme: 'colored'
                    });
                }
            });
        }
    }
    const onChangeMoveOrder = (value) => {
        setIsMove(true);
        setCurrentOrder(value);
    };
    // =================================================================
    // Dispatchs
    // =================================================================
    const dispatch = useDispatch();
    return (
        <>
            <Modal show={isModalChooseTable} onHide={() => {onHidden?.(); onCancel?.();}} >
                <>
                    <div>
                        <Modal.Header>
                            <Modal.Title>{!isMove ? 'Chọn Order' : 'Chuyển bàn'}</Modal.Title>
                        </Modal.Header>
                    </div>
                    <div className="cover-body">
                        <Modal.Body>

                            {
                                !isMove ? <><div className="order-list-body">
                                    {ordersByTable &&
                    ordersByTable?.map((order, index) => (
                        <div className="order-row">
                            <div key={`order${index}`} className="order-check" onClick={() => setCurrentOrder(order)}>
                                <input
                                    type="radio"
                                    style={{ marginTop: 5 }}
                                    name="orderId"
                                    id={`order${index}`}
                                    onChange={(e) => {
                                        if(e.target.checked){
                                            // dispatch(OrderAction.setCurrentOrderNo(order.orderNo));
                                            // dispatch(OrderAction.setCurrentOrderId(order.orderId));
                                            setOrderChoose(order);
                                        }
                                    }
                                    }
                                />
                                <label htmlFor={`order${index}`}>
                                    {' '}
                                    {order.orderNo}
                                </label>
                            </div>
                            <div
                                className="move-to-order"
            
                                onClick={() => {
                                    onChangeMoveOrder(order);
                                }}
                            >
                          Move
                                <ArrowForwardIcon fontSize="small" />
                            </div>
                        </div>
                    ))}
                                    <div className="order-row">
                                        <div
                                            className="order-check"
                                            style={{ minWidth: 30, cursor: 'pointer' }}
                                            onClick={() => {
                                                dispatch(OrderAction.setCurrentOrderNo(''));
                                                dispatch(OrderAction.setCurrentOrderId(''));
                                                navigate('/order/empty');
                                                _clearItemsOrderList();
                                                onHidden();
                                            }}
                                        >
                                            <AddIcon fontSize="small" style={{ marginTop: 2 }} />
                                            <div style={{ minWidth: 30, cursor: 'pointer' }}>
                                            Thêm order
                                            </div>
                                        </div>
                                    </div>
                                </div></>
                                    : 
                                    <>
                                        <div className="order-list-body">
                                            {tables &&
                        Format.sortUpTable(tables.filter(e => e.value !== valueTable))?.map((table, index) => (
                            <div className="order-row">
                                <div
                                    key={`table${index}`}
                                    className="order-check"
                                    onClick={() => {
                                        setMoveToTableId(table);
                                    }}
                                >
                                    <input
                                        type="radio"
                                        style={{ marginTop: 5 }}
                                        name="tableId"
                                        id={`table${index}`}
                                    />
                                    <label htmlFor={`table${index}`}>
                                        {table.value}
                                    </label>
                                </div>
                            </div>
                        ))}
                                        </div></>}
                        </Modal.Body>
                    </div>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                onHidden();
                                onCancel?.();
                                // dispatch(OrderAction.)
                            }}
                            style={{ width: 130, background: 'none', color: 'black' }}
                        >
                            Huỷ
                        </Button>
                        <Button
                            onClick={() => {
                                if(isMove) {
                                    onFetchMoveOrderToTable(currentOrder?.orderId, moveToTableId?.id);
                                    if(currentOrder?.orderId === currentOrderId) {
                                        dispatch(GlobalAction.changeCurrentTables(moveToTableId?.id));
                                        dispatch(GlobalAction.changeCurrentTablesName(moveToTableId?.value));
                                    } 
                                    onCancel?.();
                                } else {
                                    if (orderChoose) {
                                        dispatch(OrderAction.setCurrentOrderId(orderChoose?.orderId));                                        
                                        dispatch(OrderAction.setCurrentOrderNo(orderChoose?.orderNo));
                                        onConfirm?.();
                                        navigate(`/order/${orderChoose?.orderId}`);
                                        _clearItemsOrderList();
                                        setIsModalChooseTable(false);
                                    }
                                    else {
                                        toast.warn('Please select an order', {
                                            position: 'top-right'
                                        });
                                    }
                                }
                            }}
                            variant="primary"
                            style={{ width: 130, background: '#A6815B' }}
                        >
                            Xác nhận
                        </Button>
                    </Modal.Footer>
                </>
            </Modal>

            {/* <Modal show={showMoveOrder} onHide={handleCloseMoveOrderPopup}>
            <>
              <Modal.Header>
                <Modal.Title>Chuyển bàn</Modal.Title>
              </Modal.Header>
              <div className="cover-body">
                <Modal.Body>
                  <div className="order-list-body">
                    {tableList &&
                      tableList?.map((table, index) => (
                        <div className="order-row">
                          <div
                            key={`table${index}`}
                            className="order-check"
                            onClick={() => {
                              setMoveToTableId(table.table_id);
                              console.log(table.table_id);
                            }}
                          >
                            <input
                              type="radio"
                              style={{ marginTop: 5 }}
                              name="tableId"
                              id={`table${index}`}
                            />
                            <label htmlFor={`table${index}`}>
                              {" "}
                              {table.table_name}
                            </label>
                          </div>
                        </div>
                      ))}
                  </div>
                </Modal.Body>
              </div>
              <Modal.Footer>
                <Button variant="secondary"
                  style={{ width: 130, background: "none", color: "black" }}
                  onClick={handleCloseMoveOrderPopup}>
                  Huỷ
                </Button>
                <Button
                  style={{ width: 130, background: "#A6815B" }}
                  // variant="primary"
                  onClick={() => {
                    onFetchMoveOrderToTable({
                      orderId: currentOrderId,
                      tableId: moveToTableId,
                    });
                  }}
                >
                  Xác nhận
                </Button>
              </Modal.Footer>
            </>
          </Modal> */}

        </>
    );
}

export default PopupChooseTable;
