
/* eslint-disable filenames/match-regex */
import { StyleRowModel, TableColumnModel } from 'models/common';
import { colorSet } from 'shared/styles/ColorSet';

export const columns = [
    {
        id: '1',
        label: 'Oder No'
    },
    {
        id: '2',
        label: 'Time'
    },
    {
        id: '3',
        label: 'Table'
    },
    {
        id: '4',
        label: 'Payment Method'
    },
    {
        id: '5',
        label: 'Total'
    },
    {
        id: '6',
        label: ''
    }
] as TableColumnModel[];

export const arrKey = ['order_no', 'order_time', 'table_code',  'payment_option', 'total'] as Array<string>;

export const styleRow = [{
    key: 'total',
    style: {
        color: colorSet.default.red
    }
},
{
    key: 'icon',
    style: {
        marginRight: 10,
        marginLeft: 8
    }
}
] as StyleRowModel[];

export const FlexRow = [1.5, 1.5, 0.5, 3, 1, 0.3] as Array<number>;
