/* eslint-disable filenames/match-regex */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import './styles.scss';
import ConnectionLineFlow from './Content';
import ReactFlow, {
    useNodesState,
    ReactFlowProvider,
    addEdge,
    useEdgesState,
    MarkerType,
    Position
} from 'reactflow';
import 'reactflow/dist/style.css';
import { IconText , IconButton , IconImage } from './components/icons/FlowIcon';
import CustomEdge from './components/CustomEdge/CustomEdge';
import BlockFrame from './components/Frame';
import MenuRight from './components/MenuRight/MenuRight';
import BlockTable from './components/Table';
import Column from './components/Column';
import BlockDoor from './components/Door';
import { Language } from 'shared/Language';
import { TypeTable } from 'constants/Defines';
import FrameTable from './components/Frame';
import NumberFlow from './components/Number';

import { useDispatch, useSelector } from 'react-redux';
import OrderAction from '../../redux/actions/order/orderAction';
import LoadingTable from 'components/LoadingTable';
let id = 0;
const getId = () => `node_${id++}`;

const iconstyle = {
    transform: 'scale(0.5)'
};

// const libraryMes = [
//     {
//         id: 1,
//         title: 'Cột',
//         type: TypeTable.COT,
//         content: <IconText iconstyle={iconstyle} />
//     },
//     // {
//     //     id: 2,
//     //     title: 'Frame',
//     //     type: 'blockFrame',
//     //     content: <IconButton iconstyle={iconstyle} />
//     // },
    
//     {
//         id: 3,
//         title: 'Bàn 2',
//         type: TypeTable.BAN,
//         numberChair: 2,
//         content: <IconImage iconstyle={iconstyle} />
//     }
//     ,
//     {
//         id: 4,
//         title: 'Bàn 4',
//         type: TypeTable.BAN,
//         numberChair: 4,
//         content: <IconImage iconstyle={iconstyle} />
//     },
//     {
//         id: 5,
//         title: 'Bàn 6',
//         type: TypeTable.BAN,
//         numberChair: 6,
//         content: <IconImage iconstyle={iconstyle} />
//     },
//     {
//         id: 6,
//         title: 'Bàn 8',
//         type: TypeTable.BAN,
//         numberChair: 8,
//         content: <IconImage iconstyle={iconstyle} />
//     },
//     {
//         id: 7,
//         title: 'Cửa',
//         type: TypeTable.CUA,
//         content: <IconButton iconstyle={iconstyle} />
//     }
    
// ];
const nodeTypes = {
    COT: Column,
    blockFrame: BlockFrame,
    S4: BlockTable,
    CUA: BlockDoor,
    Frame: FrameTable,
    Number: NumberFlow
};
const edgeTypes = {
    custom: CustomEdge
};

const Flow = (props) => {
    const reactFlowWrapper = React.useRef(null);
    const {flowList, maxX, maxY, minX, minY, isEdit} = useSelector(
        (state) => state.common);
    const _user = localStorage.getItem('Apec_Account');
    const user = _user && JSON.parse(_user);
    const [nodes, setNodes, onNodesChange] = useNodesState(flowList|| []);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [nodeFocus, setNodeFocus] = useState({});
    const [showMenuRight, setShowMenuRight] = useState(false);
    const [arrNumber, setArrNumber] = useState([]);
    const params = useRef([]);
    const dispatch = useDispatch();
    // const onNodesChange = (e) => dispatch(OrderAction.setFlow(e));

    const onEdit = () => {
        dispatch(OrderAction.setEdit(!isEdit));
        props?.onEdit();
    };

    const checkArr = (_arr, e) => {
        if(_arr.length === 0) return;
        const check = _arr.find(item => item.position.x === e.position.x && item.position.y === e.position.y);
        if(check) return false;
        return true;
    };

    // const onChangeNode = (item, status) => {
    //     if(status === 1) {
    //         const newNode = {
    //             id: getId(),
    //             type: item.type,
    //             position: {
    //                 x: item.position.x + 10 ,
    //                 y: item.position.y + 10 
    //             },
    //             positionAbsolute: item?.positionAbsolute,
    //             data: item.data,
    //             sourcePosition: Position.Bottom,
    //             targetPosition: Position.Top,
    //             style: {width: item.width, height: item.height}
    //         };

    //         setNodes((nds) => nds.concat(newNode));
    //     } else {
    //         setNodes((nds) => nds.filter((node) => node.id !== item.id));
    //         setShowMenuRight(false);
    //     }
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChange = (value, idTable) => {
        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === idTable) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            content: value
                        }
                    };
                }
                return node;
            })
        );
    };

    const onChangePosition = (e) => {
        const _data = nodes?.find(item => item?.id === e[0]?.id);
        setArr(nodes);
        if(_data.position.x === maxX) dispatch(OrderAction.setMaxX(_data.position.x + 200));
        if(_data.position.y === maxY) dispatch(OrderAction.setMaxY(_data.position.y + 200));
        if(_data.position.x === minX) dispatch(OrderAction.setMinX(_data.position.x - 200));
        if(_data.position.y === minY) dispatch(OrderAction.setMinY(_data.position.y - 200));
        if(_data.id ) {
            if(!params.current || params.current.length < 1) {

                params.current.push({
                    id: Math.round(Number(_data?.id)),
                    tabletTop: Math.round(Number(_data?.position?.y)),
                    tabletLeft: Math.round(Number(_data?.position?.x)),
                    direction: _data.data?.rotate
                });
            } else {
                const _index = params.current.findIndex((item) => {
                    return item.id === Math.round(Number(_data?.id));
                });

                if(_index >= 0) {
                    params?.current.map((item, index) => {
                        if(index === _index) {
                            item.direction = _data?.data?.rotate;
                            item.tabletTop= Math.round(Number(_data?.position?.y));
                            item.tabletLeft= Math.round(Number(_data?.position?.x));
                        }
                    });
                } else {
                    params.current.push({
                        id: Math.round(Number(_data?.id)),
                        tabletTop: Math.round(Number(_data?.position?.y)),
                        tabletLeft: Math.round(Number(_data?.position?.x)),
                        direction: _data.data?.rotate
                    });
                }
            }
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeRotate = (value, idTable) => {
        const _data = nodes?.find(item => item?.id === idTable);
     

        if(!params.current || params.current.length < 1) {
            params.current.push({
                id: Math.round(Number(_data?.id)),
                tabletTop: Math.round(Number(_data?.position?.y)),
                tabletLeft: Math.round(Number(_data?.position?.x)),
                direction: value
            });
        } else {
            const _index = params.current.findIndex((item) => {
                return item.id === Math.round(Number(_data?.id));
            });
            if(_index >= 0) {
                params?.current.map((item, index) => {
                    if(index === _index) {
                        item.direction = value;
                    }
                });
            } else {
                params.current.push({
                    id: Math.round(Number(_data?.id)),
                    tabletTop: Math.round(Number(_data?.position?.y)),
                    tabletLeft: Math.round(Number(_data?.position?.x)),
                    direction: value
                });
            }
        }

        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === idTable) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            rotate: value
                        }
                    };
                }
                return node;
            })
        );
    };

    // const onShowMenu = () => {
    //     setShowMenuLeft(!showMenuLeft);
    // };
    // const onDragStart = (event, nodeType, numberChair, title) => {
    //     // event.target.style.border = '2px dotted rgba(0,0,0,.125)';
    //     event.target.querySelector('div').style.display = 'none';
    //     event.target.querySelector('span').style.display = 'none';
    //     event.target.querySelector('svg').style.display = 'none';
    //     // event.target.getElementsByClassName('message-help')[0].style.display =
    //     //     'none';

    //     event.dataTransfer.setData('application/reactflow', nodeType);
    //     event.dataTransfer.setData('application/number', numberChair);
    //     event.dataTransfer.setData('application/name', title);
    //     event.dataTransfer.effectAllowed = 'move';
    // };
    // const onDragEnd = (event, nodeType) => {
    //     // event.target.style.border = '0px';
    //     event.target.querySelector('div').style.display = 'block';
    //     event.target.querySelector('span').style.display = 'block';
    //     event.target.querySelector('svg').style.display = 'block';
    //     // event.target.getElementsByClassName('message-help')[0].style.display =
    //     //     'block';
    // };


    // eslint-disable-next-line @typescript-eslint/no-shadow
    const onConnect = (params) => setEdges((eds) => addEdge({ ...params, type: 'custom', markerEnd: { type: MarkerType.ArrowClosed } }, eds));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onFocus = useCallback((data) => {
        // setShowMenuRight(true);
        
        const table = props.table?.find(item => item.table_id === data.id);

        props?.onSelectTable?.(table);
        setNodeFocus(data);
    }, [props]);
    
    const onDragOver = React.useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);
    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds =
                reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
            const numberChair = event.dataTransfer.getData('application/number');
            const title = event.dataTransfer.getData('application/name');

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top
            });

            const newNode = {
                id: getId(),
                type: type,
                position,
                data: {
                    onChange,
                    onFocus,
                    onChangeRotate,
                    numberChair: Math.round(Number(numberChair)),
                    isActive: false,
                    name: title
                },
                sourcePosition: Position.Bottom,
                targetPosition: Position.Top
            };
            setNodes((nds) => nds.concat(newNode));
        },
        [onChange, onChangeRotate, onFocus, reactFlowInstance, setNodes]
    );

    useEffect(() => {
        if(flowList && flowList.length > 0) {
            const list = [];
            flowList?.map((item) => {
                list.push(
                    {...item, data: {
                        ...item.data,
                        onChange,
                        onFocus,
                        onChangeRotate
                    }}
                );
            });
            setArr(list);
            setNodes(list);
        }
    }, [flowList]);

    const setArr = (_nodes) => {
        const arr = [];
        for (var i = 0; i < _nodes?.length - 1; i++){
            let count = 0;

            if(arr.length === 0 || checkArr(arr, _nodes[i])) {
                for(var j = i + 1; j < _nodes?.length; j++) {
                    if(_nodes[i]?.position.x === _nodes[j]?.position.x && _nodes[i]?.position.y === _nodes[j]?.position.y) {
                        count ++;
                    }
                }
                if (count > 0) {
                    arr.push(
                        {
                            id: `${i}`,
                            type: 'Number',
                            position: _nodes[i]?.position,
                            data: {
                                name: 'Ban 4',
                                value: `${count}`,
                                rotate: 0
                            } ,
                            sourcePosition: 'bottom', 
                            targetPosition: 'top'
                        }
                    );
                }
            }
        }
        setArrNumber(arr);
    };

    const onSave = () => {
        dispatch(OrderAction.setFlow(nodes));
        props?.onSave(params.current);
        params.current = [];
    };

    return (
        <div className="container-flow">
            {Number(user?.position) !== 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 20, padding: '5px 16px'}}>
                <div className='action-save' onClick={onSave}>{Language.common.save}</div>
                <div className='action-edit' style={{backgroundColor: isEdit ? '#FBF3EF' : 'white'}} onClick={onEdit}>
                    {isEdit ? Language.common.editCancel: Language.common.edit}
                </div>
            </div>}
            <ReactFlowProvider>
                <div className="flow-chat-builder">
                    {/* {showMenuLeft && <div
                        className={`flow-builder-menu-left ${showMenuLeft ? 'show-menu' : ''
                        }`}
                    >
                        <div className="menu-left shadow-r">
                            <div className="builder-part">
                                <div className="detail-menu">
                                    <div className="d-flex menu-header-default">
                                        <div className="h-title">{Language.flow.library}</div>
                                    </div>
                                </div>
                                <div className="message-items">
                                    {libraryMes.map((item, index) => (
                                        <div
                                            key={index}
                                            className="message-sample"
                                            onDragEnd={(event) =>
                                                onDragEnd(event, item.type, item.numberChair)
                                            }
                                            onDragStart={(event) =>
                                                onDragStart(event, item.type, item.numberChair, item.title)
                                            }
                                            draggable
                                        >
                                            <div className="images position-relative">
                                                {item.content}
                                            </div>
                                            <span className="message-name">
                                                {item.title}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div
                                className="close-panel"
                                onClick={() => onShowMenu()}
                            >
                                <div className="icon-panel show-panel">
                                    <ArrowBackIosIcon />
                                </div>
                            </div>
                        </div>
                    </div>}
                    {showMenuRight &&   <div className={`flow-builder-menu-right ${showMenuLeft ? 'show-menu' : ''}`}>
                        <div className="menu-right shadow-r">
                            <div className="builder-part">
                                <div className="detail-menu">
                                    <div className="d-flex menu-header-default">
                                        <div className="h-title">{Language.common.edit}</div>
                                    </div>
                                </div>
                                <div className="message-items">
                                    <MenuRight nodeFocus={nodeFocus} nodes={nodes} onChangeNode={onChangeNode} />
                                </div>
                            </div>
                        </div>
                    </div>} */}
                    <div className="react-flow" ref={reactFlowWrapper} style={{backgroundColor: 'yellow'}}>
                        <ConnectionLineFlow
                            nodes={nodes.concat(arrNumber)}
                            onNodesChange={onNodesChange}
                            setReactFlowInstance={setReactFlowInstance}
                            nodeTypes={nodeTypes}
                            onEdgesChange={onEdgesChange}
                            onDrop={onDrop}
                            onConnect={onConnect}
                            onDragOver={onDragOver}
                            edgeTypes={edgeTypes}
                            onChangePosition={onChangePosition}
                            size={props.size}
                        />
                    </div>
                </div>
            </ReactFlowProvider>
        </div>
    );
};

export default Flow;
