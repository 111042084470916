import HomeReducer from './home/homeReducer';
import {globalReducer} from './global/globalReducer';
import {BookedListReducer, BookedListSplitReducer, CurrentOrderIdReducer, CurrentOrderNoReducer, LocalValueReducer, OrderListReducer, Common} from './order/orderReducer';
const reducers = {
    home: HomeReducer,
    currentOrderNo: CurrentOrderNoReducer,
    currentOrderId: CurrentOrderIdReducer,
    bookedList: BookedListReducer,
    bookedSplitList: BookedListSplitReducer,
    orderList: OrderListReducer,
    isLocal: LocalValueReducer,
    global: globalReducer,
    common: Common
};

export default reducers;
