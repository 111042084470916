
/* eslint-disable filenames/match-regex */
import { TableDropDown } from 'components/DropDown/types';
function formatMoney(number: string | number | undefined) {
    if (!number || number === 0) {
        return '0 đ';
    }
    return (
        `${Math.ceil(Number(number))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} đ`
    );
}

function formatMoneyDots(number: string | number | undefined) {
    if (!number || number === 0) {
        return '0';
    }
    return (
        `${Math.ceil(Number(number))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    );
}

function formatMoneyNumber(number: string | number | undefined) {
    if (!number || number === 0) {
        return '0 đ';
    }
    return (
        `${Math.ceil(Number(number))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} đ`
    );
}

function formatMoneyNotD(number: string | number | undefined) {
    if (!number) {
        return '0';
    }
    return (
        `${Math.ceil(Number(number))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    );
}

function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

function formatForEachWordCase(str: string) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

function formatUpperCaseCharacter(str: string) {
    return str = str.toUpperCase?.();
}

function splitNumber(str: string) {
    if(!str) return 0;
    return Number(str.split(/[^\d]+/).join(''));
}

function splitString(str: string) {
    return str?.split(/[\W\d]+/).join('');
}

function sortUpTable(data: TableDropDown[]) {
    let _data = data.sort();
    let kq = [] as TableDropDown[];
    let j = - 1;
    for ( let i = 0; i < _data.length; i ++ ){
        if(splitString(_data[i]?.value) !== splitString(_data[i+1]?.value)) {
            let _sort =  _data.slice(j+1, i + 1)
                .sort((a, b) => {
                    if(splitNumber(a?.value) < splitNumber(b?.value)) {
                        return -1;
                    } 
                    
                    if (splitNumber(a?.value) < splitNumber(b?.value)) {
                        return 1;
                    }}
                );
            kq =  kq.concat(_sort) ;
            j = i;
        }
    }
    return kq;
}

function removeAccents(str) {
    return str.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd').replace(/Đ/g, 'D');
}

const defaultNumbers =' hai ba bốn năm sáu bảy tám chín';

const chuHangDonVi = ('1 một' + defaultNumbers).split(' ');
const chuHangChuc = ('lẻ mười' + defaultNumbers).split(' ');
const chuHangTram = ('không một' + defaultNumbers).split(' ');
const dvBlock = '1 nghìn triệu tỷ'.split(' ');

function convertBlockThree(number) {
    if(number === '000') return '';
    var _a = number + ''; //Convert biến 'number' thành kiểu string
  
    //Kiểm tra độ dài của khối
    switch (_a.length) {
        case 0: return '';
        case 1: return chuHangDonVi[_a];
        case 2: return convertBlockTwo(_a);
        case 3: 
            var chuc_dv = '';
            if (_a.slice(1,3) !== '00') {
                chuc_dv = convertBlockTwo(_a.slice(1,3));
            }
            var tram = chuHangTram[_a[0]] + ' trăm';
            return tram + ' ' + chuc_dv;
    }
}

function convertBlockTwo(number) {
    var dv = chuHangDonVi[number[1]];
    var chuc = chuHangChuc[number[0]];
    var append = '';
  
    // Nếu chữ số hàng đơn vị là 5
    if (number[0] > 0 && number[1] === 5) {
        dv = 'lăm đồng';
    }
  
    // Nếu số hàng chục lớn hơn 1
    if (number[0] > 1) {
        append = ' mươi';
      
        if (number[1] === 1) {
            dv = ' mốt';
        }
    }
  
    return chuc + '' + append + ' ' + dv + ' nghìn'; 
}

function toMoney(number) {
    var str = parseInt(number) + '';
    var i = 0;
    var arr = [];
    var index = str.length;
    var result = [];
    var rsString = '';
  
    if (index === 0 || str === 'NaN') {
        return '';
    }
  
    // Chia chuỗi số thành một mảng từng khối có 3 chữ số
    while (index >= 0) {
        arr.push(str.substring(index, Math.max(index - 3, 0)));
        index -= 3;
    }
  
    // Lặp từng khối trong mảng trên và convert từng khối đấy ra chữ Việt Nam
    for (i = arr.length - 1; i >= 0; i--) {
        if (arr[i] !=='' && arr[i] !== '000') {
            result.push(convertBlockThree(arr[i]));
  
            // Thêm đuôi của mỗi khối
            if (dvBlock[i]) {
                result.push(dvBlock[i]);
            }
        }
    }
  
    // Join mảng kết quả lại thành chuỗi string
    rsString = result.join(' ');
    let strOne = rsString.toString()[0].toUpperCase();
    rsString = strOne + rsString.slice(1, rsString.length);
    // Trả về kết quả kèm xóa những ký tự thừa
    return rsString.replace(/[0-9]/g, '').replace(/ /g,' ').replace(/ $/,'');
}
  
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    formatMoney,
    capitalizeFirstLetter,
    formatForEachWordCase,
    formatUpperCaseCharacter,
    formatMoneyNotD,
    formatMoneyDots,
    formatMoneyNumber,
    sortUpTable,
    removeAccents,
    toMoney
};
