import '../css/checkboxCustom.css';
import React from 'react';
import IcCheckBox from '../../assets/svg/ic_check_box_circle.svg';
import IcCheckBoxSelect from '../../assets/svg/ic_check_box_circle_select.svg';

const CheckBox = (props) => {
    const { listOption, setListOption, item, styles, icon, typeCheck } = props;
    return ( 
        <div id="checkbox-cus-container">
            <div className={`checkbox-container ${styles}`}>
                {
                    listOption ? (
                        <img onClick={() => setListOption(!listOption, item?.name)} src={typeCheck ? IcCheckBoxSelect : 'assets/icon/Checkbox/UnChecked.svg' }alt="Checked" />
                    ) : (
                        <img onClick={() => setListOption(!listOption, item?.name)} src={typeCheck ? IcCheckBox : 'assets/icon/Checkbox/Checked.svg'} alt="UnChecked" />
                    )
                }
                {icon && <div className='mgl-16'>
                    {icon}</div>}
                <div className="checkbox-label">{item?.name}</div>
            </div>
        </div>
    );
};
export default CheckBox;
