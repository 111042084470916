/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable filenames/match-regex */
import './styles.scss';
import { HeaderProps } from './type';
export default function Header({
    column, index, isCheckBox, flexHeader
}: HeaderProps) {
    return (
        <div className= {(column.id === '1' && !isCheckBox) ? 'th' : 'border-left th'} style={{flex: flexHeader[index]}}>
            <div className='th-content'>
                <span className='text-normal bold text-black'>{`${column.label}`}</span>
            </div>
        </div>
    );
}
