import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import AppV2 from 'AppV2';
import 'assets/styles/main.scss';
import { Provider } from 'react-redux';
import configureStore from 'redux/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const store = configureStore();

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <AppV2 />
        </Provider>
    </BrowserRouter>
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
