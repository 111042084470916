/* eslint-disable react/react-in-jsx-scope */
import Modal from '@mui/material/Modal';
import { PopupPrintfAction, PopupPrintfProps } from 'components/popup-print/types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import BgScan from '../../assets/img/bg_scan.png';
import Box from '@mui/material/Box';
import './styles.scss';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};


const QrScanCode = forwardRef<PopupPrintfAction, PopupPrintfProps>(({onConfirm}: PopupPrintfProps, ref) => {

    const [visible, setVisible] = useState<boolean>(false);

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };
    useImperativeHandle(ref, () => ({
        showModal,
        hideModal
    }));

    return (
        <Modal open={visible}   
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onBackdropClick={hideModal}
        >
            
            <Box sx={style}>
                <div 
                    style={{ 
                        backgroundImage: `url(${BgScan})`,
                        width: 350,
                        height: 350,
                        backgroundRepeat: 'no-repeat',
                        display: 'flex',
                        backgroundSize: 'cover',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <QrReader
                        constraints={{ facingMode: 'environment'}}
                        onResult={(result, error) => {
                            if (result) {
                                onConfirm?.(result);
                                hideModal();
                            }
                            if (error) {
                                console.log(error);
                            }
                        }}
                        videoContainerStyle={{ width: '340px', height: '340px', borderRadius: 10}}
                        scanDelay={500}
                    />   
                </div>
            </Box>
        </Modal>
    );
});
export default QrScanCode;
