import { useAuth } from 'layout/auth-provider';
import { Navigate, useLocation } from 'react-router-dom';

export default function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();
    if (!localStorage.getItem('Apec_Account')) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        // eslint-disable-next-line react/react-in-jsx-scope
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
}
