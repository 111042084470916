import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Cart from '../cart';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import OrderAction from '../../../redux/actions/order/orderAction';
import { AppState } from 'redux/store';
import { SVGTick } from 'assets/svg';
import Format from 'utils/Format';

export interface IaddItemState{
    menu_id: number,
    item_name: string,
    price: number,
    image: string,
    qty: number,
    totalPrice: number,
    item_id: number,
    parent_id: number,
    root_menu: number
}
function Dish(props) {
    const {
        keyword,
        listMeal,
        listSearch,
        setListSearch,
        setTabs,
        showCard
    } = props;
    const {orderList} = useSelector((state: AppState) => state.orderList);
    const formatMoney = (number) => {
        if (typeof number === 'number') {
            const optionsVND = { style: 'currency', currency: 'VND', currencyDisplay: 'code' };
            return number.toLocaleString('vi-VN', optionsVND).split('V')[0];
        }
        return number;
    };
    const handleSearch = () => {
        let arrSearch = [];
        listMeal?.map(item => {
            if (Format.removeAccents(item.item_name.toLowerCase()).includes(Format.removeAccents(keyword.toLowerCase()))) {
                arrSearch.push(item);
            }
        });
        setListSearch(arrSearch);
    };
    useEffect(() =>{
        handleSearch();
    },[keyword]);
    // Dispatch
    // ===========================================================================
    const dispatch = useDispatch();
    const _addToCartOrder = (cardItem:IaddItemState) => dispatch<any>(OrderAction.addToCartOrder(
        {
            menu_id: cardItem.menu_id,
            label: cardItem.item_name,
            price: cardItem.price,image: cardItem.image,
            qty:cardItem.qty,
            totalPrice: cardItem.totalPrice,
            item_id: cardItem.item_id,
            node: '',
            parent_id: cardItem.parent_id, 
            root_menu: cardItem.root_menu
        })
    );
    const _deleteToCartOrder = (cardItem:IaddItemState) => dispatch<any>(OrderAction.removeItemsFromCart(
        {
            menu_id: cardItem.menu_id,
            label: cardItem.item_name,
            price: cardItem.price,image: cardItem.image,
            qty:cardItem.qty,totalPrice: cardItem.totalPrice,
            item_id: cardItem.item_id, 
            parent_id: cardItem.parent_id,
            root_menu: cardItem.root_menu
        })
    );
    return (
        <div className='page-dish'>
            <div className='content-dish'>
                <div style={{ width: '100%' }}>
                    {
                        listSearch?.length > 0
                        &&
                        (<div className='dish'>
                            {
                                listSearch?.filter(item => item.item_id !== 0).map((item, index) => {
                                    const isExist = orderList.find(ele => ele.menu_id === item.menu_id && ele?.parent_id === item?.parent_id);
                                    return (
                                        <div key={index} style={{ borderWidth: 1, borderColor: '#12B669' }}
                                            className={`item ${index % 2 === 0 ? 'item-active' : ''}`}
                                            onClick={() => {
                                                if(isExist) {
                                                    _deleteToCartOrder(item);
                                                } else {
                                                    _addToCartOrder(item);
                                                    showCard?.();
                                                }
                                                setTabs?.('1');
                                            }}
                                        >
                                            <div className='image' style={{
                                                backgroundImage: `url(${item?.image || '/assets/no_image.png'})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                width: '100%',
                                                height: 120,
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                {isExist && <SVGTick/>}
                                            </div>
                                            <div className='info'>
                                                <div className='label txt-info'>{item?.item_name}</div>
                                                <div className='price'>{formatMoney(item?.price)}đ</div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>)
                    }
                    {listSearch?.length === 0 && !keyword
                        &&
                        (
                            <div className='empty-cate'>
                                <img className="image-empty" src="../../../assets/icon/EmptyCate/index.svg" alt="empty" />
                            </div>
                        )
                    }
                    {listSearch?.length === 0 && keyword
                        &&
                        (
                            <div className='empty-cate'>
                                <img className="image-empty" src="assets/icon/EmptySearch/index.svg" alt="empty" />
                            </div>
                        )
                    }
                </div>
            </div>
            
        </div>

    );
}
export default Dish;
