/* eslint-disable import/no-duplicates */
/* eslint-disable filenames/match-regex */
import React, { useEffect } from 'react';
import './styles.scss';

function Column(props) {

    return (
        <div 
            className="container-column-flow"
            // onClick={props.data?.onChange}
        >
            <span className='text-big bold color-white'>Cột</span>
        </div>
    );
}

export default Column;
