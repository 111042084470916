/* eslint-disable filenames/match-regex */
import DateUtils from 'utils/DateUtils';
import Format from 'utils/Format';
import {  InfoHotelModel, OrderModel } from './../../models/order.d';
export function renderHTMLBillInvoice(data: OrderModel, infoHotel: InfoHotelModel) {
    const newDate = DateUtils.formatDayAndTimePicker(new Date()).split('/')[1];
    const _user = localStorage.getItem('Apec_Account');
    const user = _user && JSON.parse(_user).username;
    const renderColumn = () => {
        let _column = '';
        for(let i=0; i < data.items.length; i++) {
            _column += `<tr style="vertical-align:top;">
            <td style="width:0px;height:22px;"></td>
            <td class="csA8DCAC4A" colspan="1" style="width:2px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
            <td class="csB03FD833" style="width:21px;height:27px;line-height:27px;text-align:left;vertical-align:middle;"><nobr>${i + 1}</nobr></td>
            <td class="csB03FD833" colspan="14" rowspan="2" style="width:104px;height:27px;line-height:12px;text-align:left;vertical-align:middle;"><nobr>${data?.items[i].itemName}</nobr></td>
            <td class="csB03FD833" colspan="3" rowspan="2" style="width:40px;height:27px;line-height:12px;text-align:center;vertical-align:middle;"><nobr>${data?.items[i].unitName}</nobr></td>
            <td class="csF09495AB" colspan="1" rowspan="2" style="width:12px;height:27px;line-height:12px;text-align:center;vertical-align:middle;"><nobr>${data?.items[i].qty}</nobr></td>
            <td class="csF09495AB" colspan="2" rowspan="2" style="width:52px;height:27px;line-height:12px;text-align:center;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data?.items[i].price)}</nobr></td>
            <td class="csF09495AB" colspan="12" rowspan="2" style="width:60px;height:27px;line-height:12px;text-align:center;vertical-align:middle;"><nobr>80,000</nobr></td>
            <td class="csF09495AB" colspan="10" rowspan="2" style="width:47px;height:27px;line-height:12px;text-align:center;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data?.items[i].discountAmount)}</nobr></td>
            <td class="csF09495AB" colspan="10" rowspan="2" style="min-width:120px;height:27px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data?.items[i].total)}</nobr></td>
            <td></td>
        </tr>
        <tr style="vertical-align:top;">
            <td style="width:0px;height:15px;"></td>
            <td class="csA8DCAC4A" colspan="2" style="width:2px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
            <td class="csA8DCAC4A" style="width:25px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
            <td></td>
        </tr>`;
        } 
        return _column;
    };
    return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<!-- saved from url=(0016)http://localhost -->
<html>
<head>
	<title>Document</title>
	<meta HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"/>
	<style type="text/css">
    .cs4FADFF8F {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right:#FFFFFF 2px solid;border-bottom-style: none;font-family:'Times New Roman'; font-size:13px; font-weight:bold; font-style:normal; }
		.cs8E2DFAB {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:bold; font-style:italic; }
		.csC1740083 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:bold; font-style:italic; padding-left:2px;}
		.cs8EA18762 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:bold; font-style:normal; padding-left:2px;}
		.cs8EA187A8 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:bold; font-style:normal; padding-left:4px;}
		.cs46A3E351 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:bold; font-style:normal; padding-top:5px;padding-left:5px;padding-bottom:5px;}
		.cs86EE7AC5 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:bold; font-style:normal; padding-top:5px;padding-left:5px;padding-right:5px;padding-bottom:5px;}
		.csB23251C5 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:italic; }
		.csF019F6E5 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:italic; padding-left:2px;}
		.csF019F59F {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:italic; padding-left:4px;}
		.csD589D4E {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:normal; padding-left:2px;}
		.cs5F7AA6EF {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:normal; padding-left:2px;padding-right:2px;}
		.csD589D10 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:normal; padding-left:4px;}
		.csD589D2F {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:normal; padding-left:5px;}
		.csB03FD833 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:normal; padding-top:5px;padding-left:5px;padding-bottom:5px;}
		.csF09495AB {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:11px; font-weight:normal; font-style:normal; padding-top:5px;padding-left:5px;padding-right:5px;padding-bottom:5px;}
		.csF3554C5D {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:12px; font-weight:bold; font-style:normal; padding-left:2px;}
		.csE6322FFC {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:12px; font-weight:bold; font-style:normal; padding-left:2px;padding-right:2px;}
		.cs9A77F515 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:12px; font-weight:normal; font-style:italic; padding-left:1px;}
		.cs9A77F5F6 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:12px; font-weight:normal; font-style:italic; padding-left:2px;}
		.csE9BCDB7C {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:12px; font-weight:normal; font-style:normal; padding-left:1px;}
		.csA8DCAC4A {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:13px; font-weight:normal; font-style:normal; }
		.cs57813273 {color:#000000;background-color:transparent;border-left-style: none;border-top-style: none;border-right-style: none;border-bottom-style: none;font-family:'Times New Roman'; font-size:20px; font-weight:bold; font-style:normal; padding-left:2px;padding-right:2px;}
		.csAC6A4475 {height:0px;width:0px;overflow:hidden;font-size:0px;line-height:0px;}
	</style>
</head>
<body leftMargin=10 topMargin=10 rightMargin=10 bottomMargin=10 style="background-color:#FFFFFF; margin-top: 20px;  height: fix-content !important">
<table cellpadding="0" cellspacing="0" border="0" style="border-width:0px;empty-cells:show;width:485px;height:572px;position:relative;">
	<tr>
		<td style="width:0px;height:0px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:25px;"></td>
		<td style="height:0px;width:14px;"></td>
		<td style="height:0px;width:10px;"></td>
		<td style="height:0px;width:9px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:10px;"></td>
		<td style="height:0px;width:18px;"></td>
		<td style="height:0px;width:12px;"></td>
		<td style="height:0px;width:10px;"></td>
		<td style="height:0px;width:2px;"></td>
		<td style="height:0px;width:3px;"></td>
		<td style="height:0px;width:8px;"></td>
		<td style="height:0px;width:2px;"></td>
		<td style="height:0px;width:6px;"></td>
		<td style="height:0px;width:4px;"></td>
		<td style="height:0px;width:11px;"></td>
		<td style="height:0px;width:4px;"></td>
		<td style="height:0px;width:27px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:11px;"></td>
		<td style="height:0px;width:13px;"></td>
		<td style="height:0px;width:4px;"></td>
		<td style="height:0px;width:2px;"></td>
		<td style="height:0px;width:2px;"></td>
		<td style="height:0px;width:10px;"></td>
		<td style="height:0px;width:6px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:5px;"></td>
		<td style="height:0px;width:10px;"></td>
		<td style="height:0px;width:9px;"></td>
		<td style="height:0px;width:5px;"></td>
		<td style="height:0px;width:9px;"></td>
		<td style="height:0px;width:5px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:11px;"></td>
		<td style="height:0px;width:5px;"></td>
		<td style="height:0px;width:2px;"></td>
		<td style="height:0px;width:2px;"></td>
		<td style="height:0px;width:7px;"></td>
		<td style="height:0px;width:12px;"></td>
		<td style="height:0px;width:3px;"></td>
		<td style="height:0px;width:5px;"></td>
		<td style="height:0px;width:5px;"></td>
		<td style="height:0px;width:7px;"></td>
		<td style="height:0px;width:13px;"></td>
		<td style="height:0px;width:2px;"></td>
		<td style="height:0px;width:3px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:9px;"></td>
		<td style="height:0px;width:2px;"></td>
		<td style="height:0px;width:9px;"></td>
		<td style="height:0px;width:10px;"></td>
		<td style="height:0px;width:27px;"></td>
		<td style="height:0px;width:23px;"></td>
		<td style="height:0px;width:13px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:33px;"></td>
		<td style="height:0px;width:5px;"></td>
		<td style="height:0px;width:1px;"></td>
		<td style="height:0px;width:2px;"></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:16px;"></td>
		<td></td>
		<td></td>
		<td class="csA8DCAC4A" colspan="15" rowspan="4" style="width:134px;height:65px;text-align:left;vertical-align:top;"><div style="overflow:hidden;width:134px;height:65px;">
			<img alt="" src="C:\APPLICATION\LIVE_SMARTHOTEL\APPS\PMS\WebAPI\assets/uploads/medias/image/1eff3a37-2754-4efe-8ef6-b49e9b298887.jpg" style="width:0px;height:0px;" /></div>
		</td>
		<td></td>
		<td class="cs8EA18762" colspan="42" style="width:305px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${infoHotel.hotelName}</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:17px;"></td>
		<td></td>
		<td></td>
		<td></td>
	
		<td class="cs8EA18762"  style="width:41px;height:17px;line-height:12px;text-align:left;vertical-align:top;"><nobr>Website:</nobr></td>
		<td class="csC1740083" colspan="40" style="width:262px;height:17px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${infoHotel.website}</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:16px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td class="cs8EA18762" style="width:27px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>Add:</nobr></td>
		<td class="cs8E2DFAB" colspan="38" style="width:278px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${infoHotel.address}</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:16px;"></td>
		<td></td>
		<td></td>
		<td></td>

		<td class="cs8EA18762" style="width:48px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>Tax&nbsp;code:</nobr></td>
		<td class="csC1740083"  style="width:58px;height:26px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${infoHotel.taxCode}</nobr></td>
		<td></td>
		<td class="cs8EA187A8"  style="width:41px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>Hotline:</nobr></td>
		<td class="cs8E2DFAB" colspan="15" style="width:141px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${infoHotel.fax}</nobr></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:10px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:13px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:25px;"></td>
		<td class="cs57813273" colspan="61" style="width:479px;height:25px;line-height:23px;text-align:center;vertical-align:middle;"><nobr>H&#211;A&nbsp;ĐƠN&nbsp;/&nbsp;INVOICE</nobr></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:15px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:17px;"></td>
		<td></td>
		<td class="cs8EA18762"  style="width:68px;height:17px;line-height:12px;text-align:left;vertical-align:top;"><nobr>Ng&#224;y&nbsp;mở&nbsp;b&#224;n</nobr></td>
		<td class="csF019F6E5" colspan="3" style="width:70px;height:17px;line-height:12px;text-align:left;vertical-align:top;"><nobr>&nbsp;&nbsp;&nbsp;(Open&nbsp;Date):</nobr></td>
		<td class="csD589D2F" colspan="15" style="width:135px;height:17px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${data.createdDate}</nobr></td>
		<td></td>
		<td></td>
		<td class="cs8EA18762" style="width:50px;height:17px;line-height:12px;text-align:left;vertical-align:top;"><nobr>Địa điểm</nobr></td>
		<td class="csF019F6E5" colspan="3" style="width:64px;height:17px;line-height:12px;text-align:left;vertical-align:top;"><nobr>&nbsp;&nbsp;(Location):</nobr></td>
		<td class="csD589D10" colspan="15" style="width:109px;height:17px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${data.location}</nobr></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:16px;"></td>
		<td></td>
		<td class="cs8EA18762" style="width:68px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>M&#227;&nbsp;giao&nbsp;dịch</nobr></td>
		<td class="csF019F6E5" colspan="2" style="width:63px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>&nbsp;&nbsp;(Order&nbsp;No):</nobr></td>
		<td class="csD589D4E" colspan="17" style="width:145px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${data.orderNo}</nobr></td>
		<td></td>
		<td class="cs8EA18762" style="width:45px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>Ngày in</nobr></td>
		<td class="csB23251C5" colspan="3" style="width:69px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>&nbsp;(Print&nbsp;Date):</nobr></td>
		<td class="csB23251C5" colspan="15" style="width:109px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${newDate}</nobr></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:16px;"></td>
		<td></td>
		<td class="cs8EA18762"  style="width:30px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>Số&nbsp;kh&#225;ch</nobr></td>
		<td class="csF019F59F" colspan="2" style="width:81px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>&nbsp;&nbsp;(Total&nbsp;Guest):</nobr></td>
		<td class="csD589D10" colspan="17" style="width:154px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${data.noOfGuest}</nobr></td>
		<td></td>
		<td class="cs8EA18762"  style="width:37px;height:16px;line-height:12px;text-align: left;vertical-align:top;"><nobr>Số&nbsp;b&#224;n</nobr></td>
		<td class="csF019F6E5" colspan="4" style="width:76px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>(Table&nbsp;No):</nobr></td>
		<td class="csD589D2F" colspan="9" style="width:117px;height:16px;line-height:12px;text-align:left;vertical-align:top;"><nobr>${data.tableCode}</nobr></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:11px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
    <tr style="vertical-align:top;">
		<td style="width:0px;height:24px;"></td>
        <td class="cs4FADFF8F" colspan="1" style="width:2px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="cs4FADFF8F" style="width:16px;height:24px;line-height:9px;text-align:left ;vertical-align:middle;"><nobr>STT</nobr></td>
		<td class="cs4FADFF8F" colspan="14" style="width:150px;height:24px;line-height:9px;text-align:left; padding-left: 12px;vertical-align:middle;"><nobr>Mặt&nbsp;h&#224;ng</nobr></td>
		<td class="cs4FADFF8F" colspan="3" style="width:24px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>ĐVT</nobr></td>
		<td class="cs4FADFF8F" colspan="1" style="width:45px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>Số lượng</nobr></td>
		<td class="cs4FADFF8F" colspan="2" style="width:51px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>Đơn giá</nobr></td>
		<td class="cs4FADFF8F" colspan="12" style="width:51px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>Số tiền</nobr></td>
		<td class="cs4FADFF8F" colspan="10" style="width:51px;height:24px;line-height:9px;text-align:center;vertical-align:middle;"><nobr>Chiết khẩu</nobr></td>
		<td class="cs4FADFF8F" colspan="10" style="min-width:120px;height:24px;line-height:9px;text-align:right;vertical-align:middle;"><nobr>Số tiền sau CK</nobr></td>
	</tr>
	${renderColumn()}
    <tr style="vertical-align:top;">
            <td style="width:0px;height:22px;"></td>
            <td class="cs4FADFF8F" colspan="1" style="width:2px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
            <td class="cs4FADFF8F" style="width:21px;height:22px;line-height:12px;text-align:left;vertical-align:middle;"><nobr></nobr>CỘNG</td>
            <td class="csB03FD833" colspan="14" rowspan="2" style="width:104px;height:27px;line-height:12px;text-align:left;vertical-align:middle;"><nobr></nobr></td>
            <td class="csB03FD833" colspan="3" rowspan="2" style="width:40px;height:27px;line-height:12px;text-align:center;vertical-align:middle;"><nobr></nobr></td>
            <td class="csF09495AB" colspan="1" rowspan="2" style="width:12px;height:27px;line-height:12px;text-align:right;vertical-align:middle;"><nobr></nobr></td>
            <td class="csF09495AB" colspan="2" rowspan="2" style="width:52px;height:27px;line-height:12px;text-align:center;vertical-align:middle;"><nobr></nobr></td>
            <td class="cs4FADFF8F" colspan="12" rowspan="2" style="width:60px;height:27px;line-height:12px;text-align:center;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data?.amountNet)}</nobr></td>
            <td class="cs4FADFF8F" colspan="10" rowspan="2" style="width:47px;height:27px;line-height:12px;text-align:center;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data?.totalDiscount)}</nobr></td>
            <td class="cs4FADFF8F" colspan="10" rowspan="2" style="min-width:120px;;height:27px;line-height:12px;text-align:right;vertical-align:middle;"><nobr>${Format.formatMoneyNotD(data?.total)}</nobr></td>
            <td></td>
        </tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:3px;"></td>
		<td class="csA8DCAC4A" colspan="2" style="width:2px;height:3px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:25px;height:3px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>

	<tr style="vertical-align:top;">
		<td style="width:0px;height:11px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:18px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:40px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:48px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:12px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:5px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:31px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csF3554C5D" style="width:50px;height:18px;line-height:13px;text-align:left;vertical-align:top;"><nobr>Th&#224;nh&nbsp;tiền</nobr></td>
		<td class="cs9A77F5F6" style="width:60px;height:18px;line-height:13px;text-align:left;vertical-align:top;"><nobr>&nbsp;&nbsp;(Sub&nbsp;Amount):</nobr></td>
		<td class="csE6322FFC" colspan="21" style="width:157px;height:18px;line-height:13px;text-align:left;vertical-align:top;"><nobr>${Format.formatMoneyNotD(data.amountNet)} VND</nobr></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:18px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:40px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:48px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:12px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:5px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:31px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csF3554C5D"  style="width:94px;height:18px;line-height:13px;text-align:left;vertical-align:top;"><nobr>Gi&#225;&nbsp;trị&nbsp;chiết&nbsp;khấu</nobr></td>
		<td class="cs9A77F5F6"  style="width:124px;height:18px;line-height:13px;text-align:left;vertical-align:top;"><nobr>&nbsp;&nbsp;&nbsp;(Bill&nbsp;Discount):</nobr></td>
		<td class="csE6322FFC" colspan="6" style="width:90px;height:18px;line-height:13px;text-align: left;vertical-align:top;"><nobr>${Format.formatMoneyNotD(data.totalDiscount)} VND</nobr></td>
		<td class="csA8DCAC4A" style="width:1px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:18px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:40px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:48px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:12px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:5px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:31px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csF3554C5D"  style="width:79px;height:18px;line-height:13px;text-align:left;vertical-align:top;"><nobr>Service&nbsp;charge</nobr></td>
		<td class="cs9A77F515" style="width:23px;height:18px;line-height:13px;text-align:left;vertical-align:top;"><nobr>(5%):</nobr></td>
		<td class="csE6322FFC" colspan="30" style="width:168px;height:18px;line-height:13px;text-align:left;vertical-align:top;"><nobr>${Format.formatMoneyNotD(data?.svc)} VND</nobr></td>
		<td class="csA8DCAC4A" style="width:1px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:17px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:40px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:48px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:12px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:5px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:31px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csF3554C5D"  colspan="2  "style="width:79px;height:17px;line-height:13px;text-align:left;vertical-align:top;"><nobr>Tax&nbsp;Amount:</nobr></td>
		<td class="csE6322FFC" colspan="1" style="width:183px;height:17px;line-height:13px;text-align: left;vertical-align:top;"><nobr>${Format.formatMoneyNotD(data?.vat)} VND</nobr></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:18px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:40px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:48px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:12px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:5px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:31px;height:18px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csF3554C5D" style="width:117px;height:18px;line-height:13px;text-align:left;vertical-align:top;"><nobr>Tổng&nbsp;tiền&nbsp;thanh&nbsp;to&#225;n</nobr></td>
		<td class="cs9A77F5F6"  style="width:92px;height:18px;line-height:13px;text-align:left;vertical-align:top;"><nobr>&nbsp;&nbsp;(Grand&nbsp;Total):</nobr></td>
		<td class="csE6322FFC" style="width:94px;height:18px;line-height:13px;text-align:right;vertical-align:top;"><nobr>${Format.formatMoneyNotD(data?.total)} VND</nobr></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:9px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:40px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:48px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:12px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:5px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:31px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:4px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:39px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:13px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:6px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:2px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:16px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:9px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:9px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:12px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="9" style="width:48px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:15px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:3px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:10px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:71px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:13px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:33px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:9px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:17px;"></td>
		<td class="csF3554C5D" colspan="4" style="width:99px;height:17px;line-height:13px;text-align:left;vertical-align:top;"><nobr>Số&nbsp;tiền&nbsp;bằng&nbsp;chữ</nobr></td>
		<td class="cs9A77F5F6" colspan="4" style="width:56px;height:17px;line-height:13px;text-align:left;vertical-align:top;"><nobr>&nbsp;(In&nbsp;word):</nobr></td>
		<td class="cs9A77F515" colspan="41" rowspan="2" style="width:329px;height:30px;line-height:13px;text-align:left;vertical-align:top;;"><nobr>${' '}${data?.texT_TOTAL}</nobr></td>
		<td class="csA8DCAC4A" style="width:5px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:13px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:13px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:40px;height:13px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:48px;height:13px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:12px;height:13px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:13px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:5px;height:13px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:31px;height:13px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:13px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:13px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:16px;"></td>
		<td class="csF3554C5D" colspan="5" style="width:114px;height:16px;line-height:13px;text-align:left;vertical-align:top;"><nobr>H&#236;nh&nbsp;thức&nbsp;thanh&nbsp;to&#225;n</nobr></td>
		<td class="cs9A77F5F6" style="width:98px;height:16px;line-height:13px;text-align:left;vertical-align:top;"><nobr>(Payment&nbsp;Method):</nobr></td>
		<td class="csE9BCDB7C" colspan="33" style="width:265px;height:16px;line-height:13px;text-align:left;vertical-align:top; margin-left: 5px"><nobr>${data.payment}</nobr></td>
		<td class="csA8DCAC4A" style="width:5px;height:16px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:16px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:17px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:40px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:48px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:12px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:5px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:31px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:4px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:39px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:13px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:6px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:2px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csE9BCDB7C" colspan="33" style="width:265px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:17px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:22px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:40px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csE6322FFC" colspan="15" style="width:106px;height:22px;line-height:13px;text-align:center;vertical-align:top;"><nobr>Cashier&nbsp;signature</nobr></td>
		<td class="csA8DCAC4A" colspan="3" style="width:39px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:13px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:6px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:2px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:16px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:9px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:9px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:12px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="9" style="width:48px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csE6322FFC" colspan="10" style="width:95px;height:22px;line-height:13px;text-align:center;vertical-align:top;"><nobr>Guest&nbsp;signature</nobr></td>
		<td class="csA8DCAC4A" style="width:13px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:33px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:22px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:67px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:40px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:48px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:12px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:5px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:31px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:4px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="3" style="width:39px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:13px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:6px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:2px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:16px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:9px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:9px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:12px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="9" style="width:48px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:15px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:3px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:10px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:71px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:13px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:33px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:67px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:15px;"></td>
		<td class="csA8DCAC4A" style="width:1px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csE6322FFC" colspan="21" style="width:185px;height:15px;line-height:13px;text-align:center;vertical-align:top;"><nobr>${user}</nobr></td>
		<td class="csA8DCAC4A" style="width:13px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:6px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:2px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:16px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:10px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:9px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:9px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:12px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="9" style="width:48px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:15px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:3px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="2" style="width:10px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" colspan="5" style="width:71px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:13px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:33px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:5px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td class="csA8DCAC4A" style="width:1px;height:15px;"><!--[if lte IE 7]><div class="csAC6A4475"></div><![endif]--></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:2px;"></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
	</tr>
	<tr style="vertical-align:top;">
		<td style="width:0px;height:22px;"></td>
		<td class="csE6322FFC" colspan="61" style="width:479px;height:22px;line-height:13px;text-align:center;vertical-align:middle;"><nobr>We&nbsp;hope&nbsp;you&nbsp;enjoyed&nbsp;our&nbsp;service&nbsp;!</nobr></td>
		<td></td>
	</tr>
</table>
</body>
</html>
    `;

}
