import React, { useEffect, useState, useRef } from 'react';
import ReactFlow, {  useUpdateNodeInternals, NodeResizer } from 'reactflow';
import { drag } from 'd3-drag';
import { select } from 'd3-selection';
import ImgDoor from '../../../../assets/img/img_door.png';

import 'reactflow/dist/style.css';
import './styles.scss';
import useComponentVisible from 'hook/IsOutSizeHook';

export default function BlockDoor(
    props
) {
    const rotateControlRef = useRef(null);
    const updateNodeInternals = useUpdateNodeInternals();
    const [rotation, setRotation] = useState<Number>(0);
    const { refFocus, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);

    useEffect(() => {
        if (!rotateControlRef.current) {
            return;
        }

        if (props?.data?.rotate) {
            setRotation(props?.data?.rotate);
        }

        const selection = select(rotateControlRef.current);
        const dragHandler = drag().on('drag', (evt) => {
            const dx = evt.x - 100;
            const dy = evt.y - 100;
            const rad = Math.atan2(dx, dy);
            const deg = rad * (180 / Math.PI);
            setRotation(180 - deg);
            updateNodeInternals(props?.id);
        });

        selection.call(dragHandler);
    }, [props, updateNodeInternals, isComponentVisible]);

    useEffect(() => {
        // props?.data?.onChangeRotate(rotation, props.id);
    }, [rotation]);

    const onClickTable = () => {
        setIsComponentVisible(true);
        props?.data?.onFocus(props);
    };

    return (
        <div
            style={{
                transform: `rotate(${rotation}deg)`
            }}
            className='node-door'
            onClick={onClickTable}
            ref={refFocus}
        >
            {isComponentVisible && <div
                ref={rotateControlRef}
                style={{
                    display:  'block'
                }}
                className={'rotate-handle'}
            />}
            <img src={ImgDoor}/>
        </div>
    );
}
