export const COLOR = {
    succes : 'rgb(76, 175, 80)',
    new: 'rgb(33, 150, 243)',
    sending: '#ffef62',
    fail: 'rgb(244, 67, 54)'
};
export const statusEmailNotification = {
    NEW: 'new',
    SENDING: 'sending',
    SUCCESS: 'success',
    FAILED: 'failed'
};
export const getRole = (value) => {
    let role = [];
    if(value === 'admin') {
        role = ['admin', 'user'];
    }
    if(value === 'user') {
        role = ['user'];
    }
    return role;
};

export const checkTimeNowBetween = (startTime: string,endTime: string) =>{
    // var startTime = '15:10';
    // var endTime = '22:30';

    let currentDate = new Date();   
    
    let startDate = new Date(currentDate.getTime());
    startDate.setHours(Number(startTime.split(':')[0]));
    startDate.setMinutes(Number(startTime.split(':')[1]));
    
    let endDate = new Date(currentDate.getTime());
    endDate.setHours(Number(endTime.split(':')[0]));
    endDate.setMinutes(Number(endTime.split(':')[1]));

    return startDate < currentDate && endDate > currentDate;
};

// export const createNotification = (props) => {
//     const { message, title, timeOut, callback} = props
//     console.log({ title, message, timeOut})
//     const type = 'success'
//     return () => {
//       switch (type) {
//         // case 'info':
//         //   NotificationManager.info(message);
//         //   break;
//         case 'success':
//         NotificationManager.success("xin chafp");
//           break;
//         // case 'warning':
//         //   NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
//         //   break;
//         // case 'error':
//         //   NotificationManager.error('Error message', 'Click me!', 5000, () => {
//         //     alert('callback');
//         //   });
//         //   break;
//       }
//     };
//   };
