/* eslint-disable filenames/match-regex */
import React, { Component } from 'react';
import './styles.scss';

function MenuRight(props) {
    const {nodes ,nodeFocus, onChangeNode} = props;
    const value = nodes.find((item)=> item.id === nodeFocus?.id);
    return (
        <div className='menu-flow-right'>
            {value && <>
                <span className='mgb-10'>Id: {value?.id}</span>
                <span className='mgb-10'>Type: {value?.type}</span>
                <span className='mgb-10'>Name: {value?.data?.name}</span>
            </>
            }
            <button className='btn-menu-flow-right bg-green' onClick={() => onChangeNode(value, 1)}>Nhân bản</button>
            <button  className='btn-menu-flow-right red' onClick={() => onChangeNode(value, 0)}>Xoá</button>
        </div>
    );
}

export default MenuRight;
