/* eslint-disable filenames/match-regex */
export const Language = {
    nameWeb: 'Mandala_POS',
    common: {
        cancel: 'Huỷ',
        confirm: 'Xác nhận',
        edit: 'Edit',
        editCancel: 'Cancel editing',
        save: 'Save',
        print: 'Print',
        close: 'Close',
        note: 'Ghi chú'
    },
    orderPaid: {
        order: 'Order',
        note: 'Note',
        table: 'Table',
        adult: 'Adult',
        child: 'Child',
        status: 'Status',
        salesman: 'Salesman',
        paymentInfo: 'Payment Info',
        amount: 'Amount',
        vat: 'VAT',
        svc: 'SVC',
        billDiscount: 'Bill Discount',
        voucher: 'Voucher Code',
        guestName: 'Guest Name',
        topped: 'Topped Up',
        balance: 'Balance',
        total: 'Total',
        voucherInfo: 'Voucher Info',
        unit: 'Unit',
        completed: 'Completed',
        active: 'Active',
        close: 'Close',
        listOrder: 'Order List',
        voidOder: 'Void Items',
        placeHolder: 'Search Order No',
        voucherDiscount: 'Voucher Discount'
    },
    payment: {
        enterValue: 'Nhập giá trị',
        discountInfo: 'Discount info',
        choose: 'Chọn',
        exempt: 'Exempt',
        inputValueDiscount: 'Vui lòng nhập giá trị discount cho món ăn:',
        discount: 'Discount:',
        print: 'Print bill',
        payment: 'Payment',
        moneyPayment: 'Số tiền cần thanh toán',
        printBillMN: 'Print Bill MN',
        printInvoice: 'Print Invoice',
        orderInfo: 'Order Info',
        voucherCode: 'Voucher Code',
        voucherInfo: 'Voucher Info',
        selectPayment: 'Select payment invoice',
        peoplePayment: 'Người thanh toán',
        orderPayment: 'Order Payment',
        voucherModal: {
            title: 'Cổ đông mở tài khoản API',
            content: 'Điều kiện của voucher',
            voucherCode: 'MÃ VOUCHER',
            nameUser: 'USER NAME',
            expiry: 'HẠN SỬ DỤNG',
            type: 'LOẠI VOUCHER',
            valueVoucher: 'GIÁ TRỊ VOUCHER',
            residualValue: 'GIÁ TRỊ CÒN LẠI',
            listVoucherOfHotel: 'List of hotel services eligible for vouchers: ',
            remainingNumber: 'Số lần còn lại',
            many: 'Nhiều lần',
            once: 'Một lần'
        },
        errVoucher: 'Mã Voucher không hợp lệ!',
        popupPayment: {
            optionPayment: 'Payment option',
            cash: 'Cash',
            card: 'Card',
            bank: 'Bank Transfer',
            cityLedger: 'City Ledger',
            limit: 'Credit Limit',
            tern: 'Term',
            building: 'Building',
            room: 'Room',
            folio: 'Folio',
            guestName: 'Guest Name',
            focType: 'FOC Type',
            HODFOC: 'HOD FOC',
            remarks: 'Remarks',
            placeHolder: 'Enter a description...',
            paymentSuccess: 'Payment Success'
        },
        orderList: 'Order List'
    },
    merge: {
        mergeOrder: 'Gộp order',
        withOrder: 'với order',
        mergeBill: 'Merge Bill',
        note: 'Lưu ý: Chỉ được chọn tối đa 1 bill để gộp!'
    },
    toast: {
        payment: {
            successUpdate: 'Cập nhật thành công',
            errUpdate: 'Cập nhật thất bại!'
        }
    },
    header: {
        tableEmpty: 'Bàn trống',
        tableGuests: 'Đã có khách',
        tableMap: 'Table Map',
        orderPaid: 'Order Paid',
        orderDetail: 'Order Detail'
    },
    flow: {
        library: 'Library'
    },
    err: {
        notFound: 'Không tìm thấy dữ liệu',
        empty: 'Không tìm thấy kết quả',
        mergeErr: 'Merge Bill thất bại. Vui lòng thử lại',
        errPopupPrint: 'Unpaid order can\'t be printed',
        contentErrPopupPrint: 'Please choose the paid order to print.',
        updateErr: 'Cập nhật không thành công. Vui lòng thử lại!',
        copyErr: 'Copy thât bại!',
        paymentErr: 'Thanh toán thất bại. Vui lòng kiểm tra lại!',
        orderIdErr: 'Chưa có mã order. Vui lòng kiểm tra lại!',
        errParamsTableNull: 'Vị trí bàn chưa có thay đổi!'
    },
    success: {
        mergeSuccess: 'Merge Bill thành công',
        updateSuccess: 'Cập nhật thành công!',
        copySuccess: 'Copy thành công!'
    }
};
