import {
    Button,
    Modal
} from '@mui/material';
import { Box } from '@mui/system';
import { SVGDeleted } from 'assets/svg';
import { MyTextAreaInput } from 'components/TextAera';
import { TextAreaActions } from 'components/TextAera/types';
import React, { useRef, useState } from 'react';
import { Language } from 'shared/Language';
import './styles.scss';
const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1
};
  interface PropModalOrder {
    onHandleClose?: () => void,
    onConfirm?: any,
    show?: boolean,
    note?: string,
    setNote?: any,
    password?: string,
    setPassword?: any,
    selectedItem?: any
  }
const ModalCart:React.FC<PropModalOrder> = (props) => {
    const {
        onHandleClose,
        onConfirm,
        show,
        setPassword,
        password,
        setNote,
        note
    } = props;

    const refText = useRef<TextAreaActions>(null);
  
    return (
        <div>
            <Modal
                open={show}
                onClose={() => onHandleClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="add-or-edit-modal"
            >
                <Box sx={style} className="content __content">
                    <div className="popup_modal">
                        <div className="header-modal d-flex justify-content-between align-items-center">
                            <SVGDeleted/>
                        </div>
                        <div style={{fontSize: 20, fontWeight: '600', padding: '5px 20px'}}>Xác nhận hủy món ăn</div>
                        <div style={{paddingLeft: 20, paddingRight: 20}}>
                            {/* <div style={{fontSize: 14, fontWeight: '500', color: '#474A4C'}}>Nhập mật khẩu tài khoản của bạn</div>
                            <input required type="password" id="input_password" value={password} onChange={(event) => setPassword(event.target.value)} name="input_code" placeholder='Nhập mật khẩu' /> */}
                            <MyTextAreaInput ref={refText} label={Language.common.note} placeHolder={'Nhập ghi chú của bạn...'} value={''} labelStyles={'text-big medium color-gray mgb-5'} inputStyle={'text-area'}/>
                        </div>
                    </div>
                    <div className="footer_popup">
                        <div className="footer-modal">
                            <Button
                                style={{flex: 1,marginRight: 10}}
                                variant="contained"
                                color="warning"
                                onClick={() => onHandleClose()}
                            >
                                Đóng
                            </Button>
                            <Button
                                style={{flex: 1}}
                                onClick={() => onConfirm(refText.current?.getValue())} variant="contained" color="success">
                                Xác nhận
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};
  
export default ModalCart;
  