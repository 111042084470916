/* eslint-disable react/react-in-jsx-scope */
import Button from '@mui/material/Button';
import { ButtonProps } from './types';
import './styles.scss';
import { useEffect, useState } from 'react';

export default function MyButton({iconLeft, iconRight, label, styleLabel, onPress, value, styleContainer}: ButtonProps) {
    
    const [defaultValue, setDefaultValue] = useState<string | null>(null);

    useEffect(() => {
        setDefaultValue(value);
    }, [value]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        onPress?.(event);
    };

    return(
        <div className={`${styleContainer} button-style-container`}>
            <Button 
                color="primary"
                variant="contained" 
                type="submit" 
                onClick={handleClick}
            >
                {iconLeft && iconLeft}
                <span className={defaultValue ? `${styleLabel} button color` : `${styleLabel} button`}>
                    {defaultValue || label}
                </span>
                <div className="icon-right">
                    {iconRight && iconRight}
                </div>
            </Button>
        </div>
    );
}
