/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/react-in-jsx-scope */
import { Modal } from '@mui/material';
import { SVGBank, SVGCard, SVGCardPayment, SVGCash, SVGCheckActive, SVGUpDown } from 'assets/svg';
import MyButton from 'components/Button.tsx';
import CheckBox from 'components/CheckBox';
import DropDownComponent from 'components/DropDown';
import { TableDropDown } from 'components/DropDown/types';
import { MyTextAreaInput } from 'components/TextAera';
import { TextAreaActions } from 'components/TextAera/types';
import { OptionPaymentType } from 'constants/Defines';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Language } from 'shared/Language';
import Format from 'utils/Format';
import Box from '@mui/material/Box';

import { ListDropsDown, ParamsPayment, PopupPaymentActions, PopupPaymentProps } from './types';
import { fetchCityInfo, fetchListRoom, fetchListSource, fetchRoomInfo } from 'service/orderService';
import { TYPE_SOURCE } from 'constants/AppEnums';
import LoadingTable from 'components/LoadingTable';
import { CityInfoModel, ListSourceModel, RoomInfoModel } from 'models/order';
import { borderBottom } from '@mui/system';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};

const optionData = [
    {
        id: 1,
        label: 'Payment Direct',
        key: TYPE_SOURCE.PAYMENT_METHOD
    },
    {
        id: 2,
        label: 'City Ledger',
        key: TYPE_SOURCE.CITY_LEDGER
    },
    {
        id: 3,
        label: 'Post to Room',
        key: TYPE_SOURCE.BUILDING
    },
    {
        id: 4,
        label: 'FOC',
        key: TYPE_SOURCE.FOC
    }
];



const PopupPayment = forwardRef<PopupPaymentActions, PopupPaymentProps>(({  title, voucherRemain, onPressConfirm, data, onPressItem}: PopupPaymentProps, ref) => {
    const [open, setOpen] = useState<boolean>(false);
    const [option, setOption] = useState<Number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const refFOC = useRef(null);
    const refBuilding = useRef(null);
    const [errDrops, setErrDrops] = useState({foc: false, building: false});
    const [toggle, setToggle] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [listDrops, setListDrops] = useState<ListDropsDown>({
        city: [],
        room: [],
        building: [],
        foc: [],
        hoc: []
    });
    const [cityInfo, setCityInfo] = useState<CityInfoModel>();
    const [roomInfo, setRoomInfo] = useState<RoomInfoModel>();
    const [info, setInfo] = useState({
        room: '',
        city: '',
        building: '',
        foc: '',
        hod: ''
    });

    const [params, setParams] = useState<ParamsPayment>({
        orderId: data?.orderId,
        paymentOption: 1,
        paymentMethod: 1,
        cardNo: '',
        cardHolder: '',
        receiptNo: '',
        focCode: undefined,
        remark: '',
        hodCode: '',
        cityledgerId: 0,
        roomId: 0,
        building: ''
    });

    const [paramsCheckBox, setParamsCheckBox] = useState({
        cash: true,
        card: false,
        bank: false
    });

    const [voucher, setVoucher] = useState<string>('');

    const getListRoom = useCallback(async (item: TableDropDown) => {
        setParams(last => {
            last.building = item.id.toString();
            return last;
        });
        setInfo(
            last => {
                last.building = item.value;
                return last;
            }
        );
        setErrDrops(last => {
            last.building = false;
            return last;
        });
        setToggle(last => !last);
        setLoading(true);
        const res = await fetchListRoom(item.id);
        setLoading(false);
        if (res.message === 'Success') {
            const _list = res.metadata as ListSourceModel[];
            return setListDrops(last => {
                last.room = convertData(_list);
                return last;
            });
        }
    }, []);

    const getListSource = useCallback(async(id) => {
        setLoading(true);
        const res =  await fetchListSource(id);
        setLoading(false);

        if (res.message === 'Success') {
            const _list = res.metadata as ListSourceModel[];
            switch(id) {
                case TYPE_SOURCE.PAYMENT_METHOD: 
                    return ;
                case TYPE_SOURCE.CITY_LEDGER: 
                    return setListDrops(last => {
                        last.city = convertData(_list);
                        return last;
                    });
                case TYPE_SOURCE.BUILDING: 
                    return setListDrops(last => {
                        last.building = convertData(_list);
                        return last;
                    });
                case TYPE_SOURCE.FOC: 
                    return setListDrops(last => {
                        last.foc = convertData(_list);
                        return last;
                    });
                case TYPE_SOURCE.HOC: 
                    return setListDrops(last => {
                        last.hoc = convertData(_list);
                        return last;
                    });
                default: 
                    return;
            }
        }
    }, []);

    const convertData = (_data: ListSourceModel[]) => {
        const convert = [] as TableDropDown[];
        _data?.map(item => convert.push({id: item.value, value: item.text}));
        return convert;
    };

    const refText = useRef<TextAreaActions>(null);

    const showModal = async (_disabled: boolean, _voucher?: string) => {
        setDisabled(_disabled);
        setVoucher(_voucher);
        if(_disabled){
            setParamsCheckBox(last => {
                last.card = false;
                last.cash = false;
                last.bank =false;
                return last;
            });
         
        } else {
            setParamsCheckBox(last => {
                last.card = false;
                last.cash = true;
                last.bank = false;
                return last;
            });
        }
        setParams({
            orderId: data?.orderId,
            paymentOption: 1,
            paymentMethod: 1,
            cardNo: '',
            cardHolder: '',
            receiptNo: '',
            focCode: undefined,
            remark: '',
            hodCode: '',
            cityledgerId: 0,
            roomId: 0,
            building: ''
        });
        setOpen(true);
        await Promise.all([
            getListSource(TYPE_SOURCE.CITY_LEDGER),
            getListSource(TYPE_SOURCE.PAYMENT_METHOD),
            getListSource(TYPE_SOURCE.BUILDING),
            getListSource(TYPE_SOURCE.FOC),
            getListSource(TYPE_SOURCE.HOC)
        ]);
    };

    const hideModal = () => {
        setOpen(false);
    };
    const onConfirm = () => {
        if(params.paymentOption === OptionPaymentType.FOC && (!params.focCode || params.building.length < 1)) {
            refFOC.current?.setErr(!params.focCode );
            refBuilding.current?.setErr(params.building.length < 1);
            setErrDrops(last => {
                last.foc = !params.focCode;
                last.building = params.building.length < 1;
                return last;
            });
            setToggle(last => !last);

            return;
        }
        setParams(last => {
            last.remark = refText.current.getValue() || '';
            onPressConfirm?.(last);
            return last;
        });
        hideModal();
    };

    useImperativeHandle(ref, () => ({
        showModal,
        hideModal
    }));

    const setCheckBox = (value: boolean, label: string) => {
        if(!disabled) {
            if(label === Language.payment.popupPayment.cash) {
                setParamsCheckBox({...paramsCheckBox, cash: value, card: false, bank: false});
                setParams(last => {
                    last.paymentMethod = 1;
                    return last;
                });
            } else if (label === Language.payment.popupPayment.card) {
                setParamsCheckBox({...paramsCheckBox, card: value, cash: false, bank:false});
                setParams(last => {
                    last.paymentMethod = 2;
                    return last;
                });
            } else {
                setParams(last => {
                    last.paymentMethod = 3;
                    return last;
                });
                setParamsCheckBox({...paramsCheckBox, bank: value, card: false, cash: false});
            }
        }
    };


    const onChooseFOC = useCallback((item: TableDropDown) => {    
        setParams(last => {
            last.focCode = Number(item.id);
            return last;
        });  
        setInfo(
            last => {
                last.foc = item.value;
                return last;
            }
        );
        setErrDrops(last => {
            last.foc = false;
            return last;
        });
        setToggle(last => !last);
    }, []);

    const onChooseHOD = useCallback((item: TableDropDown) => {  
        setParams(last => {
            last.hodCode = item.id;
            return last;
        });  
        setInfo(
            last => {
                last.hod = item.value;
                return last;
            }
        );  
        
    }, []);

    const onGetInfoCity = useCallback(async (item: TableDropDown) => {  
        setParams(last => {
            last.cityledgerId = Number(item.id);
            return last;
        });  
        setInfo(
            last => {
                last.city = item.value;
                return last;
            }
        );
        setLoading(true);
        const res = await fetchCityInfo(item.id);
        setLoading(false);
        if (res.message === 'Success') {
            const _data = res.metadata[0] as CityInfoModel;
            setCityInfo(_data);
        }
    }, []);

    const onGetRoomInfo = useCallback(async (item: TableDropDown) => {    
        setParams(last => {
            last.roomId = Number(item.id);
            return last;
        });  
        setInfo(
            last => {
                last.room = item.value;
                return last;
            }
        );
        setLoading(true);
        const res = await fetchRoomInfo(item.id);
        setLoading(false);
        if (res.message === 'Success') {
            const _data = res.metadata[0] as RoomInfoModel;
            setRoomInfo(_data);
        }
    }, []);

    const viewText = (label: string, value: string, styleContainer?: any, color?: string) => {
        return(
            <div className={`body-city-item ${styleContainer}`}>
                <span className='text-normal color-text-2 regular space-nowrap'>{label}</span>
                <span className={`text-normal color-black bold space-nowrap ${color}`}>{value}</span>
            </div>
        );
    };

    const renderDropDown = useCallback((label: string, arr: TableDropDown[], onPress: any, value?: string, refDropDown?: any, err?: boolean) => {
        return(
            <div className='flex-row center' style={{border: err ? '1px solid red' : 'none', marginBottom: 5, borderRadius: 5, padding: '0px 5px'}}>
                <span className='text-normal color-gray regular space-nowrap'>{label}</span>
                <DropDownComponent 
                    ref={refDropDown}
                    data={arr}
                    label={Language.payment.choose}
                    styleLabel={'text-normal color-green regular space-nowrap mgr-24'}
                    stylesContainer={'drops-down-customer'}
                    defaultValue={value}
                    onPress={disabled ? null: onPress}
                    iconRight={<SVGUpDown/>}
                    styleIconRight={'discount-icon-right'}
                    widthMenu={400}
                />
            </div>
        );
    }, []);

    const viewPaymentDirec = useMemo(() => {
        return(
            <div className='payment-direc'>
                <CheckBox 
                    listOption={paramsCheckBox?.cash || false} 
                    setListOption={setCheckBox} 
                    item={{id: 1, name: Language.payment.popupPayment.cash}} 
                    styles={'mgb-10'}
                    icon={<SVGCash/>}
                    typeCheck
                />
                <CheckBox 
                    listOption={paramsCheckBox?.card || false} 
                    setListOption={setCheckBox} 
                    item={{id: 2, name: Language.payment.popupPayment.card}} 
                    styles={'mgb-10'}
                    icon={<SVGCardPayment/>}
                    typeCheck
                />
                <CheckBox
                    listOption={paramsCheckBox?.bank || false} 
                    setListOption={setCheckBox} 
                    item={{id: 3, name: Language.payment.popupPayment.bank}} 
                    styles={'mgb-10'}
                    icon={<SVGBank/>}
                    typeCheck
                />
            </div>
        );
    }, [paramsCheckBox?.bank, paramsCheckBox?.card, paramsCheckBox?.cash, setCheckBox]);

    const viewCityLedger = useMemo(() => {

    
        return(
            <div className='city-ledger'>
                {renderDropDown(`${Language.payment.popupPayment.cityLedger}:`, listDrops.city, onGetInfoCity, info.city)}
                <div className='body-city'>
                    {viewText(Language.payment.popupPayment.limit, Format.formatMoneyNumber(cityInfo?.limit || 0), 'mgb-16')}
                    {viewText(Language.payment.popupPayment.tern, cityInfo?.term.toString())}
                </div>
            </div>
        );
    }, [listDrops.city, cityInfo, info.city]);

    const viewPostToRoom = useMemo(() => {
        return(
            <div className='city-ledger'>
                <div className='building-view'>
                    {renderDropDown(`${Language.payment.popupPayment.building}:`, listDrops.building, getListRoom, info.building)}
                    {renderDropDown(`${Language.payment.popupPayment.room}:`, listDrops.room, onGetRoomInfo, info.room)}
                </div>
                <div className='body-city'>
                    {viewText(Language.payment.popupPayment.folio, roomInfo?.roomCode || '', 'mgb-16')}
                    {viewText(Language.payment.popupPayment.guestName, roomInfo?.guestName || '','mgb-16')}
                    {viewText(Language.payment.popupPayment.room, roomInfo?.folioNo || '')}
                </div>
            </div>
        );
    }, [listDrops.building, listDrops.room, roomInfo, info]);

    const viewFOC = useMemo(() => {

        return(
            <div className='city-ledger'>
                <div className='building-view'>
                    {renderDropDown(`${Language.payment.popupPayment.focType}:`, listDrops.foc, onChooseFOC, info.foc, refFOC, errDrops.foc)}
                    {renderDropDown(`${Language.payment.popupPayment.HODFOC}:`, listDrops.hoc, onChooseHOD,  info.hod)}
                </div>
                <div className='building-view'>
                    {renderDropDown(`${Language.payment.popupPayment.building}:`, listDrops.building,  getListRoom, info.building, refBuilding, errDrops.building)}
                    {renderDropDown(`${Language.payment.popupPayment.room}:`, listDrops.room, onGetRoomInfo, info.room)}
                </div>
            </div>  
        );
    }, [listDrops.hoc, listDrops.foc, listDrops.room, listDrops.building, info.building, info.room, info.foc, info.hod, errDrops.foc, toggle]);

    const renderOptionChild = useMemo(() => {
        switch(option) {
            case OptionPaymentType.PAYMENT_DIREC: 
                return viewPaymentDirec;
            case OptionPaymentType.CITY_LEDGER: 
                return viewCityLedger;
            case OptionPaymentType.POST_TO_ROOM: 
                return viewPostToRoom;
            case OptionPaymentType.FOC: 
                return viewFOC;
            default: 
                return;
        }
    }, [option, viewCityLedger, viewFOC, viewPaymentDirec, viewPostToRoom]);

    const renderViewOption = useMemo( () => {
        return(
            <div className='option border' >
                <span className='text-big medium color-black mgb-16'>{Language.payment.popupPayment.optionPayment}</span>
                <div className='view-item'>
                    {optionData.map((item, index) => {
                        const onChangeOption = async () => {
                            if(!disabled) {
                                setOption(item.id);
                                setParams(last => {
                                    last.paymentOption = item.id;
                                    if(item.id !== OptionPaymentType.PAYMENT_DIREC) {
                                        last.paymentMethod = 1;
                                    }
                                    return last;
                                });
                            } 
                        };

                        return(
                            <div key={index} className={`item-option ${option === item.id ? 'border-active' : 'border-blur'}`} onClick={onChangeOption}>
                                {option === item.id && <div className='ic-option'>
                                    <SVGCheckActive />
                                </div>}
                                <span className={`text-normal bold  ${option === item.id ? 'color-brown' : 'color-black'}`}>{item.label}</span>
                            </div>
                        );
                    })}
                  
                </div>
                <div className='option-child'>
                    {renderOptionChild}
                </div>
            </div>
        );
    }, [option, renderOptionChild]);

    const renderRemarks = useMemo(() => {
        return(
            <div className='option border mgt-24'>
                <MyTextAreaInput 
                    label={Language.payment.popupPayment.remarks} 
                    placeHolder={Language.payment.popupPayment.placeHolder} 
                    value={''} 
                    ref={refText}
                />
            </div>
        );
    }, []);

    const renderPaymentDetail = useMemo(() => {
        return(
            <div className='payment-detail-view' >
                <div className="text-big medium color-black mgb-10">{Language.orderPaid.paymentInfo}</div>
                {viewText(`${Language.orderPaid.amount} ( ${data?.items?.length || 0} items ) `, Format.formatMoney(data?.amountNet),'mgb-16')}
                {viewText(`${Language.orderPaid.billDiscount}`, `- ${Format.formatMoney(data?.totalDiscount)}`,'mgb-16', 'color-yellow')}
                {viewText(`${Language.orderPaid.vat}`, Format.formatMoney(data?.vat),'mgb-16')}
                {viewText(`${Language.orderPaid.svc}`, Format.formatMoney(data?.svc),'mgb-16')}
                {voucher && viewText(`${Language.orderPaid.voucherDiscount}`, `- ${Format.formatMoney(voucher)}`,'mgb-16', 'color-yellow')}
                <div className='line'/>
                <div className='body-city-item mgt-16'>
                    <span className='text-normal color-black bold space-nowrap'>{Language.orderPaid.total}</span>
                    <span className='text-normal color-red bold space-nowrap'>{Format.formatMoney(data?.total)}</span>
                </div>
            </div>
        );
    }, [data, voucher]);
 
    return (
        <Modal
            open={open} 
            onClose={hideModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onBackdropClick={() => setOpen(false)}
        >
            <Box sx={style}>
                <div className='popup-payment'>
                    <div className="popup-container width-payment flex-1">
                        <div className="header-payment">
                            <img src="assets/icon/Print/index.svg" alt="print" />
                            <div className="text-big-x bold color-black mgl-5">
                                {title || 'This is title'}
                            </div>
                        </div>
                        <div className='body-popup-payment'>
                            <div className='payment-option'>
                                {renderViewOption}
                                {renderRemarks}
                            </div>
                            <div className='payment-detail'>
                                {renderPaymentDetail}
                                <MyButton label={`Payment ${Format.formatMoney(data?.total - Number(voucher || 0))}`} styleContainer={'btn-pay'} onPress={onConfirm}/>
                            </div>

                        </div>
                    </div>
                </div>
                {loading && <LoadingTable />}
            </Box>
        </Modal>
    );
});
export default PopupPayment;
