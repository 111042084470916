/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable filenames/match-regex */
/* eslint-disable no-duplicate-case */
import { SVGIcDots, SVGRight } from 'assets/svg';
import { NameIcon } from 'constants/Defines';
import { CellProps } from './type';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useMemo, useState } from 'react';
import Format from 'utils/Format';
import { colorSet } from 'shared/styles/ColorSet';

export default function Cell({
    value, onPress, 
    isCheckBox, onPressCheckBox,
    defaultCheckBox, icon, keyCell,
    labelIcon, styleItem, flexCell,
    onPressColumn, active
} : CellProps) {

    const [check, setCheck] = useState<boolean>(defaultCheckBox || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const labelCheckBox = { inputProps: { 'aria-label': 'Checkbox demo' }};

    useEffect(() => {
        setCheck(defaultCheckBox);
    }, [defaultCheckBox]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onCheckBox = () => {
        setCheck(last => {
            last = !last;
            onPressCheckBox({id: value?.[keyCell[0]]});
            return last;
        });
    };
 
    const renderIcon = (name: string) => {
        switch(name) {
            case NameIcon.DOTS:
                return <SVGIcDots />;
            case NameIcon.RIGHT:
                return <SVGRight />;
            default:
                return;
        }
    };

    const renderCheckBox = useMemo(() => {
        return(
            <Checkbox {...labelCheckBox} checked={check} onChange={onCheckBox}/>
        );
    }, [check, labelCheckBox, onCheckBox]);
 
    return (
        <div className='cell-container' style={{backgroundColor: check  || active? colorSet.default.yellow1 : 'transparent'}} onClick={() => onPressColumn(value)}> 
            {isCheckBox && <div className= 'th' style={{flex: 1}}>
                {renderCheckBox}
            </div>}
            {
                keyCell.map((key: string, index: number) => {
                    return(
                        <>
                            {Object.keys(value).map((label: string, i: number) => {
                                return(
                                    <>
                                        {key === label &&  <div key={i} style={{flex: flexCell[index]}} className={i < 1 && !isCheckBox ? 'td' : 'border-left td'}  onClick={onPress}>
                                            <span 
                                                className="label-table text-normal regular" 
                                                style={styleItem?.find(item => item?.key === label)?.style}>
                                                {key === 'total' ? Format.formatMoneyNotD(value[label]) : value[label]}</span>
                                        </div>}
                                    </>
                                );
                            })}
                        </>
                    );
                })
            }
            {icon && 
    <>
        <div className="icon border-left td flex-row" style={{flex: flexCell[flexCell.length - 1]}} onClick={onPress}>
            {labelIcon &&  <span style={styleItem?.find(item => item?.key === 'icon')?.style}>{labelIcon}</span>}
            {renderIcon(icon)}
        </div>
    </>
            }
        </div>
    );
}
