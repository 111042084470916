import { TableDropDown } from 'components/DropDown/types';
import { StyleRowModel, TableColumnModel } from 'models/common';
import { colorSet } from 'shared/styles/ColorSet';

export const dropsDown = [{id: '1', value: 'VND'}, {id: '2', value: 'Percent'}] as TableDropDown[];

export const columns = [
    {
        id: '1',
        label: 'Oder No'
    },
    {
        id: '2',
        label: 'Time'
    },
    {
        id: '3',
        label: 'Table'
    },
    {
        id: '4',
        label: 'Total'
    },
    {
        id: '5',
        label: 'Payment'
    }
] as TableColumnModel[];

export const arrKey = ['order_no', 'order_time', 'table_code',  'total'] as Array<string>;
export const FlexRow = [1.3, 1.5, 1, 1, 0.5] as Array<number>;
export const styleRow = [{
    key: 'total',
    style: {
        color: colorSet.default.red,
        fontWeight: 600,
        fontSize: 14
    }
},
{
    key: 'order_no',
    style: {
        color: colorSet.default.green
    }
},
{
    key: 'icon',
    style: {
        color: colorSet.default.yellow,
        fontSize: 14,
        fontWeight: 300,
        marginRight: 10,
        marginLeft: 8
    }
}
] as StyleRowModel[];
