// import { Modal } from '@mui/material';
// import MyButton from 'components/Button.tsx';
// import React, { useState } from 'react';
// import { Language } from 'shared/Language';

// const PopupSucess = (props) => {
//     const {open, iconHead, title, des, setOpenModal} = props;
//     return (
//         <Modal open={open}>
//             <div id="popup-print">
//                 <div className="popup-print-container">
//                     <img src="assets/icon/Success/index.svg" alt="success" />
//                     <div className="success-text">{Language.payment.popupPayment.paymentSuccess}</div>
//                     <div className="detail-text"><div className="voucher-text">Voucher decreased</div> <div className="value-text">-2,620,000 đ</div></div>
//                     <MyButton label={Language.common.confirm} styleContainer={'btn-pay'} onPress={setOpenModal(false)}/>
//                 </div>
//             </div>
//         </Modal>
//     );
// };
// export default PopupSucess;


import { Modal } from '@mui/material';
import MyButton from 'components/Button.tsx';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Language } from 'shared/Language';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};

type ModalProps = {
    onConfirm: () => any;
}

export type ModalAction = {
    showModal?: () => any;
    hideModal?: () => any;
}
const PopupSuccess = forwardRef<ModalAction, ModalProps >(({onConfirm}:ModalProps, ref) => {
    const [open, setOpen] = useState<boolean>(false);

    const showModal = () => {
        setOpen(true);
     
    };

    const hideModal = () => {
        setOpen(false);
    };

    const onPressConfirm = () => {
        onConfirm?.();
        hideModal();
    };

    useImperativeHandle(ref, () => ({
        showModal,
        hideModal
    }));

    return (
        <Modal open={open} onBackdropClick={onPressConfirm}>
            <Box sx={style}>
                <div className='popup-payment'>
                    <div className="popup-container popup-success">
                        <img src="assets/icon/Success/index.svg" alt="success" />
                        <div className="text-big-x bold color-black mgb-16">
                            {Language.payment.popupPayment.paymentSuccess}
                        </div>
                        <MyButton label={Language.common.confirm} styleContainer={'btn-pay'} onPress={onPressConfirm}/>
                    </div>
                </div>
            </Box>
        </Modal>
    );
});
export default PopupSuccess;

